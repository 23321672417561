import React, { useEffect, useState } from "react";
import HorizontalBarChartWeekly from "./HorizontalBarChartWeekly";
import { calculateEngagementTimeseries } from "../utilities/CalculateEngagemntSeries.js";
import Spinner from "react-bootstrap/Spinner";
import ShortenNumber from "../utilities/ShortenNumber.js";
import BarChartNew from "./all-charts/BarChartNew";
import BarChart from "./all-charts/BarChartNew";


export default function PostingBehaviousNew({ analyticsData30d, analyticsDataAll, analyticsDataLoading, analyticsDataLoadingAll }) {
    const [dailyDataVideos, setDailyDataVideos] = useState([])
    const [dailyDataViews, setDailyDataViews] = useState([])
    const [dailyDataEngagement, setDailyDataEngagement] = useState([])
    const [analyticsData, setAnalyticsData] = useState([])
    const [daysFilter, setDaysFilter] = useState(30)

    useEffect(() => {
        if (daysFilter === 30 || daysFilter === 7) {
            setAnalyticsData(analyticsData30d);
        } else {
            setAnalyticsData(analyticsDataAll);
        }
    }, [daysFilter, analyticsData30d, analyticsDataAll]);

    // useEffect(() => {
    //     // Initialize array to store data for each day
    //     const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    //     const videosPerDay = new Array(7).fill(0);
    //     const viewsPerDay = new Array(7).fill(0);
    //     const engagementPerDay = new Array(7).fill(0);

    //     // Iterate over all videos timeseries data and aggregate the statistics
    //     analyticsData?.videos_timeseries?.forEach(entry => {
    //         const entryDate = new Date(entry['Date Posted']);
    //         const dayOfWeek = entryDate.getDay();
    //         videosPerDay[dayOfWeek] += entry.Videos || 0;
    //     });

    //     // Iterate over all views timeseries data and aggregate the statistics
    //     analyticsData?.views_timeseries?.forEach(entry => {
    //         const entryDate = new Date(entry['Date Posted']);
    //         const dayOfWeek = entryDate.getDay();
    //         viewsPerDay[dayOfWeek] += entry.Views || 0;
    //     });

    //     const engagementTimeseries = calculateEngagementTimeseries(analyticsData?.likes_timeseries || [], analyticsData?.comments_timeseries || [], analyticsData?.shares_timeseries || [], analyticsData?.bookmarks_timeseries || []);

    //      // Iterate over all engagement timeseries data and aggregate the statistics
    //      engagementTimeseries?.forEach(entry => {
    //         const entryDate = new Date(entry['Date Posted']);
    //         const dayOfWeek = entryDate.getDay();
    //         engagementPerDay[dayOfWeek] += entry.Engagement || 0;
    //     });

    //     setDailyDataVideos(videosPerDay);
    //     setDailyDataViews(viewsPerDay);
    //     setDailyDataEngagement(engagementPerDay)
    //     // Now you have the total array of data for videos and views for each day of the week
    //     console.log("Total videos per day:", videosPerDay);
    //     console.log("Total views per day:", viewsPerDay);
    // }, [analyticsData]);

    // useEffect(() => {
    //     // Initialize array to store data for each day
    //     const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    //     const videosPerDay = new Array(7).fill(0);
    //     const viewsPerDay = new Array(7).fill(0);
    //     const engagementPerDay = new Array(7).fill(0);

    //     // Get the previous month and year
    //     const currentDate = new Date();
    //     let previousMonth = currentDate.getMonth() - 1;
    //     let previousYear = currentDate.getFullYear();

    //     if (previousMonth < 0) {
    //         previousMonth = 11; // December
    //         previousYear -= 1;
    //     }

    //     // Filter function to check if a date is in the previous month and year
    //     const isInPreviousMonth = (date) => {
    //         const entryDate = new Date(date);
    //         return entryDate.getMonth() === previousMonth && entryDate.getFullYear() === previousYear;
    //     };

    //     // Iterate over all videos timeseries data and aggregate the statistics for the previous month
    //     analyticsData?.videos_timeseries?.filter(entry => isInPreviousMonth(entry['Date Posted'])).forEach(entry => {
    //         const entryDate = new Date(entry['Date Posted']);
    //         const dayOfWeek = entryDate.getDay();
    //         videosPerDay[dayOfWeek] += entry.Videos || 0;
    //     });

    //     // Iterate over all views timeseries data and aggregate the statistics for the previous month
    //     analyticsData?.views_timeseries?.filter(entry => isInPreviousMonth(entry['Date Posted'])).forEach(entry => {
    //         const entryDate = new Date(entry['Date Posted']);
    //         const dayOfWeek = entryDate.getDay();
    //         viewsPerDay[dayOfWeek] += entry.Views || 0;
    //     });

    //     const engagementTimeseries = calculateEngagementTimeseries(
    //         analyticsData?.likes_timeseries || [],
    //         analyticsData?.comments_timeseries || [],
    //         analyticsData?.shares_timeseries || [],
    //         analyticsData?.bookmarks_timeseries || []
    //     );

    //     // Iterate over all engagement timeseries data and aggregate the statistics for the previous month
    //     engagementTimeseries?.filter(entry => isInPreviousMonth(entry['Date Posted'])).forEach(entry => {
    //         const entryDate = new Date(entry['Date Posted']);
    //         const dayOfWeek = entryDate.getDay();
    //         engagementPerDay[dayOfWeek] += entry.Engagement || 0;
    //     });

    //     // Round off the aggregated data
    //     const roundedVideosPerDay = videosPerDay.map(value => Math.round(value));
    //     const roundedViewsPerDay = viewsPerDay.map(value => Math.round(value));
    //     const roundedEngagementPerDay = engagementPerDay.map(value => Math.round(value));

    //     setDailyDataVideos(roundedVideosPerDay);
    //     setDailyDataViews(roundedViewsPerDay);
    //     setDailyDataEngagement(roundedEngagementPerDay);

    // }, [analyticsData]);

    useEffect(() => {
        // Initialize array to store data for each day
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const videosPerDay = new Array(7).fill(0);
        const viewsPerDay = new Array(7).fill(0);
        const engagementPerDay = new Array(7).fill(0);

        const currentDate = new Date();
        let startDate;

        // Calculate the start date based on the selected range
        if (daysFilter == 7) {
            startDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
        } else {
            startDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
        }

        // Filter function to check if a date is within the selected range
        const isInRange = (date) => {
            const entryDate = new Date(date);
            return entryDate >= startDate && entryDate <= new Date();
        };

        // Aggregate statistics for videos
        analyticsData?.videos_timeseries?.filter(entry => isInRange(entry['Date Posted'])).forEach(entry => {
            const entryDate = new Date(entry['Date Posted']);
            const dayOfWeek = entryDate.getDay();
            videosPerDay[dayOfWeek] += entry.Videos || 0;
        });

        // Aggregate statistics for views
        analyticsData?.views_timeseries?.filter(entry => isInRange(entry['Date Posted'])).forEach(entry => {
            const entryDate = new Date(entry['Date Posted']);
            const dayOfWeek = entryDate.getDay();
            viewsPerDay[dayOfWeek] += entry.Views || 0;
        });

        const engagementTimeseries = calculateEngagementTimeseries(
            analyticsData?.likes_timeseries || [],
            analyticsData?.comments_timeseries || [],
            analyticsData?.shares_timeseries || [],
            analyticsData?.bookmarks_timeseries || []
        );

        // Aggregate statistics for engagement
        engagementTimeseries?.filter(entry => isInRange(entry['Date Posted'])).forEach(entry => {
            const entryDate = new Date(entry['Date Posted']);
            const dayOfWeek = entryDate.getDay();
            engagementPerDay[dayOfWeek] += entry.Engagement || 0;
        });

        // Round off the aggregated data
        const roundedVideosPerDay = videosPerDay.map(value => Math.round(value));
        const roundedViewsPerDay = viewsPerDay.map(value => Math.round(value));
        const roundedEngagementPerDay = engagementPerDay.map(value => Math.round(value));

        setDailyDataVideos(roundedVideosPerDay);
        setDailyDataViews(roundedViewsPerDay);
        setDailyDataEngagement(roundedEngagementPerDay);

    }, [analyticsData, daysFilter]);



    return (
        <div className="posting-behaviour-main-wrapper pb-2">
            <div className="content-wrapper" style={{padding : '16px 8px 8px'}}>
                <div className="title px-1"> 
                    <div className="d-flex gap-4">
                        <span className="strong white">Posting Behavior</span>
                        {analyticsDataLoading &&
                            <span className='white d-inline-flex gap-2'>  <Spinner animation="grow" size="sm" /><span
                                className=' d-inline-block white'>Analyzing...</span></span>}
                    </div>

                    <ul className="result">
                        <li onClick={() => setDaysFilter(7)} className={`${daysFilter == 7 ? 'active' : ''}`}>7D</li>
                        <li onClick={() => setDaysFilter(30)} className={`${daysFilter == 30 ? 'active' : ''}`}>30D</li>
                        {/* <li onClick={() => setDaysFilter(null)} className={`${daysFilter == null ? 'active' : ''}`}>All</li> */}
                    </ul>
                    {daysFilter == null && analyticsDataLoadingAll && <span
                        className='white d-inline-flex gap-2'>  <Spinner
                            animation="grow" size="sm" /><span
                                className=' d-inline-block white'>Analyzing...</span></span>}
                </div>
                <div className="graphs-main-wrapper">
                <div className="chart-wrapper">
                    <span className="white small">Videos Posted Average Per Day</span>
                    <BarChart durationData={dailyDataVideos || []} color="#EB55B4" />
                    {/*<HorizontalBarChart2 />*/}
                    <div className="footer">
                        <span
                            className="small">Videos posted average</span>
                        <span
                            className="small white">{Math.floor(dailyDataVideos.reduce((a, b) => a + b, 0) / dailyDataVideos.length) || 0}</span>
                    </div>
                </div>
                <div className="chart-wrapper">
                    <span className="white small">Average views per day</span>
                    <BarChart durationData={dailyDataViews || []} color="#858DE2" />
                    {/*<HorizontalBarChart2 />*/}
                    <div className="footer">
                        <span
                            className="small">Average views per day</span>
                        <span
                            className="small white">{ShortenNumber(Math.floor(dailyDataViews.reduce((a, b) => a + b, 0) / dailyDataViews.length) || 0)}</span>
                    </div>
                </div>
                <div className="chart-wrapper">
                    <span className="white small">Average engagement per day</span>
                    <BarChart durationData={dailyDataEngagement || []} color="#FF764C" orange="orange" />
                    {/*<HorizontalBarChart2 />*/}
                    <div className="footer">
                        <span
                            className="small">Average engagement per day</span>
                        <span
                            className="small white">{ShortenNumber(Math.floor(dailyDataEngagement.reduce((a, b) => a + b, 0) / dailyDataEngagement.length) || 0)}</span>
                    </div>
                </div>
                {/* <div className="chart-wrapper">
                    <span className="white small">Average engagement per day</span>
                    <BarChartNew/>
                    <div className="footer">
                        <span
                            className="small">Average engagement per day</span>
                        <span
                            className="small white">{ShortenNumber(Math.floor(dailyDataEngagement.reduce((a, b) => a + b, 0) / dailyDataEngagement.length) || 0)}</span>
                    </div>
                </div> */}
            </div>
            </div>
      
        </div>
    )
}
