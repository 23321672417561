import React, { useEffect, useState } from "react";
import sIcon from "../assets/images/icons/s-img.png";
import videoImg1 from "../assets/images/video-img-1.png";
import { NavItem, ProgressBar } from "react-bootstrap";
import pinIcon from "../assets/images/icons/pin-icon.png";
import { FaArrowRightLong } from "react-icons/fa6";
import ShortenNumber from "../utilities/ShortenNumber";
import { useSelector } from "react-redux";
import tikTockIcon from "../assets/images/icons/tick-tock-icon.png";
import moment from "moment";


export default function ChartsVideoInfoTopCards2({ analyticsData, videoChartTab, setVideoChartTab, profileData }) {
    const [topVideos, setTopVideos] = useState([])
    const [videosPerYear, setVideosPerYear] = useState([])
    const [topShortVideos, setTopShortVideos] = useState([]);
    const [topLongVideos, setTopLongVideos] = useState([]);

    var videosData = useSelector((state) => state?.reels?.reelsData);
    console.log('youtube videosData', videosData)
    var pinnedVideos = videosData?.filter((item) => item?.isPinned)

    useEffect(() => {
        const { top_videos, videos_timeseries, views_timeseries } = analyticsData

        // if (top_videos) {
        //     setTopVideos(top_videos?.top_comments)
        // }

        if (videosData?.length > 0) {
            let topFiveVideos
            if (profileData?.platform == 'instagram') {
                topFiveVideos = [...videosData]
                    ?.sort((a, b) => b.Views - a.Views)
                    ?.slice(0, 5);
            } else {
                topFiveVideos = [...videosData]
                    ?.sort((a, b) => b.views - a.views)
                    ?.slice(0, 5);
            }

            setTopVideos(topFiveVideos)

            const shortVideos = videosData?.filter(video => profileData?.platform !== 'instagram' ? video?.duration <= 30 : video?.Duration <= 30);
            const longVideos = videosData?.filter(video => profileData?.platform !== 'instagram' ? video?.duration > 30 : video?.Duration > 30);

            // Sort and get top 5 short videos
            let topShortVideos
            if (profileData?.platform == 'instagram') {
                topShortVideos = [...shortVideos]
                    .sort((a, b) => b.Views - a.Views)
                    .slice(0, 5);
            } else {
                topShortVideos = [...shortVideos]
                    .sort((a, b) => b.views - a.views)
                    .slice(0, 5);
            }

            // Sort and get top 5 long videos
            let topLongVideos
            if (profileData?.platform == 'instagram') {
                topLongVideos = [...longVideos]
                    .sort((a, b) => b.Views - a.Views)
                    .slice(0, 5);
            } else {
                topLongVideos = [...longVideos]
                    .sort((a, b) => b.views - a.views)
                    .slice(0, 5);
            }

            // setTopVideos(topLongVideos); // Setting top long videos
            // Set state for short and long videos
            setTopShortVideos(topShortVideos);
            setTopLongVideos(topLongVideos);
        }

    }, [analyticsData, videosData])

    console.log('topVideos', videosPerYear)
    return (
        <div className="chart-videos-info-wrapper">
            <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 mb-3 ">
                    <div className="main-info-wrapper" style={{background : '#191F2F5C'}}>
                        <span className="white strong mb-2">Top Short Videos</span>
                        {/* <div className="info-wrapper">
                            <div className="main-wrapper">
                                <img src={sIcon} alt="sIcon" className='sIcon'/>
                                <div className="main-wrapper">
                                    <img src={tikTockIcon} alt="videoImg1" className='video-img'/>
                                    <div className="text-wrapper">
                                        <span className="white small strong">Apr/12/2024</span>
                                        <span className="ex-small strong">829k Comments</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main-wrapper">
                                <div className="progress-wrapper">
                                    <span className="small white">605k</span>
                                    <ProgressBar now={60}/>
                                </div>
                                <img src={pinIcon} alt="pinIcon" className='pinIcon'/>
                            </div>
                        </div> */}
                        {
                            topShortVideos?.map((item, index) => {
                                return (
                                    <a style={{ cursor: "pointer" }} className="info-wrapper" href={`${item?.link || item?.Link}`} target="_blank"
                                    >
                                        <div className="main-wrapper">
                                            <img src={sIcon} alt="sIcon" className='sIcon' />
                                            <div className="main-wrapper">
                                                <img src={profileData?.platform == 'instagram' ? `${process.env.REACT_APP_IG_SERVER}proxy?media_url=${encodeURIComponent(
                                                    item.thumbnail
                                                )}` : item?.thumbnail || tikTockIcon} alt="videoImg1" className='video-img' />
                                                <div className="text-wrapper">
                                                    <span className="white small strong">{moment(item?.date || item['Date Posted']).format('MM/DD/YY')}</span>
                                                    <span className="ex-small strong">{ShortenNumber(item?.comments || item?.Comments)} Comments</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-wrapper">
                                            <div className="progress-wrapper">
                                                <span className="small white">{ShortenNumber(item?.views || item?.views)}</span>
                                                <ProgressBar now={60} />
                                            </div>
                                            {/* <img src={pinIcon} alt="pinIcon" className='pinIcon' /> */}
                                        </div>
                                    </a>
                                )
                            })
                        }
                        {/* <button onClick={() => { setVideoChartTab('dashboard') }} className="btn-style simple dark">View Full Data <FaArrowRightLong /></button> */}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 mb-3 ">
                <div className="main-info-wrapper" style={{background : '#191F2F5C'}}>
                        <span className="white strong mb-2">Top Long videos</span>
                        {/* <div className="info-wrapper">
                            <div className="main-wrapper">
                                <img src={sIcon} alt="sIcon" className='sIcon'/>
                                <div className="main-wrapper">
                                    <img src={videoImg1} alt="videoImg1" className='video-img'/>
                                    <div className="text-wrapper">
                                        <span className="white small strong">Apr/12/2024</span>
                                        <span className="ex-small strong">829k Comments</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main-wrapper">
                                <div className="progress-wrapper">
                                    <span className="small white">605k</span>
                                    <ProgressBar now={60}/>
                                </div>
                            </div>
                        </div> */}
                        {topLongVideos?.map((item, index) => {
                            return (
                                <a style={{ cursor: "pointer" }} className="info-wrapper" key={index + 1} href={`${item?.link || item?.Link}`} target="_blank"
                                >
                                    <div className="main-wrapper">
                                        <img src={sIcon} alt="sIcon" className='sIcon' />
                                        <div className="main-wrapper">
                                            <img src={profileData?.platform == 'instagram' ? `${process.env.REACT_APP_IG_SERVER}proxy?media_url=${encodeURIComponent(
                                                item.thumbnail
                                            )}` : item?.avatar || item?.thumbnail || tikTockIcon} alt="videoImg1" className='video-img' />
                                            <div className="text-wrapper">
                                                <span className="white small strong">{moment(item?.date || item['Date Posted']).format('MM/DD/YY')}</span>
                                                <span
                                                    className="ex-small strong">{ShortenNumber(item?.Comments || item?.comments)} Comments</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-wrapper">
                                        <div className="progress-wrapper">
                                            <span className="small white">{ShortenNumber(item?.Views || item?.views)}</span>
                                            <ProgressBar now={60} />
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                        {/* <button onClick={() => { setVideoChartTab('dashboard') }} className="btn-style simple dark">View Full Data <FaArrowRightLong /></button> */}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 mb-3 ">
                    {/* <div className="main-info-wrapper">
                        <span className="white strong mb-2">Videos posted by year</span>
                        {videosPerYear?.slice(-3)?.map((item, index) => {
                            return (
                                <div className="info-wrapper" key={index + 1}>
                                    <span className="small white">{item['year']}</span>
                                    <span className="small white">{ShortenNumber(item?.totalVideos)} Videos</span>
                                    <div className="main-wrapper">
                                        <div className="progress-wrapper">
                                            <span className="small white">{ShortenNumber(item?.totalViews)}</span>
                                            <ProgressBar now={60} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <button onClick={() => { setVideoChartTab('dashboard') }} className="btn-style simple dark">View Full Data <FaArrowRightLong /></button>
                    </div> */}
                     <div className="main-info-wrapper" style={{background : '#191F2F5C'}}>
                        <span className="white strong mb-2">Top videos</span>
                        {topVideos?.map((item, index) => {
                            return (
                                <a style={{ cursor: "pointer" }} className="info-wrapper" key={index + 1} href={`${item?.link || item?.Link}`} target="_blank"
                                >
                                    <div className="main-wrapper">
                                        <img src={sIcon} alt="sIcon" className='sIcon' />
                                        <div className="main-wrapper">
                                            <img src={profileData?.platform == 'instagram' ? `${process.env.REACT_APP_IG_SERVER}proxy?media_url=${encodeURIComponent(
                                                item.thumbnail
                                            )}` : item?.avatar || item?.thumbnail || tikTockIcon} alt="videoImg1" className='video-img' />
                                            <div className="text-wrapper">
                                                <span className="white small strong">{moment(item?.date || item['Date Posted']).format('MM/DD/YY')}</span>
                                                <span
                                                    className="ex-small strong">{ShortenNumber(item?.Comments || item?.comments)} Comments</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-wrapper">
                                        <div className="progress-wrapper">
                                            <span className="small white">{ShortenNumber(item?.Views || item?.views)}</span>
                                            <ProgressBar now={60} />
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                        {/* <button onClick={() => { setVideoChartTab('dashboard') }} className="btn-style simple dark">View Full Data <FaArrowRightLong /></button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
