import React, { useState, useEffect, useRef } from "react";
import HorizontalBarChart from "../../HorizontalBarChart";
import Calendar from "react-calendar";
import HorizontalBarChartGridLines from "../../HorizontalBarChartGridLines";
import { CircularProgressbar } from "react-circular-progressbar";
import { IoBookmark, IoCalendarClear, IoGrid } from "react-icons/io5";
import userAvatarIcon from "../../../assets/images/icons/user-avatar.png";
import userIcon from "../../../assets/images/icons/user-icon.png";
import { PiShareFatFill, PiVideoCameraFill } from "react-icons/pi";
import { BsFillEyeFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import smsPurpleIcon from "../../../assets/images/icons/sms-purple-icon.png";
import popularPersonalIcon from "../../../assets/images/icons/popualar-personality-icon.png";
import video1 from "../../../assets/images/video-img-1.png";
import tikTokSmallIcon from "../../../assets/images/icons/tiktok-small-icon.png";
import { FcLike } from "react-icons/fc";
import LargeGraphChart from "../../LargeGraphChart";
import ChartsVideoInfoTopCards from "../../ChartsVideoInfoTopCards";
import LongVsShortVideosBig from "../../LongVsShortVideosBig";
import { IoLogoTiktok } from "react-icons/io5";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import LargeGraphChartContentType from "../../LargeGraphChartContentType";
import PostsGroupedByDuration from "../../PostsGroupedByDuration";
import axios from "axios";
import { scrapServerBasepath } from "../../../services/constents";
import DoubleLineChart from "../../all-charts/DoubleLineChart";
import ShortenNumber from "../../../utilities/ShortenNumber";

import avatar from "../../../assets/images/icons/avatar-icon.png";
import ChartsVideoInfoTopCards2 from "../../ChartsVideoInfoTopCards2";
import { Spinner } from "react-bootstrap";
import DoubleChart from "../../DoubleChart";
import ContentBarChart from "../../all-charts/ContentBarChart.jsx";

const ContentCharacteristicsCharts = ({
    analyticsData,
    handleDateRange,
    dateRange,
    setDateRange,
    profileData,
    longVideosPercentage,
    shortVideosPercentage,
    videoChartTab,
    setVideoChartTab,
    handleDateRange1,
    dateRange1,
    setDateRange1,
    loadingVideos,
    analyticsDataLoading
}) => {

    const [filteredData, setFilteredData] = useState([]);
    const [chartTitle, setChartTitle] = useState('All Videos');
    const [filterType, setFilterType] = useState('long');
    const [monthlyData, setMonthlyData] = useState([]);

    const [hashtagsData, setHashtagsData] = useState([])

    var videosData = useSelector((state) => state?.reels?.reelsData);
    var reelsComplete = useSelector((state) => state?.reels?.reelsComplete);
    const [selectingStartDate, setSelectingStartDate] = useState(true);
    const [selectingStartDate1, setSelectingStartDate1] = useState(true);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [startDate1, setStartDate1] = useState("");
    const [endDate1, setEndDate1] = useState("");

    const [daysFilter, setDaysFilter] = useState(null)
    const [daysFilter1, setDaysFilter1] = useState(30)

    const [brandMentions, setBrandMentions] = useState([])
    const [filteredVideosData, setFilteredVideosData] = useState([]);

    const [brandsLoading, setBrandsLoading] = useState(false)
    const [hashtagsLoading, setHashtagsLoading] = useState(false)

    const modalRef = useRef(null);
    const modalRef1 = useRef(null);


    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.box-icon')) {
            setDateRange(false);
        }
    };

    const handleClickOutside1 = (event) => {
        if (modalRef1.current && !modalRef1.current.contains(event.target) && !event.target.closest('.box-icon')) {
            setDateRange1(false);
        }
    };

    useEffect(() => {
        if (dateRange) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dateRange]);

    useEffect(() => {
        if (dateRange1) {
            document.addEventListener('mousedown', handleClickOutside1);
        } else {
            document.removeEventListener('mousedown', handleClickOutside1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside1);
        };
    }, [dateRange1]);


    const fetchHashtags = async () => {
        setHashtagsLoading(true)
        try {
            const response = await axios.get(`${scrapServerBasepath}/api/hashtags-info?username=${profileData?.username}`)
            const data = response.data;
            setHashtagsData(data)
            setHashtagsLoading(false)
        } catch (error) {
            console.log('error fetching hashtags data', error)
            setHashtagsLoading(false)
        }
    }

    const fetchBrandMentions = async () => {
        setBrandsLoading(true)
        try {
            const response = await axios.get(`${scrapServerBasepath}/api/brand-mentions?username=${profileData?.username}`)
            const data = response.data;
            setBrandMentions(data)
            setBrandsLoading(false)
        } catch (error) {
            console.log('error fetching hashtags data', error)
            setBrandsLoading(false)
        }
    }


    useEffect(() => {
        if (!profileData?.username) {
            return;
        }
        if (profileData?.platform == 'tiktok') {
            fetchHashtags()
            fetchBrandMentions()
        }
    }, [])

    console.log('hashtags data', hashtagsData)

    useEffect(() => {
        // Filter videos by date
        let filteredByDate = videosData;
        if (daysFilter !== null && videosData) {
            const now = new Date();
            filteredByDate = videosData.filter(video => {
                const videoDate = new Date(profileData?.platform === 'instagram' ? video['Date Posted'] : video.date); // assuming `video.date` is the date property
                const diffTime = now - videoDate;
                const diffDays = diffTime / (1000 * 60 * 60 * 24);
                return diffDays <= daysFilter;
            });
        }
        else if (startDate && endDate && videosData) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            filteredByDate = videosData.filter(video => {
                const videoDate = new Date(profileData?.platform === 'instagram' ? video['Date Posted'] : video.date); // assuming `video.date` is the date property
                return videoDate >= start && videoDate <= end;
            });
        }
        console.log('filter by data ', filteredByDate)

        let filtered;
        switch (filterType) {
            case 'long':
                filtered = filteredByDate.filter(video => profileData?.platform == 'instagram' ? video?.Duration > 30 : video?.duration > 30);
                setChartTitle('Long Videos');
                break;
            case 'short':
                filtered = filteredByDate.filter(video => profileData?.platform == 'instagram' ? video?.Duration <= 30 : video?.duration <= 30);
                setChartTitle('Short Videos');
                break;
            default:
                filtered = filteredByDate;
                setChartTitle('All Videos');
        }
        setFilteredData(filtered);
    }, [filterType, videosData, profileData, daysFilter, startDate, endDate]);


    useEffect(() => {
        const aggregateDataByDay = (timeseries) => {
            const dailyCount = {};

            timeseries.forEach(video => {
                const date = dayjs(profileData?.platform === 'instagram' ? video['Date Posted'] : video.date).format('DD-MM-YY');
                if (!dailyCount[date]) {
                    dailyCount[date] = 0;
                }
                dailyCount[date]++;
            });

            const days = Object.keys(dailyCount).sort((a, b) => dayjs(a, 'DD-MM-YY') - dayjs(b, 'DD-MM-YY'));
            const data = days.map(day => dailyCount[day]);

            return { labels: days, data };
        };

        const aggregateDataByYear = (timeseries) => {
            const yearlyCount = {};

            timeseries.forEach(video => {
                const year = dayjs(profileData?.platform === 'instagram' ? video['Date Posted'] : video.date).format('YYYY');
                if (!yearlyCount[year]) {
                    yearlyCount[year] = 0;
                }
                yearlyCount[year]++;
            });

            const years = Object.keys(yearlyCount).sort();
            const data = years.map(year => yearlyCount[year]);

            return { labels: years, data };
        };

        // const filtered = filteredData.filter(video => {
        //     const videoDate = dayjs(profileData?.platform === 'instagram' ? video['Date Posted'] : video.date);
        //     const isAfterStartDate = startDate ? videoDate.isAfter(dayjs(startDate).subtract(1, 'day')) : true;
        //     const isBeforeEndDate = endDate ? videoDate.isBefore(dayjs(endDate).add(1, 'day')) : true;
        //     return isAfterStartDate && isBeforeEndDate;
        // });
        const filtered = filteredData;

        console.log('filtered data is ', filtered)
        let result;
        if (daysFilter || (startDate && endDate)) {
            if (filterType == 'all') {
                const shortFiltered = filteredData?.filter(video => profileData?.platform == 'instagram' ? video?.Duration <= 30 : video?.duration <= 30);
                const longFiltered = filteredData?.filter(video => profileData?.platform == 'instagram' ? video?.Duration > 30 : video?.duration > 30);
                const shortResult = aggregateDataByDay(shortFiltered)
                const longResult = aggregateDataByDay(longFiltered)
                result = [shortResult, longResult]
            } else {

                result = aggregateDataByDay(filtered, 'views'); // replace 'views' with the appropriate type
                console.log('result is ', result)
            }
        } else {
            if (filterType == 'all') {
                const shortFiltered = filteredData?.filter(video => profileData?.platform == 'instagram' ? video?.Duration <= 30 : video?.duration <= 30);
                const longFiltered = filteredData?.filter(video => profileData?.platform == 'instagram' ? video?.Duration > 30 : video?.duration > 30);
                const shortResult = aggregateDataByYear(shortFiltered)
                const longResult = aggregateDataByYear(longFiltered)
                result = [shortResult, longResult]
            } else {

                result = aggregateDataByYear(filtered);
            }
        }

        setMonthlyData(result);
    }, [filteredData]);


    useEffect(() => {
        if (reelsComplete && profileData?.platform == 'tiktok') {
            fetchBrandMentions()
            fetchHashtags()
        }
    }, [reelsComplete])


    console.log("🚀 ~ analyticsData content chartacter statistics charts:", analyticsData)
    console.log('monthly data ', monthlyData)


    const handleSelectByDays = (days) => {
        if (days) {
            if (!isNaN(days)) {
                const today = new Date();
                const newStartDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000); // Calculate startDate by subtracting days
                setStartDate(newStartDate);
                setEndDate(today); // endDate is today
            }
        } else {
            setStartDate(null);
            setEndDate(null)
        }
    }

    useEffect(() => {
        handleSelectByDays(document.getElementById('lastDaysInput')?.value || parseInt(document.getElementById('upTodayInput')?.value))
    }, [parseInt(document.getElementById('lastDaysInput')?.value), parseInt(document.getElementById('upTodayInput')?.value)]);


    const handlePredefinedRange = (range) => {
        // alert(range)
        const currentDate = new Date();
        let newStartDate = new Date();
        let newEndDate = new Date();

        switch (range) {
            case 'today':
                newStartDate = currentDate;
                newEndDate = currentDate;
                break;
            case 'yesterday':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - 1);
                newEndDate = newStartDate;
                break;
            case 'week':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - currentDate.getDay());
                newEndDate = currentDate;
                break;
            case 'last-days':
                const lastDays = parseInt(document.getElementById('lastDaysInput')?.value);
                handleSelectByDays(lastDays)
                break;

            case 'month':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                break;
            case 'lastMonth':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
                break;
            case 'thisYear':
                newStartDate = new Date(currentDate.getFullYear(), 0, 1);
                newEndDate = new Date(currentDate.getFullYear(), 11, 31);
                break;
            case 'upToday':
                const upTodayDays = parseInt(document.getElementById('upTodayInput').value);
                handleSelectByDays(upTodayDays)
                break;
            case 'reset':
                newStartDate = null;
                newEndDate = null
                setFilterType('long')
            // Add more cases as needed
            default:
                break;
        }
        // setDateRange([{ startDate, endDate, key: 'selection' }]);
        // setSelectedRange(range);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handlePredefinedRange1 = (range) => {
        // alert(range)
        const currentDate = new Date();
        let newStartDate = new Date();
        let newEndDate = new Date();

        switch (range) {
            case 'today1':
                newStartDate = currentDate;
                newEndDate = currentDate;
                break;
            case 'yesterday1':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - 1);
                newEndDate = newStartDate;
                break;
            case 'week1':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - currentDate.getDay());
                newEndDate = currentDate;
                break;
            case 'last-days1':
                const lastDays = parseInt(document.getElementById('lastDaysInput')?.value);
                handleSelectByDays(lastDays)
                break;

            case 'month1':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                break;
            case 'lastMonth1':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
                break;
            case 'thisYear1':
                newStartDate = new Date(currentDate.getFullYear(), 0, 1);
                newEndDate = new Date(currentDate.getFullYear(), 11, 31);
                break;
            case 'upToday1':
                const upTodayDays = parseInt(document.getElementById('upTodayInput').value);
                handleSelectByDays(upTodayDays)
                break;
            case 'reset1':
                newStartDate = null;
                newEndDate = null
                setFilterType('long')
            // Add more cases as needed
            default:
                break;
        }
        // setDateRange([{ startDate, endDate, key: 'selection' }]);
        // setSelectedRange(range);
        setStartDate1(newStartDate);
        setEndDate1(newEndDate);
    };

    const handleDateSelect = (selectedDate) => {
        if (selectingStartDate) {
            setStartDate(selectedDate);
        } else {
            setEndDate(selectedDate);
        }
        setSelectingStartDate(!selectingStartDate); // Toggle selectingStartDate flag
    };

    const handleDateSelect1 = (selectedDate) => {
        if (selectingStartDate1) {
            setStartDate1(selectedDate);
        } else {
            setEndDate1(selectedDate);
        }
        setSelectingStartDate1(!selectingStartDate1); // Toggle selectingStartDate flag
    };


    return (
        <div className="characteristics-section">
            {/* <div className="section-title" style={{ border: "none", padding: '21px 28px 0' }}>
                <div className="section-heading" style={{ margin: 0 }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="align-self-center col-xl-5 col-lg-5 col-md-5  mb-3 mb-md-0">
                                <div className="title">
                                    <strong className="white large">Content characteristics charts</strong>
                                </div>
                            </div>
                            <div className="align-self-center col-xl-7 col-lg-7 col-md-7">
                                <div className="date-wrapper">
                                    <ul className="d-list">
                                        <li onClick={() => setDaysFilter1((pre) => pre == 7 ? null : 7)}
                                            className={`${daysFilter1 == 7 ? 'white' : ''}`}><span>7D</span></li>
                                        <li onClick={() => setDaysFilter1((pre) => pre == 30 ? null : 30)}
                                            className={`${daysFilter1 == 30 ? 'white' : ''}`}><span>30D</span></li>
                                    </ul>
                                    <div onClick={handleDateRange} className="d-flex gap-2 align-items-center " style={{ cursor: 'pointer' }}>
                                        <div className={`box-icon ${dateRange1 ? "active" : ""}`} >
                                            <IoCalendarClear />
                                        </div>
                                        <p className="white">Set date range</p>
                                    </div>
                                </div>
                                <div ref={modalRef1} className={`select-time-main-wrapper chart ${dateRange1 ? "active" : ""}`}>
                                    <form action="#">
                                        <ul className="time" onChange={(e) => handlePredefinedRange1(e.target.value)}>
                                            <li>
                                                <input type="radio" id="Today1" name="time1" value="today1" />
                                                <label htmlFor="Today1">Today</label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="Yesterday1"
                                                    name="time1"
                                                    value="yesterday1"
                                                />
                                                <label htmlFor="Yesterday1">Yesterday</label>
                                            </li>
                                            <li>
                                                <input type="radio" id="week1" name="time1" value="week1" />
                                                <label htmlFor="week1">This Week</label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="last-days1"
                                                    name="time1"
                                                    value="last-days1"
                                                />
                                                <label htmlFor="last-days1">
                                                    Last <input type="text" id="lastDaysInput1" /> Days
                                                </label>
                                            </li>

                                            <li>
                                                <input type="radio" id="month1" name="time1" value="month1" />
                                                <label htmlFor="month1">
                                                    This Month{" "}
                                                    {`(${new Date().toLocaleString("en-US", {
                                                        month: "long",
                                                        timeZone: "UTC",
                                                    })})`}
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="lastMonth1"
                                                    name="time1"
                                                    value="lastMonth1"
                                                />
                                                <label htmlFor="lastMonth1">
                                                    Last Month
                                                    {`(${getLastMonthName()})`}
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="thisYear1"
                                                    name="time1"
                                                    value="thisYear1"
                                                />
                                                <label htmlFor="thisYear1">
                                                    This Year {`(${new Date().getFullYear()})`}
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="upToday1"
                                                    name="time1"
                                                    value="upToday1"
                                                />
                                                <label htmlFor="upToday">
                                                    <input type="text" id="upTodayInput1" /> Days up to today
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="reset1"
                                                    name="time1"
                                                    value="reset1"
                                                />
                                                <label htmlFor="reset1">
                                                    Reset
                                                </label>
                                            </li>
                                        </ul>
                                    </form>
                                    <div className="calendar-wrapper">
                                        <div className="select-time">
                                            <div className="select">
                                                <span className="white">
                                                    {startDate1 ? startDate1.toLocaleDateString() : ' '}
                                                </span>
                                            </div>
                                            <span className="white mx-3">-</span>
                                            <div className="select">
                                                <span className="white">
                                                    {endDate1 ? endDate1.toLocaleDateString() : ' '}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="calandar">
                                            <CalendarComponent />
                                            <Calendar onChange={handleDateSelect1}
                                                value={selectingStartDate1 ? startDate1 : endDate1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>characteristics-tab-content-main
            </div> */}
            <div className="characteristics-content p-0" style={{background:'transparent'}}>
                <div className="container-fluid">
                    <div className="row px-0 px-lg-0">
                        <div className="col-xl-5 col-lg-6 col-md-5 mb-3 mb-md-0">
                            <div className="main-wrapper">
                                <strong className="white title">Most common durations</strong>
                                <div className="bar-chart-wrapper">
                                    {/* <HorizontalBarChartGridLines   durationData={analyticsData?.video_duration_data || []}  /> */}
                                    <ContentBarChart durationData={analyticsData?.video_duration_data || []} />

                                </div>
                                <p className="small">
                                    Through comprehensive analysis,
                                    <span>
                                        {analyticsData?.video_duration_data
                                            ? Object.keys(analyticsData.video_duration_data).reduce((a, b) =>
                                                analyticsData.video_duration_data[a] > analyticsData.video_duration_data[b] ? a : b
                                            )?.replace('duration_', '')?.replace('_', '-')
                                            : ' '}
                                    </span> <span> </span>
                                    emerges as the best content duration. Seize this strategic insight to optimize your
                                    content creation and amplify your reach when it matters most.
                                </p>

                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-7  ">
                            <LongVsShortVideosBig profileata={profileData || { platform: "tiktok" }} daysFilter={daysFilter1} startDate={startDate1} endDate={endDate1} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="characteristics-content">
                <div className="section-heading px-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="align-self-center col-xl-5 col-lg-5 col-md-5 col-4    ">
                                <div className="title">
                                    <strong className="white">Content type</strong>
                                </div>
                            </div>
                            <div className="align-self-center col-xl-7 col-lg-7 col-md-7 col-8   ">
                                <div className="date-wrapper">
                                    <ul className="d-list">
                                        <li onClick={() => setDaysFilter((pre) => pre == 7 ? null : 7)}
                                            className={`${daysFilter == 7 ? 'white' : ''}`}><span>7D</span></li>
                                        <li onClick={() => setDaysFilter((pre) => pre == 30 ? null : 30)}
                                            className={`${daysFilter == 30 ? 'white' : ''}`}><span>30D</span></li>
                                    </ul>
                                    <div
                                        className={`box-icon ${dateRange ? "active" : ""}`}
                                        onClick={handleDateRange}
                                    >
                                        <IoCalendarClear />
                                    </div>
                                    <p className="white">Set date range</p>
                                </div>
                                <div ref={modalRef} className={`select-time-main-wrapper chart ${dateRange ? "active" : ""}`}>
                                    <form action="#">
                                        <ul className="time" onChange={(e) => handlePredefinedRange(e.target.value)}>
                                            <li>
                                                <input type="radio" id="Today" name="time" value="today" />
                                                <label htmlFor="Today">Today</label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="Yesterday"
                                                    name="time"
                                                    value="yesterday"
                                                />
                                                <label htmlFor="Yesterday">Yesterday</label>
                                            </li>
                                            <li>
                                                <input type="radio" id="week" name="time" value="week" />
                                                <label htmlFor="week">This Week</label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="last-days"
                                                    name="time"
                                                    value="last-days"
                                                />
                                                <label htmlFor="last-days">
                                                    Last <input type="text" id="lastDaysInput" /> Days
                                                </label>
                                            </li>

                                            <li>
                                                <input type="radio" id="month" name="time" value="month" />
                                                <label htmlFor="month">
                                                    This Month{" "}
                                                    {`(${new Date().toLocaleString("en-US", {
                                                        month: "long",
                                                        timeZone: "UTC",
                                                    })})`}
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="lastMonth"
                                                    name="time"
                                                    value="lastMonth"
                                                />
                                                <label htmlFor="lastMonth">
                                                    Last Month
                                                    {/*{`(${getLastMonthName()})`}*/}
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="thisYear"
                                                    name="time"
                                                    value="thisYear"
                                                />
                                                <label htmlFor="thisYear">
                                                    This Year {`(${new Date().getFullYear()})`}
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="upToday"
                                                    name="time"
                                                    value="upToday"
                                                />
                                                <label htmlFor="upToday">
                                                    <input type="text" id="upTodayInput" /> Days up to today
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="reset"
                                                    name="time"
                                                    value="reset"
                                                />
                                                <label htmlFor="reset">
                                                    Reset
                                                </label>
                                            </li>
                                        </ul>
                                    </form>
                                    <div className="calendar-wrapper">
                                        <div className="select-time">
                                            <div className="select">
                                                <span className="white">
                                                    {startDate ? startDate.toLocaleDateString() : ' '}
                                                </span>
                                            </div>
                                            <span className="white mx-3">-</span>
                                            <div className="select">
                                                <span className="white">
                                                    {endDate ? endDate.toLocaleDateString() : ' '}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="calandar">
                                            {/* <CalendarComponent /> */}
                                            <Calendar onChange={handleDateSelect}
                                                value={selectingStartDate ? startDate : endDate} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chart-content-wrapper py-0 py-md-2">
                    <div className="row">
                        <div className="col-xl-2 col-md-3 col-lg-3 col-4    ">
                            <ul className="lists" style={{ height: '100%', margin: '20px 0' }}>
                                <li>
                                    <button className={`${filterType == 'long' ? 'active' : ''}`}
                                        onClick={() => setFilterType('long')}>
                                        <div className="d-flex align-baseline"><IoLogoTiktok
                                            style={{ color: '#ffffff', width: 14 }} /><span>L</span></div>
                                        Long
                                    </button>
                                </li>
                                <li>
                                    <button className={`${filterType == 'short' ? 'active' : ''}`}
                                        onClick={() => setFilterType('short')}>
                                        <div className="d-flex align-baseline"><IoLogoTiktok
                                            style={{ color: '#ffffff', width: 14 }} /><span>S</span></div>
                                        Short
                                    </button>
                                </li>
                                <li>
                                    <button className={`${filterType == 'all' ? 'active' : ''}`}
                                        onClick={() => setFilterType('all')}><IoGrid
                                            style={{ color: '#ffffff' }} /> All
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-10 col-md-9 col-lg-9 col-8  ">
                            <div className="graph-wrapper">
                                <div className="graph-title">
                                    <div className="dot" style={{ background: '#FF165D' }}></div>
                                    <span>{filterType}</span></div>
                                {filterType == 'all' ? <DoubleChart dataset={monthlyData} analyticsData={analyticsData}
                                    filterType={filterType} startDate={startDate}
                                    endDate={endDate} colors={{
                                        border1: 'rgb(0, 242, 234)',
                                        border2: 'rgba(255, 22, 93, 1)'
                                    }} /> : <LargeGraphChartContentType data={monthlyData} analyticsData={analyticsData}
                                        filterType={filterType} startDate={startDate}
                                        endDate={endDate} colors={filterType == 'short' ? {
                                            borderColor: 'rgb(0, 242, 234)',
                                            gradient: 'rgba(25, 47, 62, 1)'
                                        } : filterType == 'long' ? {
                                            borderColor: 'rgba(255, 22, 93, 1)',
                                            gradient: 'rgba(66, 30, 56, 1)'
                                        } : { borderColor: '#FF6400', gradient: 'rgba(66, 43, 39, 1)' }} />}
                                {/* <DoubleLineChart /> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid">
          <div className="row">
            <div className="col-xl-7 col-lg-12">
              <div className="main-wrapper">
                <strong className="white title">Brand Mentions</strong>
                <div className="brand-mention">
                  <div className="brand-content">
                    <div className="img-wrapper">
                      <img src={userAvatarIcon} alt="userAvatarIcon"/>
                    </div>
                    <div className="content">
                      <span className="strong white">Michaelsanchez</span>
                      <div className="text">
                        <span className="ex-small">@michaelsanchez</span>
                        <div>
                          <span className="white ex-small">300k</span>
                          <span className="ex-small">Followers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <span className="white strong">36 Mentions</span>
                  </div>
                </div>
                <div className="brand-mention">
                  <div className="brand-content">
                    <div className="img-wrapper">
                      <img src={userAvatarIcon} alt="userAvatarIcon"/>
                    </div>
                    <div className="content">
                      <span className="strong white">Michaelsanchez</span>
                      <div className="text">
                        <span className="ex-small">@michaelsanchez</span>
                        <div>
                          <span className="white ex-small">300k</span>
                          <span className="ex-small">Followers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <span className="white strong">36 Mentions</span>
                  </div>
                </div>
                <div className="brand-mention">
                  <div className="brand-content">
                    <div className="img-wrapper">
                      <img src={userAvatarIcon} alt="userAvatarIcon"/>
                    </div>
                    <div className="content">
                      <span className="strong white">Michaelsanchez</span>
                      <div className="text">
                        <span className="ex-small">@michaelsanchez</span>
                        <div>
                          <span className="white ex-small">300k</span>
                          <span className="ex-small">Followers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <span className="white strong">36 Mentions</span>
                  </div>
                </div>
                <div className="brand-mention">
                  <div className="brand-content">
                    <div className="img-wrapper">
                      <img src={userAvatarIcon} alt="userAvatarIcon"/>
                    </div>
                    <div className="content">
                      <span className="strong white">Michaelsanchez</span>
                      <div className="text">
                        <span className="ex-small">@michaelsanchez</span>
                        <div>
                          <span className="white ex-small">300k</span>
                          <span className="ex-small">Followers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <span className="white strong">36 Mentions</span>
                  </div>
                </div>
                <div className="brand-mention">
                  <div className="brand-content">
                    <div className="img-wrapper">
                      <img src={userAvatarIcon} alt="userAvatarIcon"/>
                    </div>
                    <div className="content">
                      <span className="strong white">Michaelsanchez</span>
                      <div className="text">
                        <span className="ex-small">@michaelsanchez</span>
                        <div>
                          <span className="white ex-small">300k</span>
                          <span className="ex-small">Followers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <span className="white strong">36 Mentions</span>
                  </div>
                </div>
                <div className="brand-mention">
                  <div className="brand-content">
                    <div className="img-wrapper">
                      <img src={userAvatarIcon} alt="userAvatarIcon"/>
                    </div>
                    <div className="content">
                      <span className="strong white">Michaelsanchez</span>
                      <div className="text">
                        <span className="ex-small">@michaelsanchez</span>
                        <div>
                          <span className="white ex-small">300k</span>
                          <span className="ex-small">Followers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <span className="white strong">36 Mentions</span>
                  </div>
                </div>
                <div className="brand-mention">
                  <div className="brand-content">
                    <div className="img-wrapper">
                      <img src={userAvatarIcon} alt="userAvatarIcon"/>
                    </div>
                    <div className="content">
                      <span className="strong white">Michaelsanchez</span>
                      <div className="text">
                        <span className="ex-small">@michaelsanchez</span>
                        <div>
                          <span className="white ex-small">300k</span>
                          <span className="ex-small">Followers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <span className="white strong">36 Mentions</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-12">
              <div className="main-wrapper">
                <strong className="white title">Most used happashtags</strong>
                <div className="bar-chart-wrapper">
                  <HorizontalBarChart
                      hashtagsData={analyticsData?.hashtags_data || []}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>*/}
            </div>
            <div className="characteristics-content" style={{ background: 'transparent', padding: '0 0 0 0' }}>
                {/* <ChartsVideoInfoTopCards analyticsData={analyticsData || []} /> */}
                <ChartsVideoInfoTopCards2 analyticsData={analyticsData || []} videoChartTab={videoChartTab}
                    setVideoChartTab={setVideoChartTab} profileData={profileData} />
            </div>
            <div className="characteristics-content light-dark px-2 ">
                <div className="section-heading m-0">
                    <div className="title">
                        <strong className="white">User’s post grouped by duration</strong>
                    </div>
                </div>
                {/* <div className="table-wrapper dark gapped">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 70 }}></th>
                                <th style={{ minWidth: 100 }}><span className='strong'>Range    </span></th>
                                <th style={{ minWidth: 100 }}><span className='strong'>Count    </span></th>
                                <th style={{ minWidth: 150 }}><span className='strong'>Views    </span></th>
                                <th style={{ minWidth: 150 }}><span className='strong'>Likes    </span></th>
                                <th style={{ minWidth: 100 }}><span className='strong'>Comments </span></th>
                                <th style={{ minWidth: 100 }}><span className='strong'>Shares   </span></th>
                                <th style={{ minWidth: 100 }}><span className='strong'>Bookmarks</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ width: 70 }}><p className=''>1 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>1+ min </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>135 </p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>46,236,876</p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>2,281,092 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>202 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>920 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>293 </p></td>
                            </tr>
                            <tr>
                                <td style={{ width: 70 }}><p className=''>2 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>1+ min </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>135 </p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>46,236,876</p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>2,281,092 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>202 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>920 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>293 </p></td>
                            </tr>
                            <tr>
                                <td style={{ width: 70 }}><p className=''>2 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>1+ min </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>135 </p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>46,236,876</p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>2,281,092 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>202 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>920 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>293 </p></td>
                            </tr>
                            <tr>
                                <td style={{ width: 70 }}><p className=''>3 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>1+ min </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>135 </p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>46,236,876</p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>2,281,092 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>202 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>920 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>293 </p></td>
                            </tr>
                            <tr>
                                <td style={{ width: 70 }}><p className=''>4 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>1+ min </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>135 </p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>46,236,876</p></td>
                                <td style={{ minWidth: 150 }}><p className='white'>2,281,092 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>202 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>920 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>293 </p></td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
                <PostsGroupedByDuration platform={profileData?.platform || 'tiktok'} />
            </div>
            <div className="characteristics-content light-dark px-2 ">
                <div className="section-heading mb-2 ">
                    <div className="title">
                        <strong className="white d-block mb-2">Hashtags</strong>
                        <span>These are the hashtags which @{profileData?.username} has used in their own videos</span>
                    </div>
                </div>
                <div className="table-wrapper dark gapped" style={{ maxHeight: 500 }}>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ minWidth: 150 }}><span className='strong'>Hashtag name    </span></th>
                                <th style={{ minWidth: 100 }}><span className='strong'>Count    </span></th>
                                <th style={{ minWidth: 180 }}><span className='strong'>3 Most recent videos    </span></th>
                                <th style={{ minWidth: 150 }}><span className='strong'>Last video    </span></th>
                                <th style={{ minWidth: 100 }}><span className='strong'>Mentions </span></th>
                                <th style={{ minWidth: 150 }}><span className='strong'>% of long form</span></th>
                                <th style={{ minWidth: 150 }}><span className='strong'>% of short form</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {profileData?.platform == 'youtube' ? analyticsDataLoading ? <span
                                className='white d-inline-flex gap-2'>  <Spinner
                                    animation="grow" size="sm" /><span
                                        className=' d-inline-block white'>Analyzing...</span></span> : analyticsData?.hashtags_data ? analyticsData?.hashtags_data?.map((hashtag, index) => {
                                            return <tr key={index}>
                                                <td style={{ minWidth: 250 }}><p className='white'>{hashtag?.hashtag || ''} </p></td>
                                                <td style={{ minWidth: 100 }}><p className='white'>{hashtag?.count || 0} </p></td>
                                                <td style={{ minWidth: 180 }}><p className='white'>N/A</p></td>
                                                <td style={{ minWidth: 150 }}>
                                                    <p className='white'>N/A</p>
                                                </td>
                                                <td style={{ minWidth: 150 }}><p className='white'>N/A</p></td>
                                                <td style={{ minWidth: 150 }}><p className='white'>N/A</p>
                                                </td>
                                                <td style={{ minWidth: 150 }}><p className='white'>N/A</p>
                                                </td>
                                            </tr>
                                        }) : <span>No data found.</span> : hashtagsLoading ? <span
                                            className='white d-inline-flex gap-2'>  <Spinner
                                                animation="grow" size="sm" /><span
                                                    className=' d-inline-block white'>Analyzing...</span></span> : hashtagsData?.length > 0 ? hashtagsData.map((hashtag, index) => {
                                                        return <tr key={index}>
                                                            <td style={{ minWidth: 250 }}><p className='white'>#{hashtag?.title || ''} </p></td>
                                                            <td style={{ minWidth: 100 }}><p className='white'>{hashtag?.count || 0} </p></td>
                                                            <td style={{ minWidth: 180 }}>
                                                                <div className="images-wrapper">
                                                                    {/* <img src={video1} alt="video1" />
                                            <img src={video1} alt="video1" />
                                            <img src={video1} alt="video1" /> */}
                                                                    {hashtag?.lastThree?.map((item, index) => {
                                                                        return (
                                                                            <a href={`https://tiktok.com/@${profileData?.username}/video/${item?.video?.id}`}
                                                                                target="_blank"> <img key={index}
                                                                                    src={item?.video?.cover || tikTokSmallIcon}
                                                                                    alt="video1" /></a>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </td>
                                                            <td style={{ minWidth: 150 }}>
                                                                <div className="images-wrapper">
                                                                    <a href={`https://tiktok.com/@${profileData?.username}/video/${hashtag?.lastVideo?.video?.id}`}
                                                                        target="_blank"><img
                                                                            src={hashtag?.lastVideo?.video?.cover || tikTokSmallIcon} alt="video1" /></a>
                                                                </div>
                                                            </td>
                                                            <td style={{ minWidth: 150 }}><p className='white'>{hashtag?.mentionCount} </p></td>
                                                            <td style={{ minWidth: 150 }}><p
                                                                className='white'>{Math.ceil((hashtag?.longVideos / hashtag?.totalVideos) * 100)}% </p>
                                                            </td>
                                                            <td style={{ minWidth: 150 }}><p
                                                                className='white'>{Math.ceil((hashtag?.shortVideos / hashtag?.totalVideos) * 100)}% </p>
                                                            </td>
                                                        </tr>
                                                    }) : (loadingVideos && profileData?.platform == 'tiktok') ? <span
                                                        className='white d-inline-flex gap-2'>  <Spinner
                                                            animation="grow" size="sm" /><span
                                                                className=' d-inline-block white'>Analyzing...</span></span> :
                                <span>No data found.</span>}
                            {/* <tr>
                            <td style={{minWidth: 150}}><p className='white'>#entrepreneur </p></td>
                            <td style={{minWidth: 100}}><p className='white'>135 </p></td>
                            <td style={{minWidth: 180}}>
                                <div className="images-wrapper">
                                    <img src={video1} alt="video1"/>
                                    <img src={video1} alt="video1"/>
                                    <img src={video1} alt="video1"/>
                                </div>
                            </td>
                            <td style={{minWidth: 150}}>
                                <div className="images-wrapper">
                                    <img src={video1} alt="video1"/>
                                </div>
                            </td>
                            <td style={{minWidth: 150}}><p className='white'>202 </p></td>
                            <td style={{minWidth: 200}}><p className='white'>82% </p></td>
                            <td style={{minWidth: 150}}><p className='white'>23% </p></td>
                            <td style={{minWidth: 150}}><p className='white'>68% </p></td>
                        </tr> */}

                        </tbody>
                    </table>
                </div>
            </div>
            <div className="characteristics-content light-dark px-2 ">
                <div className="section-heading mb-3 ">
                    <div className="title">
                        <strong className="white d-block mb-2">Brand Mentions</strong>
                        <span>These are the accounts which @{profileData?.username} has mentioned in their own videos</span>
                    </div>
                </div>
                <div className="table-wrapper dark gapped" style={{ maxHeight: 500 }}>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ minWidth: 350 }}><span className='strong'>Profile information  </span></th>
                                <th style={{ minWidth: 200 }}><span className='strong'>Most recent mention    </span></th>
                                <th style={{ minWidth: 150 }}><span className='strong'>Videos   </span></th>
                                <th style={{ minWidth: 150 }}><span className='strong'>Views </span></th>
                                <th style={{ minWidth: 100 }}><span className='strong'>Likes </span></th>
                                <th style={{ minWidth: 150 }}><span className='strong'>Mentions  </span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td style={{ minWidth: 250 }}>
                                    <div className="user-detail-wrapper">
                                        <div className="img-wrapper">
                                            <img src={userAvatarIcon} alt="userAvatarIcon" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="name">
                                                <strong className="white">Michaelsanchez</strong>
                                                <img src={popularPersonalIcon} alt="popularPersonalIcon" />
                                            </div>
                                            <div className="text mt-1">
                                                <span className='small'>@michaelsanchez</span>
                                                <span className='small'><span className="white small">300k</span> Followers</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td style={{ minWidth: 170 }}><p className='white'>21 days ago </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>8 </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>1.7m </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>169.0k </p></td>
                                <td style={{ minWidth: 100 }}><p className='white'>36 </p></td>
                            </tr> */}
                            {brandsLoading ? <span
                                className='white d-inline-flex gap-2'>  <Spinner
                                    animation="grow" size="sm" /><span
                                        className=' d-inline-block white'>Analyzing...</span></span> : brandMentions?.length > 0 ? brandMentions?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ minWidth: 250 }}>
                                                        <div className="user-detail-wrapper">
                                                            <div className="img-wrapper">
                                                                <img
                                                                    src={(item?.profileInfo && item?.profileInfo?.username) ? item?.profileInfo?.avatar : userIcon}
                                                                    alt="userAvatarIcon" />
                                                            </div>
                                                            <a className="content-wrapper"
                                                                href={`https://tiktok.com/@${item?.profileInfo?.username}`}
                                                                target="_blank">
                                                                <div className="name">
                                                                    <strong className="white">@{item?.username}</strong>
                                                                    {(item?.profileInfo && item?.profileInfo?.verified == true) &&
                                                                        <img src={popularPersonalIcon} alt="popularPersonalIcon" />}
                                                                </div>
                                                                <div className="text mt-1">
                                                                    <span className='small'>@{item?.username}</span>
                                                                    <span className='small'><span
                                                                        className="white small">{(item?.profileInfo && item?.profileInfo?.username) ? ShortenNumber(item?.profileInfo?.followerCount) : 'N/A'}</span> Followers</span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td style={{ minWidth: 170 }}><p className='white'>{item?.daysAgo || 1} days ago </p>
                                                    </td>
                                                    <td style={{ minWidth: 100 }}><p
                                                        className='white'>{ShortenNumber(item?.videoCount || 0)} </p></td>
                                                    <td style={{ minWidth: 100 }}><p
                                                        className='white'>{ShortenNumber(item?.totalLikes || 0)} </p></td>
                                                    <td style={{ minWidth: 100 }}><p
                                                        className='white'>{ShortenNumber(item?.totalViews || 0)} </p></td>
                                                    <td style={{ minWidth: 100 }}><p className='white'>{item?.mentionCount} </p></td>
                                                </tr>
                                            )
                                        }) : (loadingVideos && profileData?.platform == 'tiktok') ? <span
                                            className='white d-inline-flex gap-2'>  <Spinner
                                                animation="grow" size="sm" /><span
                                                    className=' d-inline-block white'>Analyzing...</span></span> :
                                <span>No data found.</span>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ContentCharacteristicsCharts;
