import React, { useEffect, useState, useRef } from "react";
import { DropdownButton, ProgressBar } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatarIcon from "../assets/images/icons/user-avatar.png";
import starBoxedIcon from "../assets/images/icons/star-boxed-icon.png";
import userBoxedIcon from "../assets/images/icons/user-boxed-icon.png";
import cameraBoxedIcon from "../assets/images/icons/camera-boxed-icon.png";
import bookmarkBoxedIcon from "../assets/images/icons/bookmark-boxed-icon.png";
import gemBlueImg from "../assets/images/icons/gem-blue-img.png";
import infoQuestionMark from "../assets/images/icons/info-question-mark-icon.png";
import captureIcon from "../assets/images/icons/capture-icon.png";
import cartUpIcon from "../assets/images/icons/chart-up-circular-arrow-icon.png";
import chartDownIcon from "../assets/images/icons/chart-down-circular-arrow-icon.png";
import cyanChartSmallImg from "../assets/images/cyan-chart-small-img.png";
import greenChartSmallImg from "../assets/images/green-chart-small-img.png";
import yellowChartSmallImg from "../assets/images/yelow-chart-small-img.png";
import purpleChartSmallImg from "../assets/images/purple-chart-small-img.png";
import pinkChartSmallImg from "../assets/images/pink-chart-small-img.png";
import orangeChartSmallImg from "../assets/images/orange-chart-small-img.png";
import sIcon from "../assets/images/icons/s-img.png";
import videoImg1 from "../assets/images/video-img-1.png";
import pinIcon from "../assets/images/icons/pin-icon.png";

import { IoCalendarClear } from "react-icons/io5";
import LargeGraphChart from "../components/LargeGraphChart";
import LargeGraphChart2 from "../components/LargeGraphChart2";
import HorizontalBarChartGridLines from "./HorizontalBarChartGridLines";
import { CircularProgressbar } from "react-circular-progressbar";
import MatrixChart from "./MatrixChart";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import HorizontalBarChart from "./HorizontalBarChart";
import Calendar from "react-calendar";

// Content Characteristics
import ContentCharacteristicsCharts from "./ChildCompos/ContentCharacteristics/ContentCharacteristicsCharts";

// Posting Behavior
import TimePostingPatterns from "./ChildCompos/PostingBehavior/TimePostingPatterns";
import VideosPostedOnDaysOnAverage from "./ChildCompos/PostingBehavior/VideosPostedOnDaysOnAverage";
import PostFrequency from "./ChildCompos/PostingBehavior/PostFrequency";
import VideosPostedByDayOfTheWeek from "./ChildCompos/PostingBehavior/VideosPostedByDayOfTheWeek";
import AverageEngagementPerPostChart from "./ChildCompos/EngagementAnalysis/AverageEngagementPerPostChart";
import DistributionOfLikes from "./ChildCompos/EngagementAnalysis/DistributionOfLikes";
import TimePostingPatternsMain from "./ChildCompos/PostingBehavior/TimePostingPatternsMain";
import VideosPostedByDayOfTheWeekMain from "./ChildCompos/PostingBehavior/VideosPostedByDayOfTheWeekMain";
import VideosPostedByDayOfTheWeekMainNew from "./ChildCompos/PostingBehavior/VideosPostedByDayOfTheWeekMainNew.js";
import AccountGrowthCharts from "./ChildCompos/PerformanceMetrics/AccountGrowthCharts";
import ChartsVideoInfoTopCards from "./ChartsVideoInfoTopCards";
import DashboardTotalSmallCharts from "./DashboardTotalSmallCharts";
import HorizontalBarChart2Duration from "./HorizontalBarChart2Duration";
import GithubContrubutionChart from "./GithubContrubutionChart";
import DashboardAverageCards from "./DashboardAverageCards";
import HorizontalBarChartWeekly from "./HorizontalBarChartWeekly.jsx";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { calculateEngagementTimeseries } from "../utilities/CalculateEngagemntSeries.js";
import BarChart from "./all-charts/BarChartNew.jsx";
import ShortenNumber from "../utilities/ShortenNumber.js";
import moment from "moment/moment.js";

export default function Charts({ chartTabs, setChartTabs, profileData, allAnalyticsData, thirtyDaysAnalytics, videoChartTab, setVideoChartTab, analyticsDataLoading, analyticsDataLoading30d, loadingVideos }) {
    // const [videoChartTab, setVideoChartTab] = useState("videos");
    const [smallChartTabs, setSmallChartTabs] = useState("video");
    // const [chartTabs, setChartTabs] = useState("performance");
    const [chartData, setChartData] = useState([]);
    const [shortVideosPercentage, setShortVideosPercentage] = useState(0);
    const [longVideosPercentage, setLongVideosPercentage] = useState(0);
    const [postFrequencyPerDay, setPostFrequencyPerday] = useState(0);
    const [postFrequencyPerWeek, setPostFrequencyPerWeek] = useState(0);
    const [postFrequencyPerMonth, setPostFrequencyPerMonth] = useState(0);
    const [postFrequencyPerYear, setPostFreqencyPeryear] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startDate2, setStartDate2] = useState("");
    const [endDate2, setEndDate2] = useState("");
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [daysFilter, setDaysFilter] = useState(30)
    const [yearFilter, setYearFilter] = useState(false)
    const [chartLabels, setChartLabels] = useState([]);
    const [pointVideos, setPointVideos] = useState([])
    const [analyticsData, setAnalyticsData] = useState([]);


    useEffect(() => {
        if ((daysFilter === 30 || daysFilter === 7) && (!endDate2 && !endDate)) {
            setAnalyticsData(thirtyDaysAnalytics);
        } else {
            setAnalyticsData(allAnalyticsData);
        }
    }, [daysFilter, thirtyDaysAnalytics, allAnalyticsData, endDate, endDate2]);

    const videoChartTabHandler = (tab) => {
        setVideoChartTab(tab);
    };
    const smallChartTabsHandler = (tab) => {
        setSmallChartTabs(tab);
    };
    const chartTabsHandler = (tab) => {
        setChartTabs(tab);
    };


    const handleMouseEnter = (index) => {
        setActiveDropdown(index);
    };

    const handleMouseLeave = () => {
        setActiveDropdown(null);
    };

    const isDateInRange = (date, start, end) => {
        const currentDate = new Date(date);
        return currentDate >= new Date(start) && currentDate <= new Date(end);
    };

    const filterDataByDateRange = (data) => {
        const now = new Date();
        if (startDate && endDate) {
            return data.filter(entry => isDateInRange(entry["Date Posted"], startDate, endDate));
        }
        if (yearFilter == true) {
            const currentYear = now.getFullYear();
            return data.filter(entry => new Date(entry['Date Posted']).getFullYear() === currentYear);
        }
        else {
            if (daysFilter == 30) {
                const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
                return data.filter(entry => new Date(entry['Date Posted']) >= oneMonthAgo);
            } else if (daysFilter == 7) {
                const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
                return data.filter(entry => new Date(entry['Date Posted']) >= oneWeekAgo);
            }
            return data;
        }
    };

    // const generateLabels = (data) => {
    //     if (daysFilter == 30) {
    //         // Generate labels for monthly data
    //         return data.map(entry => new Date(entry['Date Posted']).toLocaleDateString("en-US", { month: 'short', day: 'numeric' }));
    //     } else if (daysFilter == 7) {
    //         // Generate labels for weekly data
    //         return data.map((entry, index) => {
    //             const date = new Date(entry['Date Posted']);
    //             date.setDate(date.getDate() + index); // Add index to get next days
    //             return date.toLocaleDateString("en-US", { weekday: 'short', month: 'short', day: 'numeric' });
    //         });
    //     }
    //     // Default: Generate labels from data date
    //     return data.map(entry => new Date(entry['Date Posted']).toLocaleDateString("en-US"));
    // };
    const generateLabels = (data) => {
        const format = 'MM/DD/YY';
        const today = moment();

        if (daysFilter == 30) {
            // Generate labels for the last 30 days
            return Array.from({ length: 30 }, (_, i) => today.subtract(i, 'day').format(format)).reverse();
        } else if (daysFilter == 7) {
            // Generate labels for the last 7 days
            return Array.from({ length: 7 }, (_, i) => today.subtract(i, 'day').format(format)).reverse();
        } else if (startDate && endDate) {
            // Generate labels for the custom date range
            const start = dayjs(startDate);
            const end = dayjs(endDate);
            const daysDiff = end.diff(start, 'days') + 1; // +1 to include the end date
            return Array.from({ length: daysDiff }, (_, i) => start.clone().add(i, 'day').format(format));
        } else {
            // Default: Generate labels per year
            const years = new Set(data.map(entry => dayjs(entry['Date Posted']).year()));
            return Array.from(years).sort().map(year => `${year}`);
        }
    };
    const generateYearlyLabels = (data) => {
        // Extract unique years from the data
        const uniqueYears = [...new Set(data.map(entry => new Date(entry['Date Posted']).getFullYear()))];

        // Sort the years in ascending order
        uniqueYears.sort((a, b) => a - b);

        // Generate labels for each year
        const labels = uniqueYears.map(year => year.toString());

        return labels;
    };


    function aggregateDataByMonth(timeseries, type) {
        const aggregatedData = {};

        timeseries.forEach(entry => {
            const month = dayjs(entry['Date Posted'] || entry.date).format('YYYY-MM');
            if (!aggregatedData[month]) {
                aggregatedData[month] = 0;
            }
            aggregatedData[month] += entry[type];
        });

        const labels = Object.keys(aggregatedData).sort();
        const data = labels.map(label => aggregatedData[label]);

        return { labels, data };
    }

    function aggregateDataByDay(timeseries, type) {
        const aggregatedData = {};

        timeseries.forEach(entry => {
            const day = moment(entry['Date Posted'] || entry.date).format('MM/DD/YY');
            if (!aggregatedData[day]) {
                aggregatedData[day] = 0;
            }
            aggregatedData[day] += entry[type];
        });

        const labels = Object.keys(aggregatedData).sort((a, b) => new Date(a) - new Date(b));
        const data = labels.map(label => aggregatedData[label]);

        // Reformat labels to DD-MM-YY
        const formattedLabels = labels.map(label => moment(label).format('MM/DD/YY'));

        console.log('labels ', formattedLabels);
        return { labels: formattedLabels, data };
    }

    function getVideosYearly(videosSeries) {
        const videosByYear = {};

        videosSeries?.forEach(entry => {
            const year = new Date(entry['Date Posted']).getFullYear();

            if (!videosByYear[year]) {
                videosByYear[year] = [];
            }

            if (entry?.Videos > 0) {
                const videos = Array.isArray(entry?.details?.[1]) ? entry?.details?.[1] : [];
                const thumbnails = Array.isArray(entry?.details?.[2]) ? entry?.details?.[2] : [];

                for (let i = 0; i < videos.length; i++) {
                    videosByYear[year].push({
                        link: videos[i],
                        thumbnail: thumbnails[i]
                    });
                }
            }
        });

        // Convert the result to an array of objects for each year
        const result = Object.keys(videosByYear).map(year => ({
            year: parseInt(year, 10),
            videos: videosByYear[year]
        }));

        return result;
    }

    function getVideosMonthly(videosSeries) {
        const videosByMonth = {};

        videosSeries?.forEach(entry => {
            const date = new Date(entry['Date Posted']);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12

            const yearMonth = `${year}-${month < 10 ? '0' + month : month}`;

            if (!videosByMonth[yearMonth]) {
                videosByMonth[yearMonth] = [];
            }

            if (entry?.Videos > 0) {
                const videos = Array.isArray(entry?.details?.[1]) ? entry?.details?.[1] : [];
                const thumbnails = Array.isArray(entry?.details?.[2]) ? entry?.details?.[2] : [];

                for (let i = 0; i < videos.length; i++) {
                    videosByMonth[yearMonth].push({
                        link: videos[i],
                        thumbnail: thumbnails[i]
                    });
                }
            }
        });

        // Convert the result to an array of objects for each month
        const result = Object.keys(videosByMonth).map(yearMonth => ({
            year: yearMonth,
            videos: videosByMonth[yearMonth]
        }));

        return result;
    }


    function getVideosDaily(videosSeries) {
        const videosByDay = {};

        videosSeries?.forEach(entry => {
            const dateObj = new Date(entry['Date Posted']);
            const day = ('0' + dateObj.getDate()).slice(-2);
            const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
            const year = dateObj.getFullYear().toString().slice(-2); // Get the last two digits of the year

            const date = `${day}-${month}-${year}`; // Format the date as dd-mm-yy

            if (!videosByDay[date]) {
                videosByDay[date] = [];
            }

            if (entry?.Videos > 0) {
                const videos = Array.isArray(entry?.details?.[1]) ? entry?.details?.[1] : [];
                const thumbnails = Array.isArray(entry?.details?.[2]) ? entry?.details?.[2] : [];

                for (let i = 0; i < videos.length; i++) {
                    videosByDay[date].push({
                        link: videos[i],
                        thumbnail: thumbnails[i]
                    });
                }
            }
        });

        // Convert the result to an array of objects for each day
        const result = Object.keys(videosByDay).map(date => ({
            year: date,
            videos: videosByDay[date]
        }));

        return result;
    }


    // function aggregateDataByDay(timeseries, type) {
    //     const aggregatedData = {};

    //     timeseries.forEach(entry => {
    //         const day = dayjs(entry['Date Posted'] || entry.date).format('YYYY-MM-DD');
    //         if (!aggregatedData[day]) {
    //             aggregatedData[day] = 0;
    //         }
    //         aggregatedData[day] += entry[type];
    //     });

    //     const labels = Object.keys(aggregatedData).sort((a, b) => new Date(a) - new Date(b));
    //     const data = labels.map(label => aggregatedData[label]);

    //     // Format labels to "MMM D" (e.g., "Jun 1", "Jun 2")
    //     const formattedLabels = labels.map(label => dayjs(label).format('MMM D'));

    //     console.log('labels ', formattedLabels);
    //     return { labels: formattedLabels, data };
    // }

    function aggregateDataByYear(timeseries, type) {
        const aggregatedData = {};

        timeseries.forEach(entry => {
            const year = dayjs(entry['Date Posted'] || entry.date).format('YYYY');
            if (!aggregatedData[year]) {
                aggregatedData[year] = 0;
            }
            aggregatedData[year] += entry[type];
        });

        const labels = Object.keys(aggregatedData).sort();
        const data = labels.map(label => aggregatedData[label]);

        return { labels, data };
    }
    const generateHourlyLabels = () => {
        const hours = Array.from({ length: 24 }, (_, i) => {
            const hour = i % 12 === 0 ? 12 : i % 12;
            const period = i < 12 ? 'AM' : 'PM';
            return `${hour} ${period}`;
        });
        return hours;
    };

    const aggregateDataByHour = (timeseries, type) => {
        const aggregatedData = Array(24).fill(0);

        timeseries.forEach(entry => {
            const hour = new Date(entry['Timestamp'] || entry['Date Posted']).getHours();
            console.log(`Date Posted: ${entry['Date Posted']}, Hour: ${hour}, Type: ${type}, Value: ${entry[type]}`);
            aggregatedData[hour] += entry[type];
        });

        return aggregatedData;
    };

    useEffect(() => {
        setChartLabels([])
        setStartDate(null)
        setEndDate(null)
        // setDaysFilter(30)
        setYearFilter(false)
        if (chartTabs == 'performance') {
            setDaysFilter(30)
            setSmallChartTabs('video')
        } else if (chartTabs == 'engagement') {
            setDaysFilter(30)
            setSmallChartTabs('likes-engagement');
        } else if (chartTabs == 'behavior') {
            setDaysFilter(null)
            setSmallChartTabs('engagement-behavior')
        }
    }, [chartTabs])

    useEffect(() => {
        if (chartTabs == "performance") {
            if (smallChartTabs === "video") {
                const filteredVideosTimeseries = filterDataByDateRange(analyticsData?.videos_timeseries || []);
                let labelsData = []
                let dataArray = []
                if (yearFilter == true) {
                    const { labels, data } = aggregateDataByMonth(filteredVideosTimeseries, 'Videos');
                    const videos = getVideosMonthly(filteredVideosTimeseries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }
                else if (daysFilter || (startDate && endDate)) {
                    const { labels, data } = aggregateDataByDay(filteredVideosTimeseries, 'Videos');
                    const videos = getVideosDaily(filteredVideosTimeseries || [])
                    console.log('videos date ', videos)
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                } else {
                    const { labels, data } = aggregateDataByYear(filteredVideosTimeseries, 'Videos');
                    const videos = getVideosYearly(filteredVideosTimeseries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }

                setChartLabels(labelsData);
                setChartData(dataArray);
            } else if (smallChartTabs == "Views") {
                const filteredViwsTimeseries = filterDataByDateRange(analyticsData?.views_timeseries || []);
                let labelsData = []
                let dataArray = []
                if (yearFilter == true) {
                    const { labels, data } = aggregateDataByMonth(filteredViwsTimeseries, 'Views');
                    const videos = getVideosMonthly(filteredViwsTimeseries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }
                else if (daysFilter || (startDate && endDate)) {
                    const { labels, data } = aggregateDataByDay(filteredViwsTimeseries, 'Views');
                    labelsData = labels;
                    dataArray = data;
                } else {

                    const { labels, data } = aggregateDataByYear(filteredViwsTimeseries, 'Views');
                    labelsData = labels;
                    dataArray = data
                }

                setChartLabels(labelsData);
                setChartData(dataArray);
            } else if (smallChartTabs == "Followers") {
                const filteredFollowersTimeseries = filterDataByDateRange(analyticsData?.followers_timeseries || []);
                if (filteredFollowersTimeseries?.length == 0) {
                    setChartData([23, 11, 198, 0, 87, 76, 10, 5])
                    return
                }
                let labelsData = []
                let dataArray = []
                if (yearFilter == true) {
                    const { labels, data } = aggregateDataByMonth(filteredFollowersTimeseries, 'Followers');
                    const videos = getVideosMonthly(filteredFollowersTimeseries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }
                else if (daysFilter || (startDate && endDate)) {
                    const { labels, data } = aggregateDataByDay(filteredFollowersTimeseries, 'Followers');
                    labelsData = labels;
                    dataArray = data;
                } else {

                    const { labels, data } = aggregateDataByYear(filteredFollowersTimeseries, 'Followers');
                    labelsData = labels;
                    dataArray = data
                }

                setChartLabels(labelsData);
                setChartData(dataArray);
            } else if (smallChartTabs == "Likes") {
                console.log("Likes");
                const likes_timeseries = filterDataByDateRange(analyticsData?.likes_timeseries || []);

                let labelsData = []
                let dataArray = []
                if (yearFilter == true) {
                    const { labels, data } = aggregateDataByMonth(likes_timeseries, 'Likes');
                    const videos = getVideosMonthly(likes_timeseries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }
                else if (daysFilter || (startDate && endDate)) {
                    const { labels, data } = aggregateDataByDay(likes_timeseries, 'Likes');
                    labelsData = labels;
                    dataArray = data;
                } else {

                    const { labels, data } = aggregateDataByYear(likes_timeseries, 'Likes');
                    labelsData = labels;
                    dataArray = data
                }

                setChartLabels(labelsData);
                setChartData(dataArray);
            } else if (smallChartTabs == "comment") {
                console.log("comment");
                const commentsSeries = filterDataByDateRange(analyticsData?.comments_timeseries || []);

                let labelsData = []
                let dataArray = []
                if (yearFilter == true) {
                    const { labels, data } = aggregateDataByMonth(commentsSeries, 'Comments');
                    const videos = getVideosMonthly(commentsSeries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }
                else if (daysFilter || (startDate && endDate)) {
                    const { labels, data } = aggregateDataByDay(commentsSeries, 'Comments');
                    labelsData = labels;
                    dataArray = data;
                } else {

                    const { labels, data } = aggregateDataByYear(commentsSeries, 'Comments');
                    labelsData = labels;
                    dataArray = data
                }

                setChartLabels(labelsData);
                setChartData(dataArray);
            } else if (smallChartTabs == "Bookmarks") {
                console.log("bookmarks");
                const bookmarksSeries = filterDataByDateRange(analyticsData?.bookmarks_timeseries || []);
                let labelsData = []
                let dataArray = []
                if (yearFilter == true) {
                    const { labels, data } = aggregateDataByMonth(bookmarksSeries, 'Bookmarks');
                    const videos = getVideosMonthly(bookmarksSeries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }
                else if (daysFilter || (startDate && endDate)) {
                    const { labels, data } = aggregateDataByDay(bookmarksSeries, 'Bookmarks');
                    labelsData = labels;
                    dataArray = data;
                } else {

                    const { labels, data } = aggregateDataByYear(bookmarksSeries, 'Bookmarks');
                    labelsData = labels;
                    dataArray = data
                }

                setChartLabels(labelsData);
                setChartData(dataArray);
            }
            else if (smallChartTabs == "shares") {
                console.log("bookmarks");
                const sharesSeries = filterDataByDateRange(analyticsData?.shares_timeseries || []);

                let labelsData = []
                let dataArray = [];
                if (yearFilter == true) {
                    const { labels, data } = aggregateDataByMonth(sharesSeries, 'Shares');
                    const videos = getVideosMonthly(sharesSeries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }
                else if (daysFilter || (startDate && endDate)) {
                    const { labels, data } = aggregateDataByDay(sharesSeries, 'Shares');
                    labelsData = labels;
                    dataArray = data;
                } else {

                    const { labels, data } = aggregateDataByYear(sharesSeries, 'Shares');
                    labelsData = labels;
                    dataArray = data
                }

                setChartLabels(labelsData);
                setChartData(dataArray);
            } else if (smallChartTabs == "all") {
                // Combine all timeseries
                const combinedSeries = [
                    ...filterDataByDateRange(analyticsData?.videos_timeseries || []).map(entry => ({ date: entry["Date Posted"], value: entry.Videos })),
                    ...filterDataByDateRange(analyticsData?.views_timeseries || []).map(entry => ({ date: entry["Date Posted"], value: entry.Views })),
                    ...filterDataByDateRange(analyticsData?.followers_timeseries || []).map(entry => ({ date: entry["Date Posted"], value: entry.Followers })),
                    ...filterDataByDateRange(analyticsData?.likes_timeseries || []).map(entry => ({ date: entry["Date Posted"], value: entry.Likes })),
                    ...filterDataByDateRange(analyticsData?.comments_timeseries || []).map(entry => ({ date: entry["Date Posted"], value: entry.Comments })),
                    ...filterDataByDateRange(analyticsData?.bookmarks_timeseries || []).map(entry => ({ date: entry["Date Posted"], value: entry.Bookmarks })),
                    ...filterDataByDateRange(analyticsData?.shares_timeseries || []).map(entry => ({ date: entry["Date Posted"], value: entry.Shares })),
                ];

                // const aggregatedData = combinedSeries.reduce((acc, { date, value }) => {
                //     const existingEntry = acc.find(entry => entry.date === date);
                //     if (existingEntry) {
                //         existingEntry.value += value;
                //     } else {
                //         acc.push({ date, value });
                //     }
                //     return acc;
                // }, []);

                // const growthData = aggregatedData.map((entry, index) => {
                //     if (index === 0) {
                //         return 0;
                //     } else {
                //         return entry.value - aggregatedData[index - 1].value;
                //     }
                // });

                // const labels = daysFilter ? generateLabels(aggregatedData) : [];
                // setChartData(growthData);
                // setChartLabels(labels);
                let labelsData = []
                let dataArray = []
                if (yearFilter == true) {
                    const { labels, data } = aggregateDataByMonth(combinedSeries, 'value');
                    const videos = getVideosMonthly(combinedSeries || [])
                    setPointVideos(videos)
                    labelsData = labels;
                    dataArray = data;
                }
                else if (daysFilter || (startDate && endDate)) {
                    const { labels, data } = aggregateDataByDay(combinedSeries, 'value');
                    labelsData = labels;
                    dataArray = data;
                } else {

                    const { labels, data } = aggregateDataByYear(combinedSeries, 'value');
                    labelsData = labels;
                    dataArray = data
                }

                setChartLabels(labelsData);
                setChartData(dataArray);
            } else {
                const videos_timeseries = filterDataByDateRange(analyticsData?.videos_timeseries || []);
                const videosData = videos_timeseries.map((entry) => entry.Videos);

                // Calculate the growth values
                const growthData = videosData.map((value, index) => {
                    if (index === 0) {
                        // For the first value, the growth is 0
                        return 0;
                    } else {
                        // Calculate the difference between the current value and the previous value
                        return value - videosData[index - 1];
                    }
                });
                if (daysFilter || (startDate && endDate)) {
                    const labels = generateLabels(videos_timeseries);
                    setChartLabels(labels)
                } else {
                    setChartLabels([])
                }
                setChartData(growthData);
            }
        } else if (chartTabs == "engagement") {
            if (smallChartTabs === "videos-engagement") {
                const videos_timeseries = filterDataByDateRange(analyticsData?.videos_timeseries || []);

                const numSlots = 10; // You can adjust this value
                const minSlotSize = 2;

                // Calculate the size of each slot
                const slotSize = Math.max(Math.ceil(videos_timeseries.length / numSlots), minSlotSize);

                // Initialize an array to store the average likes growth for each slot
                const averageLikesGrowthArray = [];

                // Calculate the average likes growth for each slot
                for (let i = 0; i < numSlots; i++) {
                    const startIndex = i * slotSize;
                    const endIndex = Math.min((i + 1) * slotSize, videos_timeseries.length);

                    let totalLikesGrowth = 0;
                    let count = 0;

                    // Calculate total likes growth within the current slot
                    for (let j = startIndex + 1; j < endIndex; j++) {
                        const growth = videos_timeseries[j].Videos - videos_timeseries[j - 1].Videos;
                        if (growth >= 0) {
                            totalLikesGrowth += growth;
                            count++;
                        }
                    }

                    // Calculate the average likes growth for the current slot if count is not zero
                    if (count !== 0) {
                        const averageLikesGrowth = totalLikesGrowth / count;
                        averageLikesGrowthArray.push(averageLikesGrowth);
                    } else {
                        averageLikesGrowthArray.push(0); // Handle case with no valid growth
                    }
                }

                // Generate labels if daysFilter is applied
                if (daysFilter || (startDate && endDate)) {
                    const labels = generateLabels(videos_timeseries);
                    setChartLabels(labels);
                } else {
                    setChartLabels([]);
                    const labels = generateYearlyLabels(videos_timeseries);
                    setChartLabels(labels);
                }

                // Set the calculated chart data
                setChartData(averageLikesGrowthArray);
                console.log("growthData", averageLikesGrowthArray);
            }
            else if (smallChartTabs === "views-engagement") {
                console.log("views-engagement");

                const viewsTimeseries = filterDataByDateRange(analyticsData?.views_timeseries || []);
                let averageViewsArray = [];
                let labels = [];
                if (yearFilter == true) {
                    // Monthly data calculation
                    const monthlyData = {};

                    viewsTimeseries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();
                        const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12

                        const yearMonth = `${year}-${month < 10 ? '0' + month : month}`;

                        if (!monthlyData[yearMonth]) {
                            monthlyData[yearMonth] = { totalViews: 0, count: 0 };
                        }

                        monthlyData[yearMonth].totalViews += entry.Views;
                        monthlyData[yearMonth].count += 1;
                    });

                    Object.keys(monthlyData).sort().forEach(yearMonth => { // Sort by yearMonth to maintain chronological order
                        const { totalViews, count } = monthlyData[yearMonth];
                        averageViewsArray.push(count !== 0 ? Math.round(totalViews / count) : 0);
                        labels.push(yearMonth);
                    });
                }
                else if (daysFilter || (startDate && endDate)) {
                    // Daily data calculation
                    const numSlots = viewsTimeseries.length;
                    averageViewsArray = new Array(numSlots).fill(0);

                    for (let i = 0; i < viewsTimeseries.length; i++) {
                        const views = viewsTimeseries[i].Views;
                        averageViewsArray[i] = views;
                    }

                    // Generate daily labels
                    labels = generateLabels(viewsTimeseries);
                } else {
                    // Yearly data calculation
                    const yearlyData = {};

                    viewsTimeseries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();

                        if (!yearlyData[year]) {
                            yearlyData[year] = { totalViews: 0, count: 0 };
                        }

                        yearlyData[year].totalViews += entry.Views;
                        yearlyData[year].count += 1;
                    });

                    Object.keys(yearlyData).sort().forEach(year => { // Sort by year to maintain chronological order
                        const { totalViews, count } = yearlyData[year];
                        averageViewsArray.push(count !== 0 ? Math.round(totalViews / count) : 0);
                        labels.push(year);
                    });
                }

                setChartLabels(labels);
                setChartData(averageViewsArray);
                console.log("averageViewsData", averageViewsArray);
            }


            else if (smallChartTabs == "followers-engagement") {
                console.log("followers");
                const followersTimeseries = filterDataByDateRange(analyticsData?.folloewers_timeseries || []);

                const numSlots = 10; // You can adjust this value
                const minSlotSize = 2;

                // Calculate the size of each slot
                const slotSize = Math.max(Math.ceil(followersTimeseries.length / numSlots), minSlotSize);

                // Initialize an array to store the average growth for each slot
                const averageGrowthArray = [];
                // Calculate the average growth for each slot
                for (let i = 0; i < numSlots; i++) {
                    const startIndex = i * slotSize;
                    const endIndex = Math.min((i + 1) * slotSize, followersTimeseries.length);

                    let totalGrowth = 0;
                    let count = 0;

                    // Calculate total growth within the current slot
                    for (let j = startIndex + 1; j < endIndex; j++) {
                        totalGrowth += followersTimeseries[j].Followers - followersTimeseries[j - 1].Followers;
                        count++;
                    }

                    // Calculate the average growth for the current slot if count is not zero
                    if (count !== 0) {
                        const averageGrowth = totalGrowth / count;
                        averageGrowthArray.push(averageGrowth);
                    }
                }
                if (daysFilter || (startDate && endDate)) {
                    const labels = generateLabels(followersTimeseries);
                    setChartLabels(labels)
                } else {
                    // setChartLabels([])
                    const labels = generateYearlyLabels(followersTimeseries);
                    setChartLabels(labels);
                }
                setChartData(averageGrowthArray);
            } else if (smallChartTabs === "likes-engagement") {
                console.log("likes-engagement");

                const likesTimeseries = filterDataByDateRange(analyticsData?.likes_timeseries || []);
                let averageLikesGrowthArray = [];
                let labels = [];
                if (yearFilter) {
                    // Monthly data calculation
                    const monthlyData = {};

                    likesTimeseries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();
                        const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12

                        const yearMonth = `${year}-${month < 10 ? '0' + month : month}`;

                        if (!monthlyData[yearMonth]) {
                            monthlyData[yearMonth] = { totalGrowth: 0, count: 0 };
                        }

                        // Calculate growth from previous data if available
                        const previousLikes = entry.previousLikes !== undefined ? entry.previousLikes : 0;
                        const growth = entry.Likes - previousLikes;
                        monthlyData[yearMonth].totalGrowth += growth >= 0 ? growth : 0;
                        monthlyData[yearMonth].count += 1;
                    });

                    Object.keys(monthlyData).forEach(yearMonth => {
                        const { totalGrowth, count } = monthlyData[yearMonth];
                        averageLikesGrowthArray.push(count !== 0 ? Math.round(totalGrowth / count) : 0);
                        labels.push(yearMonth);
                    });
                }
                else if (daysFilter || (startDate && endDate)) {
                    // Daily data calculation
                    const numSlots = likesTimeseries.length;
                    averageLikesGrowthArray = new Array(numSlots).fill(0);

                    for (let i = 1; i < likesTimeseries.length; i++) {
                        const growth = likesTimeseries[i].Likes - likesTimeseries[i - 1].Likes;
                        averageLikesGrowthArray[i] = growth >= 0 ? growth : 0;
                    }

                    // Generate daily labels
                    labels = generateLabels(likesTimeseries);
                } else {
                    // Yearly data calculation
                    const yearlyData = {};

                    likesTimeseries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();

                        if (!yearlyData[year]) {
                            yearlyData[year] = { totalGrowth: 0, count: 0 };
                        }

                        // Calculate growth from previous data if available
                        const previousLikes = entry.previousLikes !== undefined ? entry.previousLikes : 0;
                        const growth = entry.Likes - previousLikes;
                        yearlyData[year].totalGrowth += growth >= 0 ? growth : 0;
                        yearlyData[year].count += 1;
                    });

                    Object.keys(yearlyData).forEach(year => {
                        const { totalGrowth, count } = yearlyData[year];
                        averageLikesGrowthArray.push(count !== 0 ? Math.round(totalGrowth / count) : 0);
                        labels.push(year);
                    });
                }

                setChartLabels(labels);
                setChartData(averageLikesGrowthArray);
                console.log("averageLikesGrowthData", averageLikesGrowthArray);
            }

            else if (smallChartTabs === "comments-engagement") {
                console.log("comments-engagement");

                const commentsTimeseries = filterDataByDateRange(analyticsData?.comments_timeseries || []);
                let averageCommentsGrowthArray = [];
                let labels = [];
                if (yearFilter) {
                    // Monthly data calculation
                    const monthlyData = {};

                    commentsTimeseries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();
                        const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12

                        const yearMonth = `${year}-${month < 10 ? '0' + month : month}`;

                        if (!monthlyData[yearMonth]) {
                            monthlyData[yearMonth] = { totalGrowth: 0, count: 0 };
                        }

                        // Calculate growth
                        const previousMonth = `${year}-${month - 1 < 10 ? '0' + (month - 1) : (month - 1)}`;
                        if (monthlyData[previousMonth]) {
                            const previousTotal = monthlyData[previousMonth].totalGrowth;
                            const growth = entry.Comments - previousTotal;
                            monthlyData[yearMonth].totalGrowth += growth >= 0 ? growth : 0;
                        } else {
                            monthlyData[yearMonth].totalGrowth += entry.Comments;
                        }
                        monthlyData[yearMonth].count += 1;
                    });

                    Object.keys(monthlyData).forEach(yearMonth => {
                        const { totalGrowth, count } = monthlyData[yearMonth];
                        averageCommentsGrowthArray.push(count !== 0 ? Math.round(totalGrowth / count) : 0);
                        labels.push(yearMonth);
                    });
                }
                else if (daysFilter || (startDate && endDate)) {
                    // Daily data calculation
                    const numSlots = commentsTimeseries.length;
                    averageCommentsGrowthArray = new Array(numSlots).fill(0);

                    for (let i = 1; i < commentsTimeseries.length; i++) {
                        const growth = commentsTimeseries[i].Comments - commentsTimeseries[i - 1].Comments;
                        averageCommentsGrowthArray[i] = growth >= 0 ? growth : 0;
                    }

                    // Generate daily labels
                    labels = generateLabels(commentsTimeseries);
                } else {
                    // Yearly data calculation
                    const yearlyData = {};

                    commentsTimeseries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();

                        if (!yearlyData[year]) {
                            yearlyData[year] = { totalGrowth: 0, count: 0 };
                        }

                        // Calculate growth
                        const previousYear = year - 1;
                        if (yearlyData[previousYear]) {
                            const previousTotal = yearlyData[previousYear].totalGrowth;
                            const growth = entry.Comments - previousTotal;
                            yearlyData[year].totalGrowth += growth >= 0 ? growth : 0;
                        } else {
                            yearlyData[year].totalGrowth += entry.Comments;
                        }
                        yearlyData[year].count += 1;
                    });

                    Object.keys(yearlyData).forEach(year => {
                        const { totalGrowth, count } = yearlyData[year];
                        averageCommentsGrowthArray.push(count !== 0 ? Math.round(totalGrowth / count) : 0);
                        labels.push(year);
                    });
                }

                setChartLabels(labels);
                setChartData(averageCommentsGrowthArray);
            }

            else if (smallChartTabs === "bookmarks-engagement") {
                console.log("bookmarks-engagement");

                const bookmarksSeries = filterDataByDateRange(analyticsData?.bookmarks_timeseries || []);

                if (bookmarksSeries.length === 0) {
                    setChartData([23, 234, 241, 2, 233, 0, 92, 133]);
                    return;
                }

                let averageBookmarksArray = [];
                let labels = [];
                if (yearFilter) {
                    // Monthly data calculation
                    const monthlyData = {};

                    bookmarksSeries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();
                        const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12

                        const yearMonth = `${year}-${month < 10 ? '0' + month : month}`;

                        if (!monthlyData[yearMonth]) {
                            monthlyData[yearMonth] = { totalBookmarks: 0, count: 0 };
                        }

                        monthlyData[yearMonth].totalBookmarks += entry.Bookmarks;
                        monthlyData[yearMonth].count += 1;
                    });

                    Object.keys(monthlyData).forEach(yearMonth => {
                        const { totalBookmarks, count } = monthlyData[yearMonth];
                        averageBookmarksArray.push(count !== 0 ? Math.round(totalBookmarks / count) : 0);
                        labels.push(yearMonth);
                    });
                }
                else if (daysFilter || (startDate && endDate)) {
                    // Daily data calculation
                    const numSlots = bookmarksSeries.length;
                    averageBookmarksArray = new Array(numSlots).fill(0);

                    for (let i = 0; i < bookmarksSeries.length; i++) {
                        const bookmarks = bookmarksSeries[i].Bookmarks;
                        averageBookmarksArray[i] = bookmarks;
                    }

                    // Generate daily labels
                    labels = generateLabels(bookmarksSeries);
                } else {
                    // Yearly data calculation
                    const yearlyData = {};

                    bookmarksSeries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();

                        if (!yearlyData[year]) {
                            yearlyData[year] = { totalBookmarks: 0, count: 0 };
                        }

                        yearlyData[year].totalBookmarks += entry.Bookmarks;
                        yearlyData[year].count += 1;
                    });

                    Object.keys(yearlyData).forEach(year => {
                        const { totalBookmarks, count } = yearlyData[year];
                        averageBookmarksArray.push(count !== 0 ? Math.round(totalBookmarks / count) : 0);
                        labels.push(year);
                    });
                }

                setChartLabels(labels);
                setChartData(averageBookmarksArray);
            }

            else if (smallChartTabs === "shares-engagement") {
                console.log("shares-engagement");

                const sharesSeries = filterDataByDateRange(analyticsData?.shares_timeseries || []);
                let averageSharesGrowthArray = [];
                let labels = [];
                if (yearFilter) {
                    // Monthly data calculation
                    const monthlyData = {};

                    sharesSeries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();
                        const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12

                        const yearMonth = `${year}-${month < 10 ? '0' + month : month}`;

                        if (!monthlyData[yearMonth]) {
                            monthlyData[yearMonth] = { totalShares: 0, count: 0 };
                        }

                        monthlyData[yearMonth].totalShares += entry.Shares;
                        monthlyData[yearMonth].count += 1;
                    });

                    Object.keys(monthlyData).forEach(yearMonth => {
                        const { totalShares, count } = monthlyData[yearMonth];
                        averageSharesGrowthArray.push(count !== 0 ? Math.round(totalShares / count) : 0);
                        labels.push(yearMonth);
                    });
                }
                else if (daysFilter || (startDate && endDate)) {
                    // Daily data calculation
                    const numSlots = sharesSeries.length;
                    averageSharesGrowthArray = new Array(numSlots).fill(0);

                    for (let i = 1; i < sharesSeries.length; i++) {
                        const growth = sharesSeries[i].Shares - sharesSeries[i - 1].Shares;
                        averageSharesGrowthArray[i] = growth >= 0 ? growth : 0;
                    }

                    // Generate daily labels
                    labels = generateLabels(sharesSeries);
                } else {
                    // Yearly data calculation
                    const yearlyData = {};

                    sharesSeries.forEach(entry => {
                        const date = new Date(entry['Date Posted']);
                        const year = date.getFullYear();

                        if (!yearlyData[year]) {
                            yearlyData[year] = { totalShares: 0, count: 0 };
                        }

                        yearlyData[year].totalShares += entry.Shares;
                        yearlyData[year].count += 1;
                    });

                    Object.keys(yearlyData).forEach(year => {
                        const { totalShares, count } = yearlyData[year];
                        averageSharesGrowthArray.push(count !== 0 ? Math.round(totalShares / count) : 0);
                        labels.push(year);
                    });
                }

                setChartLabels(labels);
                setChartData(averageSharesGrowthArray);
            }


            else {
                const videos_timeseries = filterDataByDateRange(analyticsData?.videos_timeseries || []);

                const numSlots = 10; // You can adjust this value
                const minSlotSize = 2;

                // Calculate the size of each slot
                const slotSize = Math.max(Math.ceil(videos_timeseries.length / numSlots), minSlotSize);

                // Initialize an array to store the average growth for each slot
                const averageGrowthArray = [];
                // Calculate the average growth for each slot
                for (let i = 0; i < numSlots; i++) {
                    const startIndex = i * slotSize;
                    const endIndex = Math.min((i + 1) * slotSize, videos_timeseries.length);

                    let totalGrowth = 0;
                    let count = 0;

                    // Calculate total growth within the current slot
                    for (let j = startIndex + 1; j < endIndex; j++) {
                        totalGrowth += videos_timeseries[j].Videos - videos_timeseries[j - 1].Videos;
                        count++;
                    }

                    // Calculate the average growth for the current slot if count is not zero
                    if (count !== 0) {
                        const averageGrowth = totalGrowth / count;
                        averageGrowthArray.push(averageGrowth);
                    }
                }
                if (daysFilter || (startDate && endDate)) {
                    const labels = generateLabels(videos_timeseries);
                    setChartLabels(labels)
                } else {
                    // setChartLabels([])
                    const labels = generateYearlyLabels(videos_timeseries);
                    setChartLabels(labels);
                }
                setChartData(averageGrowthArray);
            }
        } else if (chartTabs == "behavior") {

            if (smallChartTabs == "engagement-behavior") {
                const engagementTimeseriesRaw = calculateEngagementTimeseries(
                    analyticsData?.likes_timeseries || [],
                    analyticsData?.comments_timeseries || [],
                    analyticsData?.shares_timeseries || [],
                    analyticsData?.bookmarks_timeseries || []
                );
                let engagementTimeseries = filterDataByDateRange(engagementTimeseriesRaw);
                let calcEngagementPerHour = aggregateDataByHour(engagementTimeseries, 'Engagement');

                console.log("🚀 ~ calcEngagementPerHour ~ calcEngagementPerHour:::::", calcEngagementPerHour);

                let labels = generateHourlyLabels();

                setChartLabels(labels);
                setChartData(calcEngagementPerHour);
            }

            else if (smallChartTabs == "views-behavior") {
                console.log("views-behavior");
                const viewsSeriesData = filterDataByDateRange(analyticsData?.views_timeseries || []);

                let calcViewsPerHour = aggregateDataByHour(viewsSeriesData, 'Views');

                console.log("🚀 ~ calcViewsPerHour ~ calcViewsPerHour:::::", calcViewsPerHour);

                let labels = generateHourlyLabels();

                setChartLabels(labels);
                setChartData(calcViewsPerHour);
                console.log("growthData", calcViewsPerHour);
            }

            else if (smallChartTabs == "likes-behavior") {
                console.log("likes-behavior");
                const timesSeriesData = filterDataByDateRange(analyticsData?.likes_timeseries || []);

                let calcLikesPerHour = aggregateDataByHour(timesSeriesData, 'Likes');

                console.log("🚀 ~ calcLikesPerHour ~ calcLikesPerHour:::::", calcLikesPerHour);

                let labels = generateHourlyLabels();

                setChartLabels(labels);
                setChartData(calcLikesPerHour);
            }

            else if (smallChartTabs == "comments-behavior") {
                console.log("comments-behavior");
                const commentsSeriesData = filterDataByDateRange(analyticsData?.comments_timeseries || []);

                let calcCommentsPerHour = aggregateDataByHour(commentsSeriesData, 'Comments');

                console.log("🚀 ~ calcCommentsPerHour ~ calcCommentsPerHour:::::", calcCommentsPerHour);

                let labels = generateHourlyLabels();

                setChartLabels(labels);
                setChartData(calcCommentsPerHour);
            }
            else if (smallChartTabs == "shares-behavior") {
                console.log("shares-behavior");
                const sharesSeriesData = filterDataByDateRange(analyticsData?.shares_timeseries || []);

                let calcSharesPerHour = aggregateDataByHour(sharesSeriesData, 'Shares');

                console.log("🚀 ~ calcSharesPerHour ~ calcSharesPerHour:::::", calcSharesPerHour);

                let labels = generateHourlyLabels();

                setChartLabels(labels);
                setChartData(calcSharesPerHour);
            }

            else if (smallChartTabs == "bookmarks-behavior") {
                console.log("bookmarks-behavior");

                const bookmarksSeries = filterDataByDateRange(analyticsData?.bookmarks_timeseries || []);
                if (bookmarksSeries?.length == 0) {
                    setChartData([12, 0, 81.99, 211, 91, 66]);
                    return;
                }

                let calcBookmarksPerHour = aggregateDataByHour(bookmarksSeries, 'Bookmarks');

                console.log("🚀 ~ calcBookmarksPerHour ~ calcBookmarksPerHour:::::", calcBookmarksPerHour);

                let labels = generateHourlyLabels();

                setChartLabels(labels);
                setChartData(calcBookmarksPerHour);
            }

            else if (smallChartTabs == "videos-behavior") {
                console.log("videos-behavior");
                const videosSeriesData = filterDataByDateRange(analyticsData?.videos_timeseries || []);

                let calcVideosPerHour = aggregateDataByHour(videosSeriesData, 'Videos');

                console.log("🚀 ~ calcVideosPerHour ~ calcVideosPerHour:::::", calcVideosPerHour);

                let labels = generateHourlyLabels();

                setChartLabels(labels);
                setChartData(calcVideosPerHour);
            }

            else if (smallChartTabs == "followers-behavior") {
                console.log("followers-behavior");
                const followersSeriesData = filterDataByDateRange(analyticsData?.followers_timeseries || []);

                let calcFollowersPerHour = aggregateDataByHour(followersSeriesData, 'Followers');

                console.log("🚀 ~ calcFollowersPerHour ~ calcFollowersPerHour:::::", calcFollowersPerHour);

                let labels = generateHourlyLabels();

                setChartLabels(labels);
                setChartData(calcFollowersPerHour);
            }
            else {
                const videosSeriesData = filterDataByDateRange(analyticsData?.videos_timeseries || [])
                let calcVideosPerHour = aggregateDataByHour(videosSeriesData, 'Videos');
                console.log("🚀 ~ calcVideosPerHour ~ calcVideosPerHour:::::", calcVideosPerHour);
                let labels = generateHourlyLabels();
                setChartLabels(labels);
                setChartData(calcVideosPerHour);
            }
        }
    }, [analyticsData, smallChartTabs, chartTabs, startDate, endDate, daysFilter, yearFilter]);

    // useEffect(() => {
    //     const durationData = analyticsData?.video_duration_data || [];
    //     const totalDuration = Object.values(durationData).reduce((total, duration) => total + duration, 0);
    //     console.log("total duration ", totalDuration);
    //     // Calculate total duration of short videos (duration < 30)
    //     const shortDuration = durationData.duration_0_15 + durationData.duration_15_30 + durationData.duration_30_60;
    //     console.log("shortDuration", shortDuration);

    //     // Calculate total duration of long videos (duration >= 30)
    //     const longDuration = totalDuration - shortDuration;

    //     // Calculate percentages
    //     const shortPercentage = (shortDuration / totalDuration) * 100;
    //     const longPercentage = (longDuration / totalDuration) * 100;

    //     setShortVideosPercentage(Math.ceil(shortPercentage));
    //     setLongVideosPercentage(Math.ceil(longPercentage));

    //     // Output the percentages
    //     console.log(`Short Videos Percentage: ${shortPercentage.toFixed(2)}%`);
    //     console.log(`Long Videos Percentage: ${longPercentage.toFixed(2)}%`);
    // }, [analyticsData]);

    function getWeekNumber(date) {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const daysOffset = Math.round((date - firstDayOfYear) / (24 * 60 * 60 * 1000));
        return Math.ceil((date.getDay() + 1 + daysOffset) / 7);
    }

    // Helper function to get month and year
    function getMonthYear(date) {
        return `${date.getMonth() + 1}-${date.getFullYear()}`;
    }

    useEffect(() => {
        const videosTimeSeries = allAnalyticsData?.videos_timeseries || [];
        // Group data by date and calculate total videos posted for each date
        const videosByDate = {};
        videosTimeSeries.forEach((entry) => {
            const date = entry["Date Posted"];
            const videos = entry.Videos;
            if (!videosByDate[date]) {
                videosByDate[date] = 0;
            }
            videosByDate[date] += videos;
        });

        // Calculate post frequency per day
        const totalDays = Object.keys(videosByDate).length;
        const totalVideos = Object.values(videosByDate)?.reduce((acc, cur) => acc + cur, 0);
        const postFrequencyPerDay = (totalVideos / totalDays)?.toFixed(2);

        console.log("Post frequency per day:", postFrequencyPerDay);
        setPostFrequencyPerday(postFrequencyPerDay || 0);
        // Aggregate data to calculate post frequency per week
        const videosByWeek = {};
        Object.keys(videosByDate).forEach((date) => {
            const weekNumber = getWeekNumber(new Date(date));
            if (!videosByWeek[weekNumber]) {
                videosByWeek[weekNumber] = 0;
            }
            videosByWeek[weekNumber] += videosByDate[date];
        });
        const totalWeeks = Object.keys(videosByWeek).length;
        const postFrequencyPerWeek = (totalVideos / totalWeeks)?.toFixed(2);

        console.log("Post frequency per week:", postFrequencyPerWeek);
        setPostFrequencyPerWeek(postFrequencyPerWeek || 0);

        // Aggregate data to calculate post frequency per month
        const videosByMonth = {};
        Object.keys(videosByDate).forEach((date) => {
            const monthYear = getMonthYear(new Date(date));
            if (!videosByMonth[monthYear]) {
                videosByMonth[monthYear] = 0;
            }
            videosByMonth[monthYear] += videosByDate[date];
        });
        const totalMonths = Object.keys(videosByMonth).length;
        const postFrequencyPerMonth = (totalVideos / totalMonths)?.toFixed(2);

        console.log("Post frequency per month:", postFrequencyPerMonth);
        setPostFrequencyPerMonth(postFrequencyPerMonth || 0);
        // Aggregate data to calculate post frequency per year
        const videosByYear = {};
        Object.keys(videosByDate).forEach((date) => {
            const year = new Date(date).getFullYear();
            if (!videosByYear[year]) {
                videosByYear[year] = 0;
            }
            videosByYear[year] += videosByDate[date];
        });
        const totalYears = Object.keys(videosByYear).length;
        const postFrequencyPerYear = (totalVideos / totalYears)?.toFixed(2);

        console.log("Post frequency per year:", postFrequencyPerYear);
        setPostFreqencyPeryear(postFrequencyPerYear || 0);
        // Helper function to get week number
    }, [allAnalyticsData]);

    // For Distribution of Likes Chart
    // calculate last 30 days video count
    let currentDate = new Date(); // Get current date
    let thirtyDaysAgo = new Date(); // Get a date 30 days ago
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    let calcLast30DaysVideos = analyticsData && analyticsData?.videos_timeseries
        ?.filter((item) => new Date(item["Date Posted"]) >= thirtyDaysAgo) // Filter entries within the last 30 days
        ?.reduce((total, item) => total + item.Videos, 0); // Sum up the counts

    console.log("Total videos in the last 30 days:", calcLast30DaysVideos);

    const [dateRange, setDateRange] = useState(false);
    const [dateRange1, setDateRange1] = useState(false);
    const wrapperRef = useRef(null);
    const handleDateRange = () => {
        setDateRange(!dateRange);
    };
    const handleDateRange1 = () => {
        setDateRange1(!dateRange1);
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setDateRange(false);
            setDateRange1(false);
        }
    };

    const [dailyDataVideos, setDailyDataVideos] = useState([])
    const [dailyDataViews, setDailyDataViews] = useState([])
    const [dailyShortVideos, setDailyShortVideos] = useState([])
    const [dailyLongVideos, setDailyLongVideos] = useState([])
    const [dailyEngagement, setDailyEngagement] = useState([])
    var videosData = useSelector((state) => state?.reels?.reelsData);

    useEffect(() => {
        // Initialize array to store data for each day
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const videosPerDay = new Array(7).fill(0);
        const viewsPerDay = new Array(7).fill(0);
        const shortVideosPerDay = new Array(7).fill(0);
        const longVideosPerDay = new Array(7).fill(0);
        const engagementPerDay = new Array(7).fill(0);

        let videosTimeseries = filterDataByDateRange(analyticsData?.videos_timeseries || [])
        let viewsTimeseries = filterDataByDateRange(analyticsData?.views_timeseries || [])
        let engagementSeriesDate = calculateEngagementTimeseries(analyticsData?.likes_timeseries || [], analyticsData?.comments_timeseries || [], analyticsData?.shares_timeseries || [], analyticsData?.bookmarks_timeseries || [])
        let engagementSeries = filterDataByDateRange(engagementSeriesDate || [])
        // Iterate over all videos timeseries data and aggregate the statistics
        videosTimeseries?.forEach(entry => {
            const entryDate = new Date(entry['Date Posted']);
            const dayOfWeek = entryDate.getDay();
            videosPerDay[dayOfWeek] += entry.Videos || 0;
        });

        // Iterate over all views timeseries data and aggregate the statistics
        viewsTimeseries?.forEach(entry => {
            const entryDate = new Date(entry['Date Posted']);
            const dayOfWeek = entryDate.getDay();
            viewsPerDay[dayOfWeek] += entry.Views || 0;
        });

        engagementSeries?.forEach(entry => {
            const entryDate = new Date(entry['Date Posted']);
            const dayOfWeek = entryDate.getDay();
            engagementPerDay[dayOfWeek] += entry.Engagement || 0;
        });

        let filteredByDate = videosData;
        if (daysFilter !== null && videosData) {
            const now = new Date();
            filteredByDate = videosData.filter(video => {
                const videoDate = new Date(profileData?.platform === 'instagram' ? video['Date Posted'] : video.date); // assuming `video.date` is the date property
                const diffTime = now - videoDate;
                const diffDays = diffTime / (1000 * 60 * 60 * 24);
                return diffDays <= daysFilter;
            });
        }

        // Filter videosData and categorize into short and long videos per day
        filteredByDate?.forEach(video => {
            const videoDate = new Date(profileData?.platform === 'instagram' ? video['Date Posted'] : video.date);
            const dayOfWeek = videoDate.getDay();
            if (profileData?.platform === 'instagram' ? video?.Duration <= 30 : video?.duration <= 30) {
                shortVideosPerDay[dayOfWeek]++;
            } else {
                longVideosPerDay[dayOfWeek]++;
            }
        });

        // Calculate averages for short and long videos
        // const totalDays = videosData?.length ? 7 : 0;
        // const averageShortVideos = shortVideosPerDay.map(count => count / totalDays);
        // const averageLongVideos = longVideosPerDay.map(count => count / totalDays);


        setDailyDataVideos(videosPerDay);
        setDailyDataViews(viewsPerDay)
        setDailyShortVideos(shortVideosPerDay)
        setDailyLongVideos(longVideosPerDay)
        setDailyEngagement(engagementPerDay)
        // Now you have the total array of data for videos and views for each day of the week
        console.log("Total videos per day:", videosPerDay);
        console.log("Total views per day:", viewsPerDay);
        console.log("Total views per day:", viewsPerDay);
    }, [analyticsData, daysFilter, startDate, endDate, yearFilter]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (<div className="charts-main-wrapper">
         <div className="average-cards-wrapper">
            {/* <strong className="white">Averages</strong> */}
            <DashboardAverageCards analyticsData={allAnalyticsData || []} analyticsDataLoading={analyticsDataLoading} />
        </div>
        <ChartsVideoInfoTopCards analyticsData={allAnalyticsData || []} videoChartTab={videoChartTab} setVideoChartTab={setVideoChartTab} profileData={profileData} analyticsDataLoading={analyticsDataLoading} />

        {/* <DashboardTotalSmallCharts analyticsData={analyticsData} /> */}

        <div className="section-topbar">
            <ul className="video-tabs ">
                <li style={{ cursor: "pointer" }} >

                    <a className={`tab-link ${chartTabs == "performance" ? "active" : ""}`}
                        show={activeDropdown === 0}
                        onClick={() => chartTabsHandler("performance")}>
                        Performance Metrics
                        <img
                            src={infoQuestionMark}
                            alt="Your Image"
                            style={{ marginLeft: "5px" }}
                            onMouseEnter={() => handleMouseEnter(0)}
                            onMouseLeave={handleMouseLeave}
                        /></a>
                    <div className="tip">
                        <span className="small">
                            These charts focus on overall performance metrics and growth
                            tracking. Metrics such as Account Growth Chart provide
                            insights into the overall growth and effectiveness of the
                            account's content strategy over time, helping you track
                            progress and identify areas for improvement.
                        </span>
                    </div>

                    {/* <DropdownButton
                        as={ButtonGroup}
                        id={`dropdown-button-drop-end}`}
                        drop="end"
                        className={`${chartTabs == "performance" ? "active" : ""}`}
                        onClick={() => chartTabsHandler("performance")}
                        title={<>
                            Performance Metrics
                            <img
                                src={infoQuestionMark}
                                alt="Your Image"
                                style={{marginLeft: "5px"}}
                                onMouseEnter={() => handleMouseEnter(0)}
                                onMouseLeave={handleMouseLeave}
                            />
                        </>}
                        show={activeDropdown === 0}

                    >
                        <Dropdown.Item eventKey="1">
                            <div className="tip">
                                <span className="small">
                                    These charts focus on overall performance metrics and growth
                                    tracking. Metrics such as Account Growth Chart provide
                                    insights into the overall growth and effectiveness of the
                                    account's content strategy over time, helping you track
                                    progress and identify areas for improvement.
                                </span>
                            </div>
                        </Dropdown.Item>
                    </DropdownButton>*/}
                </li>
                <li style={{ cursor: "pointer" }} >

                    <a className={`tab-link ${chartTabs == "engagement" ? "active" : ""}`}
                        show={activeDropdown === 1}
                        onClick={() => chartTabsHandler("engagement")}>
                        Engagement Analysis
                        <img
                            src={infoQuestionMark}
                            alt="Your Image"
                            style={{ marginLeft: "5px" }}
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={handleMouseLeave}
                        /></a>
                    <div className="tip">
                        <span className="small">
                            These charts focus on overall performance metrics and growth
                            tracking. Metrics such as Account Growth Chart provide
                            insights into the overall growth and effectiveness of the
                            account's content strategy over time, helping you track
                            progress and identify areas for improvement.
                        </span>
                    </div>

                    {/* <DropdownButton
                        as={ButtonGroup}
                        id={`dropdown-button-drop-end}`}
                        drop="end"
                        variant="secondary"
                        className={`${chartTabs == "engagement" ? "active" : ""}`}
                        onClick={() => chartTabsHandler("engagement")}
                        title={<>
                            Engagement Analysis
                            <img
                                src={infoQuestionMark}
                                alt="Your Image"
                                style={{marginLeft: "5px"}}
                                onMouseEnter={() => handleMouseEnter(1)}
                                onMouseLeave={handleMouseLeave}
                            />
                        </>}
                        show={activeDropdown === 1}

                    >
                        <Dropdown.Item eventKey="1">
                            <div className="tip">
                                <span className="small">
                                    These charts focus on overall performance metrics and growth
                                    tracking. Metrics such as Account Growth Chart provide
                                    insights into the overall growth and effectiveness of the
                                    account's content strategy over time, helping you track
                                    progress and identify areas for improvement.
                                </span>
                            </div>
                        </Dropdown.Item>
                    </DropdownButton>*/}
                </li>
                <li style={{ cursor: "pointer" }} >

                    <a className={`tab-link ${chartTabs == "characteristics" ? "active" : ""}`}
                        show={activeDropdown === 2}
                        onClick={() => chartTabsHandler("characteristics")}>
                        Content Characteristics
                        <img
                            src={infoQuestionMark}
                            alt="Your Image"
                            style={{ marginLeft: "5px" }}
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={handleMouseLeave}
                        /></a>
                    <div className="tip">
                        <span className="small">
                            These charts focus on overall performance metrics and growth
                            tracking. Metrics such as Account Growth Chart provide
                            insights into the overall growth and effectiveness of the
                            account's content strategy over time, helping you track
                            progress and identify areas for improvement.
                        </span>
                    </div>

                    {/*  <DropdownButton
                        as={ButtonGroup}
                        id={`dropdown-button-drop-end}`}
                        drop="end"
                        variant="secondary"
                        className={`${chartTabs == "characteristics" ? "active" : ""}`}
                        onClick={() => chartTabsHandler("characteristics")}
                        title={<>
                            Content Characteristics
                            <img
                                src={infoQuestionMark}
                                alt="Your Image"
                                style={{marginLeft: "5px"}}
                                show={activeDropdown === 2}
                                onMouseEnter={() => handleMouseEnter(2)}
                            />
                        </>}

                        onMouseLeave={handleMouseLeave}
                    >
                        <Dropdown.Item eventKey="1">
                            <div className="tip">
                                <span className="small">
                                    These charts focus on overall performance metrics and growth
                                    tracking. Metrics such as Account Growth Chart provide
                                    insights into the overall growth and effectiveness of the
                                    account's content strategy over time, helping you track
                                    progress and identify areas for improvement.
                                </span>
                            </div>
                        </Dropdown.Item>
                    </DropdownButton>*/}
                </li>
                <li style={{ cursor: "pointer" }} >

                    <a className={`tab-link ${chartTabs == "behavior" ? "active" : ""}`}
                        show={activeDropdown === 3}
                        onClick={() => chartTabsHandler("behavior")}>
                        Posting Behavior
                        <img
                            src={infoQuestionMark}
                            alt="Your Image"
                            style={{ marginLeft: "5px" }}
                            onMouseEnter={() => handleMouseEnter(3)}
                            onMouseLeave={handleMouseLeave}
                        /></a>
                    <div className="tip">
                        <span className="small">
                            These charts focus on overall performance metrics and growth
                            tracking. Metrics such as Account Growth Chart provide
                            insights into the overall growth and effectiveness of the
                            account's content strategy over time, helping you track
                            progress and identify areas for improvement.
                        </span>
                    </div>

                    {/* <DropdownButton
                        as={ButtonGroup}
                        id={`dropdown-button-drop-end}`}
                        drop="end"
                        variant="secondary"
                        className={`${chartTabs == "behavior" ? "active" : ""}`}
                        onClick={() => chartTabsHandler("behavior")}
                        title={<>
                            Posting Behavior
                            <img
                                src={infoQuestionMark}
                                alt="Your Image"
                                style={{marginLeft: "5px"}}
                                onMouseEnter={() => handleMouseEnter(3)}
                                onMouseLeave={handleMouseLeave}
                            />
                        </>}
                        show={activeDropdown === 3}

                    >
                        <Dropdown.Item eventKey="1">
                            <div className="tip">
                                <span className="small">
                                    These charts focus on overall performance metrics and growth
                                    tracking. Metrics such as Account Growth Chart provide
                                    insights into the overall growth and effectiveness of the
                                    account's content strategy over time, helping you track
                                    progress and identify areas for improvement.
                                </span>
                            </div>
                        </Dropdown.Item>
                    </DropdownButton>*/}
                </li>
            </ul>
        </div>
        {chartTabs == "performance" && (
            <div className={'performance-tab-content-main'}>
                <AccountGrowthCharts
            dateRange={dateRange}
            setDateRange={setDateRange}
            handleDateRange={handleDateRange}
            smallChartTabs={smallChartTabs}
            setSmallChartTabs={setSmallChartTabs}
            smallChartTabsHandler={smallChartTabsHandler}
            profileData={profileData}
            chartData={chartData}
            setChartData={setChartData}
            chartTabs={chartTabs}
            setChartTabs={setChartTabs}
            analyticsData={analyticsData}
            calcLast30DaysVideos={calcLast30DaysVideos}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            daysFilter={daysFilter}
            setDaysFilter={setDaysFilter}
            chartLabels={chartLabels}
            pointVideos={pointVideos || []}
            yearFilter={yearFilter}
            setYearFilter={setYearFilter}
            ref={wrapperRef}
            analyticsDataLoading={analyticsDataLoading}
            analyticsDataLoading30d={analyticsDataLoading30d}
        />
                </div>

        )}
        {chartTabs == "engagement" && (<>
        <div className={'engagement-tab-content-main'}>
            <AverageEngagementPerPostChart
                dateRange={dateRange}
                setDateRange={setDateRange}
                handleDateRange={handleDateRange}
                profileData={profileData}
                infoQuestionMark={infoQuestionMark}
                smallChartTabsHandler={smallChartTabsHandler}
                smallChartTabs={smallChartTabs}
                setSmallChartTabs={setSmallChartTabs}
                chartData={chartData}
                analyticsData={analyticsData}
                calcLast30DaysVideos={calcLast30DaysVideos}
                chartTabs={chartTabs}
                setChartTabs={setChartTabs}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                daysFilter={daysFilter}
                setDaysFilter={setDaysFilter}
                chartLabels={chartLabels}
                yearFilter={yearFilter}
                setYearFilter={setYearFilter}
                analyticsDataLoading={analyticsDataLoading}
                analyticsDataLoading30d={analyticsDataLoading30d}
            />
            <DistributionOfLikes
                dateRange1={dateRange1}
                setDateRange1={setDateRange1}
                handleDateRange1={handleDateRange1}
                analyticsData={analyticsData}
                profileData={profileData}
                calcLast30DaysVideos={calcLast30DaysVideos}
                startDate={startDate2}
                setStartDate={setStartDate2}
                endDate={endDate2}
                setEndDate={setEndDate2}
                analyticsDataLoading={analyticsDataLoading}
                analyticsDataLoading30d={analyticsDataLoading30d}
            />
        </div>
        </>)}
        {chartTabs == "characteristics" && (
            <div className={'characteristics-tab-content-main'}>
                <ContentCharacteristicsCharts
            analyticsData={analyticsData}
            handleDateRange={handleDateRange}
            handleDateRange1={handleDateRange1}
            dateRange={dateRange}
            setDateRange={setDateRange}
            profileData={profileData}
            shortVideosPercentage={shortVideosPercentage}
            longVideosPercentage={longVideosPercentage}
            videoChartTab={videoChartTab}
            setVideoChartTab={setVideoChartTab}
            dateRange1={dateRange1}
            setDateRange1={setDateRange1}
            loadingVideos={loadingVideos}
            analyticsDataLoading={analyticsDataLoading}
        />
            </div>)}
        {chartTabs == "behavior" && (<>
        <div className={'behavior-tab-content-main'}>
            <TimePostingPatternsMain
                dateRange={dateRange}
                setDateRange={setDateRange}
                handleDateRange={handleDateRange}
                smallChartTabsHandler={smallChartTabsHandler}
                chartData={chartData}
                smallChartTabs={smallChartTabs}
                setSmallChartTabs={setSmallChartTabs}
                analyticsData={analyticsData}
                chartTabs={chartTabs}
                setChartTabs={setChartTabs}
                profileData={profileData}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                daysFilter={daysFilter}
                setDaysFilter={setDaysFilter}
                chartLabels={chartLabels}
                yearFilter={yearFilter}
                setYearFilter={setYearFilter}
                analyticsDataLoading={analyticsDataLoading}
                analyticsDataLoading30d={analyticsDataLoading30d}
            />
            {/* <VideosPostedByDayOfTheWeekMain
                dateRange1={dateRange1}
                handleDateRange1={handleDateRange1}
                analyticsData={analyticsData}
            /> */}
            <VideosPostedByDayOfTheWeekMainNew
                dateRange1={dateRange1}
                handleDateRange1={handleDateRange1}
                analyticsData={allAnalyticsData}
                profileData={profileData}
                analyticsDataLoading={analyticsDataLoading}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                daysFilter={daysFilter}
                setDaysFilter={setDaysFilter}
                dateRange={dateRange}
                setDateRange={setDateRange}
                handleDateRange={handleDateRange}

            />

            <div className="posting-behaviour-section chart-section p-0 mt-5 ">
                <div className="section-heading p-0 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="align-self-center col-lg-7 col-xl-7 col-6">
                                <div className="title mb-0">
                                    <strong className="white">Posting Behavior</strong>
                                </div>
                            </div>
                            <div className="align-self-center col-lg-5 col-md-5 col-6">
                                <div className="date-wrapper">
                                    <ul className="d-list">
                                        <li onClick={() => setDaysFilter((pre) => pre == 7 ? null : 7)} className={`${daysFilter == 7 ? 'white' : ''}`}><span>7D</span></li>
                                        <li onClick={() => setDaysFilter((pre) => pre == 30 ? null : 30)} className={`${daysFilter == 30 ? 'white' : ''}`}><span>30D</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chart-content-wrapper transparent" style={{ padding: '13px 25px' }}>
                    <div className="row">
                        <div className="graphs-main-wrapper">
                            <div className="chart-wrapper">
                                <span className="white small">Videos Posted Average Per Day</span>
                                <BarChart durationData={dailyDataVideos} color="#EB55B4" />
                                {/*<HorizontalBarChart2 />*/}
                                {/* <HorizontalBarChartWeekly durationData={dailyDataVideos} color="#EB55B4"/> */}
                                <div className="footer">
                                    <span
                                        className="small">Videos posted average</span>
                                    <span className="small white">{ShortenNumber(Math.floor(dailyDataVideos.reduce((a, b) => a + b, 0) / dailyDataVideos.length) || 0)}</span>
                                </div>
                            </div>
                            <div className="chart-wrapper">
                                <span className="white small">Average views per day</span>
                                <BarChart durationData={dailyDataViews} color="#858DE2" />
                                {/*<HorizontalBarChart2 />*/}
                                <div className="footer">
                                    <span
                                        className="small">Average views per day</span>
                                    <span className="small white">{ShortenNumber(Math.floor(dailyDataViews.reduce((a, b) => a + b, 0) / dailyDataViews.length) || 0)}</span>
                                </div>
                            </div>
                            <div className="chart-wrapper">
                                <span className="white small">Average engagement per day</span>
                                <BarChart durationData={dailyEngagement} color="#FF764C" />
                                {/*<HorizontalBarChart2 />*/}
                                <div className="footer">
                                    <span
                                        className="small">Average engagement per day</span>
                                    <span className="small white">{ShortenNumber(Math.floor(dailyEngagement.reduce((a, b) => a + b, 0) / dailyEngagement.length) || 0)}</span>
                                </div>
                            </div>
                            <div className="chart-wrapper">
                                <span className="white small">Average short videos per day</span>
                                <BarChart durationData={dailyShortVideos} color="rgba(234, 185, 255, 1)" />
                                {/*<HorizontalBarChart2 />*/}
                                <div className="footer">
                                    <span
                                        className="small">Average short videos per day</span>
                                    <span className="small white">{ShortenNumber(Math.floor(dailyShortVideos.reduce((a, b) => a + b, 0) / dailyShortVideos.length) || 0)}</span>
                                </div>
                            </div>
                            <div className="chart-wrapper">
                                <span className="white small">Average long videos per day</span>
                                <BarChart durationData={dailyLongVideos} color="rgba(234, 185, 255, 1)" />
                                {/*<HorizontalBarChart2 />*/}
                                <div className="footer">
                                    <span
                                        className="small">Average long videos per day</span>
                                    <span className="small white">{ShortenNumber(Math.floor(dailyLongVideos.reduce((a, b) => a + b, 0) / dailyLongVideos.length) || 0)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chart-section">
                <div className="section-heading">
                    {/*<h3>Videos Posted on Days On Average & Post Frequency</h3>*/}
                </div>
                <VideosPostedOnDaysOnAverage analyticsData={allAnalyticsData} analyticsDataLoading={analyticsDataLoading} startDate={startDate} endDate={endDate} daysFilter={daysFilter} yearFilter={yearFilter}/>
                <PostFrequency
                    profileData={profileData}
                    postFrequencyPerDay={postFrequencyPerDay}
                    postFrequencyPerWeek={postFrequencyPerWeek}
                    infoQuestionMark={infoQuestionMark}
                    postFrequencyPerMonth={postFrequencyPerMonth}
                    postFrequencyPerYear={postFrequencyPerYear}
                    analyticsDataLoading={analyticsDataLoading}
                />
            </div>
        </div>
        </>)}
    </div>);
}