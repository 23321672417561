import React, { useEffect, useRef, useState } from "react";
import { FaFilter } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import { IoCalendarClear } from "react-icons/io5";
import VideosSection from "./VideosSection";
import Pagination from "./Pagination/Pagination";
import { scrapServerBasepath } from "../services/constents";
import io from "socket.io-client";
import Dropdown from 'react-bootstrap/Dropdown';

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import GetLastDownloadedVideoId from "../utilities/GetLastDownloadedVideoId";
import { BsFillGridFill } from "react-icons/bs";
import listViewIcon from "../assets/images/icons/download-list-view-icon.png";
import tableHeadingIcon from "../assets/images/icons/table-heading-icon.png";
import avatar from "../assets/images/icons/avatar-icon.png";
import tikTockIcon from "../assets/images/icons/tick-tock-icon.png";
import CalendarComponent from "./CalendarComponent";
import VideosListView from "./videosListView";
import Calendar from "react-calendar";
import NewPagination from "./Pagination/NewPagination";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingReels, setReelsData, setReelsFinished } from "../store/reelsSlice";
import { FaDownload } from "react-icons/fa";
import { Spinner } from "react-bootstrap";

export default function VideosList({
    profileInfo,
    platform,
    username,
    selectedVideos,
    setSelectedVideos,
    userData,
    getDataFromJsonFile,
    analyticsData,
    loadingVideos,
    setLoadingVideos,
    data,
    setData,
    handleDownloadZip,
    firstLoad
}) {


    // const [data, setData] = useState([]);
    const [dataClone, setDataClone] = useState([]);
    // const [loadingVideos, setLoadingVideos] = useState(false);
    const [pageData, setPageData] = useState([]);

    const [columns, setColumns] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [paginationModal, setPaginationModal] = useState({ page: 1, pageSize: 50 })
    const [lastDownloadedVideoId, setLastDownloadedVideoId] = useState("");
    const [selectingStartDate, setSelectingStartDate] = useState(true);
    const [dateRange, setDateRange] = useState(false);

    const dispatch = useDispatch()
    const modalRef = useRef(null);


    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.box-icon')) {
            setDateRange(false);
        }
    };

    useEffect(() => {
        if (dateRange) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dateRange]);

    // useEffect(() => {
    //     setLoadingVideos(true);
    //     setData([]);
    //     setSelectedVideos(new Set());
    // }, [profileInfo?.id, username]);

    // var eventSource;
    // var socket;


    // useEffect(() => {
    //     if (!profileInfo?.id) {
    //         setLoadingVideos(false);
    //         return;
    //     }

    //     setLoadingVideos(true);
    //     eventSource = new EventSource(
    //         `${scrapServerBasepath}/api/${platform}/videos/${profileInfo?.id}?getDataFromJsonFile=${getDataFromJsonFile}&username=${username}&userId=${profileInfo?.id}`
    //     );
    //     console.log("event:: ", eventSource);
    //     socket = io(scrapServerBasepath); // Create a socket connection

    //     console.log("event source res:: ", eventSource);

    //     eventSource.onmessage = (event) => {
    //         const newVideo = JSON.parse(event.data);
    //         setData((prevData) => [...prevData, newVideo]);
    //         setLoadingVideos(false);
    //         dispatch(setReelsData([newVideo]));
    //         // Emit the data to the socket server
    //         socket.emit("newVideo", newVideo);
    //     };

    //     eventSource.addEventListener("no-more-videos", () => {
    //         console.log("no more videos");
    //         setLoadingVideos(false);
    //         dispatch(setReelsFinished(true));
    //         dispatch(setLoadingReels(false));
    //         eventSource.close();
    //     });

    //     setTimeout(() => {
    //         setLoadingVideos(false);
    //     }, 180000);

    //     // Clean up on component unmount
    //     return () => {
    //         eventSource.close();
    //         socket.disconnect(); // Disconnect the socket
    //     };
    // }, [profileInfo?.id, username]);



    // useEffect(() => {
    //     if (profileInfo?.videoCount === 0) {
    //         setLoadingVideos(false);
    //     }
    // }, [profileInfo]);

    useEffect(() => {
        GetLastDownloadedVideoId(platform, profileInfo?.id, userData?.appuser_id)
            //thne set setLastDownloadedVideoId
            .then((lastDownloadedVideo) => {
                setLastDownloadedVideoId(lastDownloadedVideo);
            });
    }, [profileInfo?.id]);

    const [sortConfig, setSortConfig] = useState(null);

    const requestSort = (key) => {
        console.log("keyy is reqsoert: ", key);
        //console.log('request sort ' + key)
        let direction = "descending";
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "descending"
        ) {
            direction = "ascending";
        }
        setSortConfig({ key, direction });
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const filters = [
        {
            id: 1,
            name: "Only non downloaded videos",
        },
    ];

    const sortFilters = [
        {
            id: 1,
            name: "Duration",
            value: profileInfo?.platform == "instagram" ? "Duration" : "duration",
        },
        {
            id: 2,
            name: "Views",
            value: profileInfo?.platform == "instagram" ? "Views" : "views",
        },
        {
            id: 3,
            name: "Comments",
            value: profileInfo?.platform == "instagram" ? "Comments" : "comments",
        },
        {
            id: 4,
            name: "Shares",
            value: profileInfo?.platform == "instagram" ? "Shares" : "shares",
        },
        {
            id: 5,
            name: "Likes",
            value: profileInfo?.platform == "instagram" ? "Likes" : "likes",
        },
        {
            id: 6,
            name: "Bookmarks",
            value: profileInfo?.platform == "instagram" ? "Bookmarks" : "bookmarks",
        },
        {
            id: 6,
            name: "Date Posted",
            value: profileInfo?.platform == "instagram" ? "Date Posted" : "date",
        }
    ];

    const [selectedFilters, setSelectedFilters] = useState(new Set());
    const [selectedSortFilters, setSelectedSortFilters] = useState(new Set());

    const [filteredCount, setFilteredCount] = useState(0); // Add a new state to store the count of filtered results
    const [filteredData, setFilteredData] = useState([]); // Add a new state to store the count of filtered results

    const [nonDownloadedCount, setNonDownloadedCount] = useState(0);
    const [videosType, setVideoType] = useState('profile_videos');

    var videosData = useSelector((state) => state?.reels?.reelsData);


    useEffect(() => {
        let updatedData = dataClone ? [...dataClone] : [...data];

        //sort logic
        if (sortConfig !== null) {
            updatedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
            // console.log({ sortedData: updatedData });
        } else {
            // console.log('sort config is null');
        }

        if (searchTerm) {
            // filtering logic
            updatedData = updatedData.filter((item) =>
                item.title?.toLowerCase().includes(searchTerm?.toLowerCase())
            );
        }

        // Assuming startDate and endDate are in the format you mentioned
        if (startDate && endDate && videosType == 'profile_videos') {
            // date range
            if (profileInfo?.platform === "instagram") {
                updatedData = updatedData.filter((item) => {
                    const itemDate = new Date(item["Date Posted"]);
                    console.log('item date', itemDate)
                    return (
                        itemDate >= new Date(startDate) &&
                        itemDate <= new Date(endDate)
                    );
                });
            } else {
                updatedData = updatedData.filter((item) => {
                    const itemDate = new Date(item.date);
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    return (
                        item?.title?.toLowerCase().includes(searchTerm.toLowerCase()) &&
                        itemDate >= start &&
                        itemDate <= end
                    );
                });
            }
        }


        //count how many non downloaded videos there is :
        const lastDownloadedVideoIndex = updatedData.findIndex(
            (item) => item.id === lastDownloadedVideoId
        );
        if (lastDownloadedVideoIndex !== -1) {
            setNonDownloadedCount(
                updatedData.slice(0, lastDownloadedVideoIndex)?.length
            );
        }
        //if filter with id 1 (only non downloaded videos) is selected, then remove all videos after the last downloaded video id

        if (selectedFilters.has(1)) {
            console.log("filter 1 is selected ");
            const lastDownloadedVideoIndex = updatedData.findIndex(
                (item) => item.id === lastDownloadedVideoId
            );
            if (lastDownloadedVideoIndex !== -1) {
                console.log(
                    "last downloaded video " +
                    lastDownloadedVideoId +
                    " index is " +
                    lastDownloadedVideoIndex
                );
                updatedData = updatedData.slice(0, lastDownloadedVideoIndex);
                setNonDownloadedCount(
                    updatedData.slice(0, lastDownloadedVideoIndex)?.length
                );
            } else {
                console.log(
                    "last downloaded video " +
                    lastDownloadedVideoId +
                    " index is NOT FOUND"
                );
            }
        }
        {
            console.log("filter 1 is not selected ");
        }

        setFilteredCount(updatedData.length); // Update the count of filtered results
        setFilteredData(updatedData)
        // slicing logic for pagination
        const start = (paginationModal?.page - 1) * paginationModal?.pageSize;
        const end = start + paginationModal?.pageSize;
        updatedData = updatedData.slice(start, end);
        if (dataClone) {
            setDataClone(updatedData)
        } else {
            setPageData(updatedData)
        }
    }, [
        data,
        searchTerm,
        sortConfig,
        selectedFilters,
        paginationModal,
        startDate,
        endDate,
        dataClone
    ]);

    useEffect(() => {
        // setDataClone(data)
        if (videosType == 'top5_videos') {
            // setDataClone(analyticsData?.top_videos?.top_comments || []);
            let topfiveVideos
            if (profileInfo?.platform == 'instagram') {
                topfiveVideos = [...videosData]
                    ?.sort((a, b) => b.Views - a.Views)
                    ?.slice(0, 5);
            } else {
                topfiveVideos = [...videosData]
                    ?.sort((a, b) => b.views - a.views)
                    ?.slice(0, 5);
            }

            setDataClone(topfiveVideos)

        }
        if (videosType == 'best_videos') {
            // setDataClone(analyticsData?.top_videos?.top_views || []);
            let outliers
            if (profileInfo?.platform == 'instagram') {
                outliers = [...videosData]
                    ?.sort((a, b) => b.Comments - a.Comments)
                    ?.slice(0, 5);

            } else {
                outliers = [...videosData]
                    ?.sort((a, b) => b.comments - a.comments)
                    ?.slice(0, 5);

            }
            setDataClone(outliers)

        } if (videosType == 'profile_videos') {
            setDataClone(null);
        }
    }, [videosType, endDate])

    const [activeDownloadView, setActiveDownloadView] = useState('gridView');
    const handleDownloadView = (tab) => {
        setActiveDownloadView(tab);
    };
    const handleDateRange = () => {
        setDateRange(!dateRange);
    };


    const handleSelectByDays = (days) => {
        if (days) {
            if (!isNaN(days)) {
                const today = new Date();
                const newStartDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000); // Calculate startDate by subtracting days
                setStartDate(newStartDate);
                setEndDate(today); // endDate is today
            }
        } else {
            setStartDate(null);
            setEndDate(null)
        }
    }

    useEffect(() => {
        handleSelectByDays(document.getElementById('lastDaysInput')?.value || parseInt(document.getElementById('upTodayInput')?.value))
    }, [parseInt(document.getElementById('lastDaysInput')?.value), parseInt(document.getElementById('upTodayInput')?.value)]);


    const handleDateRangeChange = (value) => {
        const currentDate = new Date();
        let newStartDate = new Date();
        let newEndDate = new Date();

        switch (value) {
            case 'today':
                newStartDate = currentDate;
                newEndDate = currentDate;
                break;
            case 'yesterday':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - 1);
                newEndDate = newStartDate;
                break;
            case 'week':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - currentDate.getDay());
                newEndDate = currentDate;
                break;
            case 'last-days':
                const lastDays = parseInt(document.getElementById('lastDaysInput')?.value);
                handleSelectByDays(lastDays)
                break;

            case 'month':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                break;
            case 'lastMonth':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
                break;
            case 'thisYear':
                newStartDate = new Date(currentDate.getFullYear(), 0, 1);
                newEndDate = new Date(currentDate.getFullYear(), 11, 31);
                break;
            case 'upToday':
                const upTodayDays = parseInt(document.getElementById('upTodayInput').value);
                handleSelectByDays(upTodayDays)
                break;
            case 'reset':
                newStartDate = null;
                newEndDate = null
            // Add more cases as needed
            default:
                break;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleDateSelect = (selectedDate) => {
        if (selectingStartDate) {
            setStartDate(selectedDate);
        } else {
            setEndDate(selectedDate);
        }
        setSelectingStartDate(!selectingStartDate); // Toggle selectingStartDate flag
    };

    console.log('start DAte', startDate)
    console.log('end dat', endDate)

    const getLastMonthName = () => {
        return dayjs().subtract(1, 'month').format('MMMM');
    };

    const handleSort = (value) => {
        if (value == 'reset') {
            selectedSortFilters.clear()
            setSelectedSortFilters(
                new Set(selectedSortFilters)
            );
            setSortConfig(null)
            return
        }
        selectedSortFilters.clear();
        selectedSortFilters.add(value);

        setSelectedSortFilters(
            new Set(selectedSortFilters)
        );

        requestSort(value)
    };

    return (

        <div className="videos-main-wrapper">
            <div className="section-topbar">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-xl-5 col-lg-5 col-md-5 align-self-center">
                            <ul className="video-tabs">
                                <li><a className={`${videosType == 'profile_videos' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => { setVideoType('profile_videos') }}>Profile Videos</a></li>
                                <li><a className={`${videosType == 'best_videos' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => setVideoType('best_videos')}>Outliers</a></li>
                                <li><a className={`${videosType == 'top5_videos' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => { setVideoType('top5_videos') }}>Top Videos</a></li>
                            </ul>
                        </div>
                        <div className=" col-xl-7 col-lg-7 col-md-7 align-self-center">
                            <div className="right-filter-wrapper">
                                {selectedVideos?.size > 0 && <a onClick={() => handleDownloadZip()} className="btn-style simple download-videos-btn d-none d-xl-inline-flex"> <FaDownload /> Download selected </a>}
                                {selectedVideos?.size > 0 && <a onClick={() => handleDownloadZip()} className="btn-style simple download-videos-btn d-inline-flex d-xl-none"> <FaDownload /></a>}
                                <div className="select-wrapper position-relative">
                                    <FaFilter style={{ position: 'absolute', left: '14px' }} />
                                    {/* <Form.Select aria-label="Default select example" onChange={(e) => {
                                        selectedSortFilters.clear();
                                        selectedSortFilters.add(e.target.value);

                                        setSelectedSortFilters(
                                            new Set(selectedSortFilters)
                                        );

                                        requestSort(e.target.value)
                                    }}>
                                        <option>Sort by videos by</option>
                                        <option value="car">
                                            <div className="div">car</div>
                                        </option>
                                         <option value="1">Duration</option>
                                            <option value="2">Views</option>
                                            <option value="3">Comments</option>
                                            <option value="4">Shares</option>
                                            <option value="5">Likes </option>
                                            <option value="6">Bookmarks</option>
                                            <option value="7">Date posted</option>
                                        {sortFilters?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.value}>{item.name}</option>
                                            )
                                        })}
                                    </Form.Select> */}
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" style={{ padding: '9px 20px 8px 40px' }} id="dropdown-basic">{selectedSortFilters.size > 0 ? selectedSortFilters.values().next().value : 'Sort videos by'}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleSort(profileInfo?.platform == 'instagram' ? 'Duration' : 'duration')}>Duration</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort(profileInfo?.platform == 'instagram' ? 'Views' : 'views')}>Views</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort(profileInfo?.platform == 'instagram' ? 'Comments' : 'comments')}>Comments</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort(profileInfo?.platform == 'instagram' ? 'Shares' : 'shares')}>Shares</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort(profileInfo?.platform == 'instagram' ? 'Likes' : 'likes')}>Likes</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort(profileInfo?.platform == 'instagram' ? 'Bookmarks' : 'bookmarks')}>Bookmarks</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort(profileInfo?.platform == 'instagram' ? 'Date Posted' : 'date')}>Date posted</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSort('reset')}>Reset</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                                <div className="date-wrapper" onClick={handleDateRange}>
                                    <div className={`box-icon small ${dateRange ? 'active' : ''}`} >
                                        <IoCalendarClear />
                                    </div>
                                    <p className="white">Set date range</p>
                                </div>
                                <ul className="icon-list">
                                    <li>
                                        <a className={`${activeDownloadView == 'gridView' ? 'active' : ''}`}
                                            onClick={() => handleDownloadView('gridView')}>
                                            <div className="box-icon small">
                                                <BsFillGridFill />
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${activeDownloadView == 'listView' ? 'active' : ''}`}
                                            onClick={() => handleDownloadView('listView')}>
                                            <div className="box-icon small">
                                                <img src={listViewIcon} alt="listViewIcon" />
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div ref={modalRef} className={`select-time-main-wrapper ${dateRange ? 'active' : ''}`}>
                        <form action="#">
                            <ul className="time" onChange={(e) => handleDateRangeChange(e.target.value)}>

                                <li>
                                    <input type="radio" id="last-days" name="time" value="last-days" />
                                    <label htmlFor="last-days">Last <input type="text" id="lastDaysInput"/> Days</label>
                                </li>
                                <li>
                                    <input type="radio" id="week" name="time" value="week" />
                                    <label htmlFor="week">This Week</label>
                                </li>
                                <li>
                                    <input type="radio" id="month" name="time" value="month" />
                                    <label htmlFor="month">This Month {`(${new Date().toLocaleString('en-US', { month: 'long', timeZone: 'UTC' })})`}
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="lastMonth" name="time" value="lastMonth" />
                                    <label htmlFor="lastMonth">Last Month {`(${getLastMonthName()})`}</label>

                                </li>
                                <li>
                                    <input type="radio" id="alltime" name="time" value="reset" />
                                    <label htmlFor="alltime">All time</label>
                                </li>
                                <li>
                                    <input type="radio" id="thisYear" name="time" value="thisYear" />
                                    <label htmlFor="thisYear">This Year {`(${new Date().getFullYear()})`}</label>
                                </li>
                                {/* <li>
                                    <input type="radio" id="upToday" name="time" value="upToday" />
                                    <label htmlFor="upToday"><input type="text" id="upTodayInput" /> Days up to today</label>
                                </li> */}
                                {/* <li>
                                    <input type="radio" id="reset" name="time" value="reset" />
                                    <label htmlFor="reset"><input type="text" id="resetnput" /> Reset</label>
                                </li> */}
                            </ul>
                        </form>
                        <div className="calendar-wrapper">
                            <div className="select-time">
                                <div className="select"><span className='white'>{startDate ? startDate.toLocaleDateString() : ' '}</span></div>
                                <span className='white mx-3'>-</span>
                                <div className="select"><span className='white'>{endDate ? endDate.toLocaleDateString() : ' '}</span></div>
                            </div>
                            <div className="calandar">
                                {/* <CalendarComponent /> */}
                                <Calendar onChange={handleDateSelect} value={selectingStartDate ? startDate : endDate} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {firstLoad ? <span
                className='white d-inline-flex gap-2'>  <Spinner
                    animation="grow" size="sm" /><span
                        className=' d-inline-block white'>Loading...</span></span> : activeDownloadView == 'gridView' ? <VideosSection data={dataClone ? dataClone : pageData || []} platform={platform} selectedVideos={selectedVideos}
                            setSelectedVideos={setSelectedVideos} /> : <VideosListView data={dataClone ? dataClone : pageData || []} platform={platform} selectedVideos={selectedVideos}
                                setSelectedVideos={setSelectedVideos} />

            }

            {/* <Pagination paginationModel={paginationModal} setPaginationModel={setPaginationModal}
                data={{ data, totalPages: Math.ceil(data?.length / paginationModal?.pageSize) }} /> */}


            <NewPagination
                paginationModel={paginationModal} setPaginationModel={setPaginationModal}
                data={{ data: dataClone ? dataClone : (startDate && endDate) ? filteredData : data, totalPages: Math.ceil((dataClone ? dataClone?.length : (startDate && endDate) ? filteredData?.length : data?.length) / paginationModal?.pageSize) }}
            />
        </div>


    )
}