import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { IoBookmark, IoCalendarClear, IoGrid } from "react-icons/io5";
import LargeGraphChart from "../../LargeGraphChart";

import cyanChartSmallImg from "../../../assets/images/cyan-chart-small-img.png";
import greenChartSmallImg from "../../../assets/images/green-chart-small-img.png";
import yellowChartSmallImg from "../../../assets/images/yelow-chart-small-img.png";
import purpleChartSmallImg from "../../../assets/images/purple-chart-small-img.png";
import pinkChartSmallImg from "../../../assets/images/pink-chart-small-img.png";
import orangeChartSmallImg from "../../../assets/images/orange-chart-small-img.png";
import infoQuestionMark from "../../../assets/images/icons/info-question-mark-icon.png";
import { PiShareFatFill, PiVideoCameraFill } from "react-icons/pi";
import { BsFillEyeFill, BsHeartFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import smsPurpleIcon from "../../../assets/images/icons/Icon.png";
import { FcLike } from "react-icons/fc";
import LargeGraphChart1 from "../../LargeGraphChart1";
import PurpleLargeChart from "../../all-charts/PurpleLargeChart";
import DoubleLineChart from "../../all-charts/DoubleLineChart";
import GreenLargeChart from "../../all-charts/GreenLargeChart";
import { Spinner } from "react-bootstrap";

const AverageEngagementPerPostChart = ({
  dateRange,
  setDateRange,
  handleDateRange,
  profileData,
  infoQuestionMark,
  smallChartTabsHandler,
  smallChartTabs,
  setSmallChartTabs,
  chartData,
  analyticsData,
  calcLast30DaysVideos,
  chartTabs,
  setChartTabs,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  daysFilter,
  setDaysFilter,
  chartLabels,
  yearFilter,
  setYearFilter,
  analyticsDataLoading, analyticsDataLoading30d
}) => {
  const [selectingStartDate, setSelectingStartDate] = useState(true);
  const modalRef = useRef(null);


  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.box-icon')) {
      setDateRange(false);
    }
  };

  useEffect(() => {
    if (dateRange) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dateRange]);

  const handleSelectByDays = (days) => {
    if (days) {
      if (!isNaN(days)) {
        const today = new Date();
        const newStartDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000); // Calculate startDate by subtracting days
        setStartDate(newStartDate);
        setEndDate(today); // endDate is today
      }
    } else {
      setStartDate(null);
      setEndDate(null)
    }
  }

  useEffect(() => {
    handleSelectByDays(document.getElementById('lastDaysInput')?.value || parseInt(document.getElementById('upTodayInput')?.value))
  }, [parseInt(document.getElementById('lastDaysInput')?.value), parseInt(document.getElementById('upTodayInput')?.value)]);


  const handlePredefinedRange = (range) => {
    // alert(range)
    const currentDate = new Date();
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (range) {
      case "today":
        newStartDate = currentDate;
        newEndDate = currentDate;
        break;
      case "yesterday":
        newStartDate = new Date(currentDate);
        newStartDate.setDate(currentDate.getDate() - 1);
        newEndDate = newStartDate;
        break;
      case "week":
        newStartDate = new Date(currentDate);
        newStartDate.setDate(currentDate.getDate() - currentDate.getDay());
        newEndDate = currentDate;
        break;
      case "last-days":
        const lastDays = parseInt(document.getElementById('lastDaysInput')?.value);
        handleSelectByDays(lastDays)
        break;


      case "month":
        newStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        newEndDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        break;
      case "lastMonth":
        newStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        );
        newEndDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        );
        break;
      case "thisYear":
        // newStartDate = new Date(currentDate.getFullYear(), 0, 1);
        // newEndDate = new Date(currentDate.getFullYear(), 11, 31);
        newStartDate = null;
        newEndDate = null;
        setYearFilter(true)
        break;
      case "upToday":
        const upTodayDays = parseInt(
          document.getElementById("upTodayInput").value
        );
        handleSelectByDays(upTodayDays);
        break;
      case "reset":
        newStartDate = null;
        newEndDate = null;
        setYearFilter(false)
      // Add more cases as needed
      default:
        break;
    }
    // setDateRange([{ startDate, endDate, key: 'selection' }]);
    // setSelectedRange(range);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleDateSelect = (selectedDate) => {
    if (selectingStartDate) {
      setStartDate(selectedDate);
    } else {
      setEndDate(selectedDate);
    }
    setSelectingStartDate(!selectingStartDate); // Toggle selectingStartDate flag
  };

  return (
    <div className="chart-section">
      {/* <div className="section-title">
        <h3 className="white">Engagement Analysis Charts</h3>
      </div> */}
      <div className="section-heading pb-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-xl-7">
              <div className="title">
                <strong className="white">Average {smallChartTabs?.split('-')[0]} per post</strong>
                {/* <div className="text">
                  <div className="dot" style={{ background: "#FF6400" }}></div>
                  <span className="small">Average</span>
                  <img src={infoQuestionMark} alt="infoQuestionMark" />
                </div> */}
              </div>
              <p>
                This graph shows the number of videos @{profileData?.username}{" "}
                <span> </span>
                have posted over time. It helps you track thier content
                production and see if there are any trends in their posting
                frequency.
              </p>
            </div>
            <div className="col-lg-6 col-xl-5">
              <div className="date-wrapper">
                {analyticsDataLoading30d ? <span
                  className='white d-inline-flex gap-2'>  <Spinner
                    animation="grow" size="sm" /><span
                      className=' d-inline-block white'>Analyzing...</span></span> : <ul className="d-list">
                  <li
                    onClick={() =>
                      setDaysFilter((pre) => pre == 7 ? null : (7))
                    }
                    className={`${daysFilter == 7 ? "white" : ""}`}
                  >
                    <span>7D</span>
                  </li>
                  <li
                    onClick={() =>
                      setDaysFilter((pre) => pre == 30 ? null : (30))
                    }
                    className={`${daysFilter == 30 ? "white" : ""}`}
                  >
                    <span>30D</span>
                  </li>
                  {/* <li
                    onClick={() =>
                      setDaysFilter((pre) => (null))
                    }
                    className={`${daysFilter == null ? "white" : ""}`}
                  >
                    <span>All</span>
                  </li> */}
                </ul>}
                {endDate && analyticsDataLoading && <span
                  className='white d-inline-flex gap-2'>  <Spinner
                    animation="grow" size="sm" /><span
                      className=' d-inline-block white'>Analyzing...</span></span>}
                <div onClick={handleDateRange} className="d-flex gap-2 align-items-center " style={{ cursor: 'pointer' }}>
                  <div
                    className={`box-icon ${dateRange ? "active" : ""}`}
                  >
                    <IoCalendarClear />
                  </div>
                  <p className="white">Set date range</p>
                </div>
              </div>
              <div
                ref={modalRef}
                className={`select-time-main-wrapper chart ${dateRange ? "active" : ""
                  }`}
              >
                <form action="#">
                  <ul
                    className="time"
                    onChange={(e) => handlePredefinedRange(e.target.value)}
                  >
                    <li>
                      <input
                        type="radio"
                        id="Today"
                        name="time"
                        value="today"
                      />
                      <label htmlFor="Today">Today</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="Yesterday"
                        name="time"
                        value="yesterday"
                      />
                      <label htmlFor="Yesterday">Yesterday</label>
                    </li>
                    <li>
                      <input type="radio" id="week" name="time" value="week" />
                      <label htmlFor="week">This Week</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="last-days"
                        name="time"
                        value="last-days"
                      />
                      <label htmlFor="last-days">
                        Last <input type="text" id="lastDaysInput" /> Days
                      </label>
                    </li>

                    <li>
                      <input
                        type="radio"
                        id="month"
                        name="time"
                        value="month"
                      />
                      <label htmlFor="month">
                        This Month{" "}
                        {`(${new Date().toLocaleString("en-US", {
                          month: "long",
                          timeZone: "UTC",
                        })})`}
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="lastMonth"
                        name="time"
                        value="lastMonth"
                      />
                      <label htmlFor="lastMonth">
                        Last Month
                        {/*{`(${getLastMonthName()})`}*/}
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="thisYear"
                        name="time"
                        value="thisYear"
                      />
                      <label htmlFor="thisYear">
                        This Year {`(${new Date().getFullYear()})`}
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="upToday"
                        name="time"
                        value="upToday"
                      />
                      <label htmlFor="upToday">
                        <input type="text" id="upTodayInput" /> Days up to today
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="reset"
                        name="time"
                        value="reset"
                      />
                      <label htmlFor="reset">Reset</label>
                    </li>
                  </ul>
                </form>
                <div className="calendar-wrapper">
                  <div className="select-time">
                    <div className="select">
                      <span className="white">
                        {startDate ? startDate.toLocaleDateString() : " "}
                      </span>
                    </div>
                    <span className="white mx-3">-</span>
                    <div className="select">
                      <span className="white">
                        {endDate ? endDate.toLocaleDateString() : " "}
                      </span>
                    </div>
                  </div>
                  <div className="calandar">
                    {/* <CalendarComponent /> */}
                    <Calendar
                      onChange={handleDateSelect}
                      value={selectingStartDate ? startDate : endDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-content-wrapper">
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-4 col-md-3    ">
            <ul className="lists">
              <li>
                <button
                  className={`${smallChartTabs == "likes-engagement" ? "active" : ""
                    }`}
                  onClick={() => setSmallChartTabs("likes-engagement")}
                >
                  {/* <FcLike style={{ color: "#5352B0" }} /> Likes */}
                  <BsHeartFill style={{ color: "#5352B0" }} /> Likes
                </button>
              </li>
              <li>
                <button
                  className={`${smallChartTabs == "comments-engagement" ? "active" : ""
                    }`}
                  onClick={() => setSmallChartTabs("comments-engagement")}
                >
                  <img src={smsPurpleIcon} alt="chartsBlackIcon" /> Comment
                </button>
              </li>
              <li>
                <button
                  className={`${smallChartTabs == "shares-engagement" ? "active" : ""
                    }`}
                  onClick={() => setSmallChartTabs("shares-engagement")}
                >
                  <PiShareFatFill style={{ color: "#ff6400" }} /> Shares
                </button>
              </li>
              <li>
                <button
                  className={`${smallChartTabs == "views-engagement" ? "active" : ""
                    }`}
                  onClick={() => setSmallChartTabs("views-engagement")}
                >
                  <BsFillEyeFill style={{ color: "#00F2EA" }} /> Views
                </button>
              </li>
              {/*<li><button className={`${smallChartTabs == 'followers-engagement' ? 'active' : ''}`} onClick={() => setSmallChartTabs('followers-engagement')}><FaUser style={{ color: '#FF6400' }} /> Followers</button></li>*/}

              <li>
                <button
                  className={`${smallChartTabs == "bookmarks-engagement" ? "active" : ""
                    }`}
                  onClick={() => setSmallChartTabs("bookmarks-engagement")}
                >
                  <IoBookmark style={{ color: "#34A853" }} /> Bookmarks
                </button>
              </li>
              {/*<li><button className={`${smallChartTabs == 'likes-engagement' ? 'active' : ''}`} onClick={() => setSmallChartTabs('likes-engagement')}><FcLike style={{ color: '##5352B0' }} /> Likes</button></li>*/}
              {/*<li><button><IoGrid style={{ color: 'white' }} /> All</button></li>*/}
            </ul>
          </div>
          <div className="col-xl-10 col-lg-9 col-8 col-md-9">
            <div className="graph-wrapper">
              {/* <div className="graph-title">
                <div className="dot" style={{ background: "#FF165D" }}></div>
                <span>Likes</span>
              </div> */}
              {/*<LargeGraphChart
                              data={chartData}
                              smallChartTabs={smallChartTabs}
                              analyticsData={analyticsData}
                              chartTabs={chartTabs}
                          />*/}
              <GreenLargeChart
                data={chartData}
                smallChartTabs={smallChartTabs}
                analyticsData={analyticsData}
                chartTabs={chartTabs}
                colors={
                  smallChartTabs == "comments-engagement"
                    ? {
                      gradient: "rgba(253, 189, 97, 0.06)",
                      borderColor: "#FDBD61",
                    }
                    : smallChartTabs == "views-engagement"
                      ? {
                        gradient: "rgba(25, 47, 62, 1)",
                        borderColor: "rgb(0, 242, 234)",
                      }
                      : smallChartTabs == "videos-engagement"
                        ? {
                          gradient: "#5352B0",
                          borderColor: "#5352B0",
                        }
                        : smallChartTabs == "shares-engagement"
                          ? {
                            gradient: "rgba(66, 43, 39, 1)",
                            borderColor: "#FF6400",
                          }
                          : smallChartTabs == "bookmarks-engagement"
                            ? {
                              gradient: "rgba(33, 73, 58, 1)",
                              borderColor: "rgb(52, 168, 83)",
                            }
                            : smallChartTabs == "likes-engagement"
                              ? {
                                gradient: "rgba(83, 82, 176, 0.06)",
                                borderColor: "#5352B0",
                              }
                              : smallChartTabs == "followers"
                                ? {
                                  gradient: "rgba(42, 46, 86, 1)",
                                  borderColor: "rgb(83, 82, 176)",
                                }
                                : {
                                  gradient: "rgba(66, 30, 56, 1)",
                                  borderColor: "rgba(255, 22, 93, 1)",
                                }
                }
                chartLabels={chartLabels}
              />
              {/* <DoubleLineChart /> */}
            </div>
          </div>
        </div>
        {/*<div className="chart-tabs-wrapper">
            <div
                className={`chart-tab ${
                    smallChartTabs == "videos-engagement" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("videos-engagement")}
            >
              <div className="img-wrapper">
                <img src={pinkChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Videos</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "posts-engamement" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("posts-engamement")}
            >
              <div className="img-wrapper">
                <img src={cyanChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Post count</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "views-engagement" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("views-engagement")}
            >
              <div className="img-wrapper">
                <img src={cyanChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Views</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "followers-engagement" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("followers-engagement")}
            >
              <div className="img-wrapper">
                <img src={orangeChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Followers</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "likes-engagement" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("likes-engagement")}
            >
              <div className="img-wrapper">
                <img src={purpleChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Likes</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "bookmarks-engagement" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("bookmarks-engagement")}
            >
              <div className="img-wrapper">
                <img src={greenChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Bookmarks</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "comments-engagement" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("comments-engagement")}
            >
              <div className="img-wrapper">
                <img src={yellowChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Comments</p>
              </div>
            </div>
          </div>*/}
      </div>
      <div className="average-text purple">
        <p className="small">
          @{profileData?.username} gathered <span> </span>
          <span>
            {Array.isArray(chartData)
              ? Math.ceil(chartData.reduce((a, b) => a + b, 0)).toLocaleString()
              : 0} {smallChartTabs?.split('-')[0]}
          </span>
          {/* accounts of similar size have gathered 12,347,383 at an average. */}
        </p>

      </div>
    </div>
  );
};

export default AverageEngagementPerPostChart;
