import { annual_packeges, apiKey, lemon_squeezy_store_id, monthly_packeges } from "../services/constents";
import { ApiRequests, paymentApiRequests } from "../services/apiRequests";
import axios from "axios";
import { parse } from "cookie";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { FaBookmark } from "react-icons/fa6";
import ProfileNotFound from "../components/ProfileNotFound";
import Modal from 'react-bootstrap/Modal';
import SideBarComponent from "../components/SideBarComponent";
import HeaderComponent from "../components/HeaderComponent";
import LoadingComponent from "../components/LoadingComponent";

import chatGptStarsIcon from '../assets/images/icons/chat-gpt-stars-icon.png'
import tickInCircleIcon from '../assets/images/icons/tick-in-circle-icon.svg'
import tiktokSocialIcon from '../assets/images/icons/tiktok-icon.svg'
import youtubeSocialIcon from '../assets/images/icons/youtube-icon.png'
import intagramSocialIcon from '../assets/images/icons/instagram-icon.svg'
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function Pricing() {
    const [productsData, setProductsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate= useNavigate()
    const location = useLocation()
    const [customersData, setCustomersData] = useState(null);
    // const user = JSON.parse(localStorage.getItem("user"));
    const { user } = useSelector((store) => store.auth);
    const token = localStorage.getItem("app-access-token");
    const [annual, setAnnual] = useState(false);
    const [loadingStates, setLoadingStates] = useState({});
    const errorModalOpenRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [isSidebarActive, setSidebarActive] = useState(false);
    const [isSidebarMobileActive, setSidebarMobileActive] = useState(false);
    const unparsedUser = localStorage.getItem("user");

    console.log("products::: ", productsData);
    useEffect(() => {
        if (!unparsedUser) {
            navigate(`/sign-in?returnUrl=${encodeURIComponent(location.pathname)}`)
        }
        // getStore()
        getProduct();
    }, []);
    const getProduct = async () => {
        try {
            // const response = await axios.get('https://api.lemonsqueezy.com/v1/products/1', {
            // headers: {
            //   Accept: 'application/vnd.api+json',
            //   'Content-Type': 'application/vnd.api+json',
            //   Authorization: `Bearer ${apiKey}`,
            // },
            // });

            const { data } = await ApiRequests.getProducts({
                store_id: lemon_squeezy_store_id,   // live
                // store_id: 39021,
                token: token,
            });
            const _product = data?.data;
            // const finalProducts = _product?.slice(-6)
            // console.log('final products ', finalProducts)
            setProductsData(_product);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            // Handle the error
        }
    };
    const getStore = async () => {
        try {
            // const response = await axios.get('https://api.lemonsqueezy.com/v1/stores', {
            //   headers: {
            //     Accept: 'application/vnd.api+json',
            //     'Content-Type': 'application/vnd.api+json',
            //     Authorization: `Bearer ${apiKey}`,
            //   },
            // });
            const { data } = await paymentApiRequests.getStores();

            console.log(data.data); // Handle the response data
        } catch (error) {
            console.error(error);
            // Handle the error
        }
    };

    const createPaymentMethod = async ({ id, store_id }) => {
        try {
            const response = await paymentApiRequests.checkOut({
                data: {
                    type: "checkouts",
                    attributes: {
                        store_id,
                        variant_id: 32232,
                        custom_price: null,
                        product_options: {
                            name: "",
                            description: "",
                            media: [],
                            redirect_url: "",
                            receipt_button_text: "",
                            receipt_link_url: "",
                            receipt_thank_you_note: "",
                            enabled_variants: [],
                        },
                        checkout_options: {
                            embed: false,
                            media: true,
                            logo: true,
                            desc: true,
                            discount: true,
                            dark: false,
                            subscription_preview: true,
                            button_color: "#7047EB",
                        },
                        checkout_data: {
                            email: "",
                            user: "",
                            name: "",
                            billing_address: {
                                country: "",
                                zip: "",
                            },
                            tax_number: "",
                            discount_code: "",
                            custom: {
                                userId: user?.id
                            }
                        },
                        expires_at: null,
                    },
                    relationships: {
                        store: {
                            data: {
                                type: "stores",
                                id: "32232",
                            },
                        },
                        variant: {
                            data: {
                                type: "variants",
                                id: "90864",
                            },
                        },
                    },
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    // const handleUpgradeClick = async (subscriptionId, productID) => {
    //   setLoadingStates((prevState) => ({
    //     ...prevState,
    //     [productID]: true,
    //   }));
    //   try {
    //     const response = await ApiRequests.subscription({
    //       data: { productID, subscriptionId },
    //       token: token,
    //     });
    //     if (response.status === 200) {
    //       toast.success("Subscription updated successfully");
    //       window.location.reload();
    //     } else {
    //       toast.error("Error while updating subscription");
    //     }
    //   } catch (error) {
    //     // Handle network errors or other exceptions
    //     console.error("An error occurred:", error);
    //   } finally {
    //     setLoadingStates((prevState) => ({
    //       ...prevState,
    //       [productID]: false,
    //     }));
    //   }
    // };

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedSubscriptionID, setSelectedSubscriptionID] = useState(null);
    const [errorModelMessage, setErrorModelMessage] = useState("");
    const [success, setSuccess] = useState(false)
    const [showCTA, setShowCTA] = useState(false);
    const [CTAText, setCTAText] = useState('')
    // Step 3: Function to open the confirmation dialog
    const openConfirmation = (subscriptionId, productId) => {
        setSelectedProductId(productId);
        setSelectedSubscriptionID(subscriptionId);
        setShowConfirmation(true);
    };

    // Step 4: Update handleUpgradeClick to open the confirmation dialog
    const handleUpgradeClick = (subscriptionId, productID) => {
        openConfirmation(subscriptionId, productID);
    };

    // Step 5: Function to handle the upgrade or cancel
    const handleConfirmation = async (confirmed) => {
        setShowConfirmation(false);
        if (confirmed) {
            setLoadingStates((prevState) => ({
                ...prevState,
                [selectedProductId]: true,
            }));
            try {
                const response = await ApiRequests.subscription({
                    data: {
                        productID: selectedProductId,
                        subscriptionId: selectedSubscriptionID,
                    },
                    token: token,
                });
                if (response.status === 200 && !response.error) {
                    // toast.success("Subscription updated successfully");
                    setErrorModelMessage('Subscription updated successfully.')
                    setSuccess(true)
                    window.location.reload();
                } else {
                    toast.error("Error while updating subscription");
                }
            } catch (error) {
                console.error("An error occurred:", error);
                setErrorModelMessage(error.message)
                setSuccess(false)
            } finally {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [selectedProductId]: false,
                }));
            }
        }
    };
    const handleShow = () => setModalShow(true);

    const sidebarActiveHandler = () => {
        setSidebarActive(!isSidebarActive);
    };
    const sidebarMobileActiveHandler = () => {
        setSidebarMobileActive(true);
    };
    const sidebarMobileCloseHandler = () => {
        setSidebarMobileActive(false);
    };

    return (
        <>
            <div className='pricing-page'>
                <div className="inner-page-wrapper">
                    <SideBarComponent isSidebarActive={isSidebarActive} isSidebarMobileActive={isSidebarMobileActive} sidebarMobileCloseHandler={sidebarMobileCloseHandler} sidebarActiveHandler={sidebarActiveHandler} />
                    <div className={`main-dashboard-wrapper ${isSidebarActive ? 'active' : ''} ${isSidebarMobileActive ? 'show' : ''} `}>
                        <HeaderComponent sidebarActiveHandler={sidebarActiveHandler} sidebarMobileActiveHandler={sidebarMobileActiveHandler} isSidebarActive={isSidebarActive} setErrorModelMessage={setErrorModelMessage} setSuccess={setSuccess} success={success} />
                        <div className="dashboard-inner-page">
                            <div className="contauner-fluid">
                                {loading ? (
                                    <div className=" flex justify-center items-center">
                                        <div
                                            className=""
                                            style={{ width: "100%", margin: "auto", height: 100 }}
                                        >
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    transform: "translate(-50%,-45%)",
                                                    zIndex: "10",
                                                    marginTop: "35px",
                                                    top: '60px'
                                                }}
                                                className="loadingio-spinner-interwind-flyom1cz6sv"
                                            >
                                                <div className="ldio-zxrz71mlja" style={{ marginTop: 25 }}>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ) : (
                                    <>
                                        <div className="pricing-container">
                                            {/* <button onClick={() => errorModalOpenRef.current.click()}>Modal</button>
            <Modal errorModalOpenRef={errorModalOpenRef} /> */}
                                            <div className='container'>

                                                <div
                                                    className="form-check form-switch pb-10 py-12 "
                                                    style={{ paddingLeft: "3.5em", display: "flex" }}
                                                >
                                                    <input
                                                        className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-gray-300 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                                                        style={{ transform: "scale(1.5)" }}
                                                        name="allowEmail"
                                                        checked={annual}
                                                        onChange={(e) => {
                                                            setAnnual(e.target.checked);
                                                        }}
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckDefault"
                                                    />

                                                    <label
                                                        style={{ fontSize: "17px" }}
                                                        className="form-check-label inline-block text-sm pl-6 text-gray-400 text-left"
                                                        htmlFor="flexSwitchCheckDefault"
                                                    >
                                                        Annual Subscription
                                                    </label>
                                                </div>
                                                <div
                                                    className="row"
                                                >
                                                    {productsData &&
                                                        Array.isArray(productsData) &&
                                                        productsData
                                                            ?.filter((item) => {
                                                                const productId = item?.id;

                                                                // List of product IDs for beginner and annual packages
                                                                const beginnerProductIds = monthly_packeges;
                                                                const annualProductIds = annual_packeges;

                                                                return (annual && annualProductIds.includes(productId)) || (!annual && beginnerProductIds.includes(productId));
                                                            })
                                                            ?.map(
                                                                ({
                                                                    id,
                                                                    attributes: {
                                                                        buy_now_url,
                                                                        description,
                                                                        name,
                                                                        price_formatted,
                                                                        store_id,
                                                                    },
                                                                }) => (
                                                                    <div className="col-xl-4 col-lg-6 col-md-6 mb-4" key={id}>
                                                                        <div className={`plan-card`}>
                                                                            {/* Plan Name Wrapper */}
                                                                            <div className="plan-name-wrapper">
                                                                                <div className="title">
                                                                                    <div className="icon"></div>
                                                                                </div>
                                                                                <div className="text">
                                                                                    <strong className="white">{name}</strong>
                                                                                    <span className="white">
                                                                                        Ideal for {annual ? "annual" : "monthly"} users.
                                                                                    </span>
                                                                                </div>
                                                                                <div className="price">
                                                                                    <h3 className="large white">
                                                                                        {price_formatted} <sub>/ per month</sub>
                                                                                    </h3>
                                                                                    {user.subscribed &&
                                                                                        user.subscription &&
                                                                                        id === user.subscription.productId ? (
                                                                                        <button
                                                                                            className="btn-style get-started-btn subscribed"
                                                                                            disabled
                                                                                        >
                                                                                            {user.subscription.plan}
                                                                                        </button>
                                                                                    ) : user.subscribed ? (
                                                                                        <button
                                                                                            className="btn-style get-started-btn"
                                                                                            onClick={() =>
                                                                                                handleUpgradeClick(
                                                                                                    user.subscription.subscriptionId,
                                                                                                    id
                                                                                                )
                                                                                            }
                                                                                            disabled={loadingStates[id]}
                                                                                        >
                                                                                            {loadingStates[id]
                                                                                                ? "Processing..."
                                                                                                : "Upgrade"}
                                                                                        </button>
                                                                                    ) : (
                                                                                        <a
                                                                                            href={
                                                                                                buy_now_url +
                                                                                                `?checkout[email]=${user.email}&checkout[name]=${user.name}&checkout[custom][user_id]=${user?.id || user._id
                                                                                                }`
                                                                                            }
                                                                                            target="_blank"
                                                                                            className="btn-style get-started-btn"
                                                                                        >
                                                                                            Get Started
                                                                                        </a>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            {/* Features Wrapper */}
                                                                            <div className="features-main-wrapper">
                                                                                <strong className="white features-title">
                                                                                    What you will get
                                                                                </strong>
                                                                                <br />
                                                                                <div
                                                                                    className="description text-muted mb-4"
                                                                                    dangerouslySetInnerHTML={{ __html: description }}
                                                                                ></div>
                                                                            </div>
                                                                            {/* Supported Social Media */}
                                                                            <div className="support-social-media-wrapper">
                                                                                <p>Supported Social Networks</p>
                                                                                <ul className="social-media-icons">
                                                                                    <li>
                                                                                        <a href="#">
                                                                                            <img
                                                                                                src={tiktokSocialIcon}
                                                                                                alt="TikTok"
                                                                                            />
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#">
                                                                                            <img
                                                                                                src={youtubeSocialIcon}
                                                                                                alt="YouTube"
                                                                                            />
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="#">
                                                                                            <img
                                                                                                src={intagramSocialIcon}
                                                                                                alt="Instagram"
                                                                                            />
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}

                                                    {/* <div className="col-md-4 mb-4">
                                                        <div className={`plan-card `}>
                                                            <div className="plan-name-wrapper">
                                                                <div className="title">
                                                                    <div className="icon"></div>
                                                                </div>
                                                                <div className="text">
                                                                    <strong className="white">Beginner</strong>
                                                                    <span className="white">Ideal for newcomers.</span>
                                                                </div>
                                                                <div className="price">
                                                                    <h3 className="large white">$5 <sub>/ per month</sub></h3>
                                                                    <a href={`#`} target='_blank' className="btn-style get-started-btn">Get Started</a>
                                                                </div>
                                                            </div>
                                                            <div className="features-main-wrapper">
                                                                <strong className="white features-title">What you will get</strong>
                                                                <ul className="features-list">
                                                                    <li><img src={chatGptStarsIcon} alt="chatGptStarsIcon" /><span>Chat GPT/AI ready downloads</span> </li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Download <span className="white">3 profiles</span> per month</span></li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Download all profile data</span> </li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Download up to <span className="white">6000</span> videos per month</span></li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Scan and analyze <span className="white"> 10 profiles</span></span></li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Backup: Tiktok only</span>  </li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Full data reports & analytics</span>  </li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>No watermarks</span>  </li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Downloadable .csv file</span>  </li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Download speed: <span className="white">Average</span></span></li>
                                                                    <li><img src={tickInCircleIcon} alt="tickInCircleIcon" /><span>Videos saved for: 24 hours</span>  </li>
                                                                </ul>
                                                            </div>
                                                            <div className="support-social-media-wrapper">
                                                                <p>Supported Social Networks</p>
                                                                <ul className="social-media-icons">
                                                                    <li><a href="#"><img src={tiktokSocialIcon} alt="social-media-icon" /></a>
                                                                    </li>
                                                                    <li><a href="#"><img src={youtubeSocialIcon} alt="social-media-icon" /></a>
                                                                    </li>
                                                                    <li><a href="#"><img src={intagramSocialIcon}
                                                                        alt="social-media-icon" /></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* {showConfirmation && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-gray-700 p-5 rounded-lg shadow-lg w-96 h-auto animate-fade-in">
                            <p className="text-gray-300 mt-2">
                                You are about to upgrade your subscription plan. This will provide
                                you with access to premium features and benefits.
                            </p>
                            <p className="text-gray-300 mt-2">
                                Please note that you will be charged according to the new plan.
                            </p>
                            <p className="text-gray-300 mt-4">
                                Are you sure you want to proceed with the upgrade?
                            </p>
                            <div className="mt-4 flex justify-end">
                                <button
                                    onClick={() => handleConfirmation(true)}
                                    className="bg-green-500 text-white px-4 py-2 rounded-md mr-2"
                                >
                                    Upgrade
                                </button>
                                <button
                                    onClick={() => handleConfirmation(false)}
                                    className="bg-red-500 text-white px-4 py-2 rounded-md"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )} */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {errorModelMessage && <ProfileNotFound message={errorModelMessage}
                setErrorModelMessage={setErrorModelMessage}
                CTAText={CTAText} success={success} />}

            {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

            {showConfirmation && <Modal
                show={showConfirmation}
                onHide={() => setShowConfirmation(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="upgrade-package-modal dashboard-download-modal"
            >
                <Modal.Body>
                    <p>
                        You are about to upgrade your subscription plan. This will provide you with access to premium features and benefits.

                        Please note that you will be charged according to the new plan.
                    </p>
                    <p>Are you sure you want to proceed with the upgrade?</p>
                    <div className="btn-wrapper">
                        <a onClick={() => handleConfirmation(true)} className="btn-style mr-2">Upgrade</a>
                        <a onClick={() => handleConfirmation(false)} className="btn-style dark simple">Cancel</a>
                    </div>
                </Modal.Body>
            </Modal>
            }
        </>
    );
}

// export async function getServerSideProps(context) {
//   const { req, res } = context;
//   const cookies = parse(req.headers.cookie || "");
//   const tokens = cookies.tokens;
//   if (!tokens) {
//     return {
//       redirect: {
//         destination: "/login",
//         permanent: false,
//       },
//     };
//   }
//   const tokensObject = JSON?.parse(tokens);
//   const user = tokensObject?.user;

//   // console.log("asad", user);
//   const { data } = await paymentApiRequests.getProducts();

//   // Return props to pass to the protected component
//   return {
//     props: { user, products: data },
//   };
// }
