// HorizontalBarChartGridLines.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';


export default function ContentBarChart({ durationData }) {
  const data = Object.values(durationData)?.map((item) => Math.ceil(item));
  const labels = Object.keys(durationData).map(key => {
    const [start, end] = key.replace('duration_', '').split('_').map(Number);
    return `${start}-${end}`;
  });

  const barColors = [
    'rgba(217, 217, 217, 1)',
    'rgba(217, 217, 217, 1)',
    'rgba(234, 185, 255, 1)',
    'rgba(133, 141, 226, 1)',
    'rgba(235, 85, 180, 1)',
    'rgba(181, 138, 228, 1)'
  ].reverse();

  const videosData = useSelector((state) => state?.reels?.reelsData);

  const durationCategories = [
    { label: '0-15s', min: 0, max: 15 },
    { label: '15-30s', min: 15, max: 30 },
    { label: '30-60s', min: 30, max: 60 },
    { label: '1-5m', min: 60, max: 300 },
    { label: '5-10m', min: 300, max: 600 },
    { label: '10-15m', min: 600, max: 900 }
  ];

  const durationCounts = durationCategories.map(() => 0);

  const filterVideosByDate = (videos, days) => {
    if (!days) return videos;
    const now = new Date();
    return videos.filter(video => {
      const videoDate = new Date(video.date || video?.['Date Posted']);
      const timeDifference = (now - videoDate) / (1000 * 60 * 60 * 24);
      return timeDifference <= days;
    });
  };

  const filteredVideosData = filterVideosByDate(videosData, 30);

  filteredVideosData.forEach(video => {
    const duration = video?.duration == 0 ? 0 : video?.duration || video?.Duration;
    for (let i = 0; i < durationCategories.length; i++) {
      const category = durationCategories[i];
      if (duration >= category.min && duration < category.max) {
        durationCounts[i]++;
        break;
      }
    }
  });

  const mappedData = durationCounts;

  const chartData = {
    labels: ['10-15m', '5-10m', '1-5m', '30-60s', '15-30s', '0-15s'].reverse(),
    datasets: [
      {
        label: 'Videos',
        data: mappedData,
        backgroundColor: barColors,
        borderRadius: 7,
        barThickness: 'flex',
        maxBarThickness: 25,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
          color: '#E5E6EB1A',
          borderDash: [4],
        },
        ticks: {
          display: false,
          offset: 20,
        maxTicksLimit: 4,
        // stepSize: 5,
        // offset: 5,
        },
     
     
      },
      y: {
        beginAtZero: true,
        offset: 20,
        grid: {
          display: false,
        },
        ticks: {
          color: '#D9D9D9',
          fontSize: 10,
          offset: 20,
          crossAlign:'far',
        },
        
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => `${context.raw}s`,
        },
      },
      datalabels: {
        color: '#D9D9D9',
        align: 'end',
        anchor: 'end',
        formatter: (val) => `${val}`,
        offset: 0,
        font: {
          size: 10,
        },
        padding: {
          right: 10,
        },
      },
    },
    layout: {
      offset: 20,
      padding: {
        right: 30,
        left: 0,
        top: 20,
        bottom: 20,
      },
    },
  };

  return (
    <div className="horizontal-bar-chart position-relative content-bar-chart" style={{ maxWidth: 450 }} >
      <span className="title" style={{ color: '#ffffff', position: 'absolute', top: 10, left: -2, fontSize: 10 }}>
        Duration
      </span>
      <Bar data={chartData} options={options} plugins={[ChartDataLabels]} height={350} />
    </div>
  );
}
