// import React, { useEffect, useState } from 'react';
// import Chart from 'chart.js/auto'; // Import Chart object
// import ReactDOMServer from 'react-dom/server';

// import img from '../assets/images/video-img-1.png'
// import tiktokIcon from '../assets/images/icons/tiktok-small-icon.png'
// import { FaCalendarDays } from "react-icons/fa6";
// import { HiArrowRight } from "react-icons/hi2";
// import { TbCircleArrowUpFilled } from "react-icons/tb";

// export default function LargeGraphChart1({ data, smallChartTabs, analyticsData, chartTabs, colors,daysFilter, chartLabels }) {
//     console.log('data for chart', data)
//     const [ishow, setIsShow] = useState()
//     useEffect(() => {
//         // Create gradient
//         const canvas = document.getElementById('largeGraphChart');
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, '550'); // Adjust according to your chart size
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, colors?.gradient); // A djust as needed

//         // Apply gradient as background color
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: chartLabels?.length > 0 ? chartLabels :['January 2024', 'December 2023', 'November 2023', 'October 2023', 'September 2023', 'August 2023', 'July 2024', 'June 2023'],
//                 // labels:chartLabels,
//                 datasets: [
//                     {
//                         label: 'My second dataset',
//                         backgroundColor: gradient,
//                         borderColor: colors?.borderColor,
//                         borderWidth: 2,
//                         fill: true,
//                         pointBackgroundColor: colors?.borderColor,
//                         pointBorderColor: colors?.borderColor, // Point border color
//                         // pointHoverBackgroundColor: '#191F2F',
//                         pointHoverBackgroundColor: colors?.borderColor,
//                         // pointHoverBorderColor: 'rgba(255, 22, 93, 1)',
//                         pointHoverBorderColor: colors?.borderColor,
//                         // data: [20,15,30,40,34,30,20,20],
//                         data: data,
//                         lineTension: 0.4, // Adjust the tension value for smoothness
//                         zIndex: 1,
//                         pointRadius: 4, // Set the radius of circular points
//                         pointHoverRadius: 6,
//                         pointLabelFontSize: 14, // Font size of the point label
//                         pointLabelFontColor: 'rgba(255, 22, 93, 1)',
//                         pointBorderWidth: 2,
//                         pointHoverBorderWidth: 2,
//                         pointHitRadius: 10,
//                         images: [
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                         ],
//                     },

//                 ],

//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     },
//                     tooltip: {
//                         // Disable the on-canvas tooltip
//                         enabled: false,

//                         external: function (context) {
//                             // Tooltip Element
//                             let tooltipEl = document.getElementById('chartjs-tooltip');
//                             console.log("GG", tooltipEl)
//                             // Create element on first render
//                             if (!tooltipEl) {
//                                 tooltipEl = document.createElement('div');
//                                 tooltipEl.id = 'chartjs-tooltip';
//                                 document.body.appendChild(tooltipEl);
//                             }
//                             console.log("GG2", tooltipEl)

//                             // Hide if no tooltip
//                             const tooltipModel = context.tooltip;
//                             if (tooltipModel.opacity === 0) {
//                                 tooltipEl.style.opacity = 0;
//                                 console.log("Opacity is zero")
//                                 return;
//                             }
//                             console.log(tooltipModel)
//                             // Set caret Position
//                             tooltipEl.classList.remove('above', 'below', 'no-transform');
//                             if (tooltipModel.yAlign) {
//                                 tooltipEl.classList.add(tooltipModel.yAlign);
//                             } else {
//                                 tooltipEl.classList.add('no-transform');
//                             }

//                             // Set Text
//                             if (tooltipModel.body) {
//                                 const htmlString = ReactDOMServer.renderToString(<MyComponent />);

//                                 tooltipEl.innerHTML = htmlString;
//                             }

//                             const position = context.chart.canvas.getBoundingClientRect();

//                             // Display, position, and set styles for font
//                             tooltipEl.style.opacity = 1;
//                             tooltipEl.style.position = 'absolute';
//                             tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
//                             tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
//                             tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
//                             tooltipEl.style.pointerEvents = 'none';
//                         }
//                     }
//                 },
//                 scales: {
//                     // yAxes: [{
//                     //     ticks: {
//                     //         beginAtZero: true,
//                     //         color: '#ffffff', // Set color for labels
//                     //     }
//                     // }],
//                     // xAxes: [{
//                     //     gridLines: {
//                     //         z: 99
//                     //     }
//                     // }],
//                     x: {
//                         ticks: {
//                             // display: false // Hide the ticks (labels) on the x-axis
//                             color: '#D9D9D9', // Set color for labels
//                             beginAtZero: true,
//                         },
//                         grid: {
//                             display: false,
//                             z: 99
//                         }
//                     },
//                     y: {
//                         ticks: {
//                             // display: false // Hide the ticks (labels) on the x-axis
//                             color: '#D9D9D9', // Set color for labels
//                         },
//                         grid: {
//                             display: true,
//                             color: 'rgba(229, 230, 235, 0.15)',
//                             zIndex: 9999,
//                             drawOnChartArea: true,
//                             //dashed grid lines
//                             // borderDash: [5, 5], // set the dash style
//                             offset: false
//                         },
//                         border: {
//                             // dash: [2,4],
//                         },
//                         gridLines: {
//                             display: true,
//                             color: '#ffffff',
//                             zIndex: 2, // Set a higher z-index
//                         }
//                     }
//                 },
//                 pointLabels: {
//                     display: true, // Display point labels
//                     fontColor: 'rgba(255, 22, 93, 1)', // Color of point labels
//                     fontSize: 14, // Font size of point labels
//                     fontStyle: 'bold' // Font style of point labels
//                 },
//                 elements: {
//                     point: {
//                         radius: 4, // Set the radius of circular points
//                         hoverRadius: 6,
//                     },
//                     line: {
//                         tension: 0 // Disable bezier curves
//                     }
//                 },
//             },
//         });

//         return () => {
//             chartInstance.destroy(); // Cleanup chart instance when component unmounts
//         };
//     }, [smallChartTabs, data, analyticsData, chartTabs]);

//     return (
//         <>
//             <div className="graph-title">
//                 <div className="dot" style={{ background: colors?.borderColor }}></div>
//                 <span>{smallChartTabs}</span></div>
//             <div className='graph-chart-wrapper'>
//                 <canvas id="largeGraphChart" />
//             </div>
//         </>
//     );
// }

// const MyComponent = () => {
//     return <div className='large-chart-custom-tooltip'>
//         <div className="upper-date-title">
//             <div className="date">
//                 <div className="d-flex align-self-center gap-2">
//                     <FaCalendarDays />
//                     <span className="small">October 2023</span>
//                 </div>
//                 <span className="ex-small strong">110 total videos</span>
//             </div>
//             <div className="percentage">
//                 <TbCircleArrowUpFilled />
//                 <span className="ex-small green">8.2%</span>
//             </div>
//         </div>
//         <div className="img-list">
//             <div className="list">
//                 <img src={img} alt="img" className='img' />
//                 <div className="icon">
//                     <img src={tiktokIcon} alt="tiktokIcon" className='tiktokIcon' />
//                     <span>s</span>
//                 </div>
//             </div>
//             <div className="list">
//                 <img src={img} alt="img" className='img' />
//                 <div className="icon">
//                     <img src={tiktokIcon} alt="tiktokIcon" />
//                     <span>s</span>
//                 </div>
//             </div>
//             <div className="list">
//                 <img src={img} alt="img" className='img' />
//                 <div className="icon">
//                     <img src={tiktokIcon} alt="tiktokIcon" />
//                     <span>s</span>
//                 </div>
//             </div>
//             <div className="list">
//                 <img src={img} alt="img" className='img' />
//                 <div className="icon">
//                     <img src={tiktokIcon} alt="tiktokIcon" />
//                     <span>s</span>
//                 </div>
//             </div>
//             <div className="list">
//                 <img src={img} alt="img" className='img' />
//                 <div className="icon">
//                     <img src={tiktokIcon} alt="tiktokIcon" />
//                     <span>s</span>
//                 </div>
//             </div>
//         </div>
//         <div className="view-more-btn">
//             <a href="#" className='btn-style text'>View more </a>
//             <HiArrowRight />
//         </div>
//     </div>;
// };


// import React, { useEffect, useState, useRef  } from 'react';
// import Chart from 'chart.js/auto';
// import {registerables } from 'chart.js';
// import ReactDOMServer from 'react-dom/server';

// import img from '../assets/images/video-img-1.png';
// import tiktokIcon from '../assets/images/icons/tiktok-small-icon.png';
// import { FaCalendarDays } from "react-icons/fa6";
// import { HiArrowRight } from "react-icons/hi2";
// import { TbCircleArrowUpFilled } from "react-icons/tb";
// import ChartDataLabels from 'chartjs-plugin-datalabels';

// Chart.register(...registerables);
// export default function LargeGraphChart1({ data, smallChartTabs, analyticsData, chartTabs, colors, daysFilter, chartLabels }) {
//     console.log('recieved data', data)
//     const [ishow, setIsShow] = useState();
//     const chartRef = useRef(null);
//     useEffect(() => {
//         const canvas = document.getElementById('largeGraphChart');
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, 550);
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, colors?.gradient);
//         const chart = chartRef.current;
//         const customPlugin = {
//             id: 'custom-datalabels',
//             afterDatasetsDraw: (chart) => {
//                 const ctx = chart.ctx;
//                 chart.data.datasets.forEach((dataset, i) => {
//                     const meta = chart.getDatasetMeta(i);
//                     meta.data.forEach((element, index) => {
//                         const value = dataset.data[index];
//                         ctx.save();
//                         ctx.font = 'bold 12px Arial';
//                         ctx.fillStyle = 'white';
//                         ctx.textAlign = 'center';
//                         ctx.textBaseline = 'middle';
//                         ctx.fillText(value, element.x, element.y - 10);
//                         ctx.restore();
//                     });
//                 });
//             },
//         };
//         Chart.register(customPlugin);
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: chartLabels?.length > 0 ? chartLabels : ['January 2024', 'December 2023', 'November 2023', 'October 2023', 'September 2023', 'August 2023', 'July 2024', 'June 2023'],
//                 datasets: [
//                     {
//                         label: 'My second dataset',
//                         data: data,
//                         backgroundColor: gradient,
//                         borderColor: colors?.borderColor,
//                         borderWidth: 2,
//                         fill: true,
//                         // pointBackgroundColor: colors?.borderColor,
//                         pointBackgroundColor: '#191F2F',
//                         pointBorderColor: colors?.borderColor,
//                         // pointHoverBackgroundColor: colors?.borderColor,
//                         pointHoverBackgroundColor:'#191F2F',
//                         pointHoverBorderColor: colors?.borderColor,
//                         lineTension: 0,
//                         pointRadius: 4,
//                         pointHoverRadius: 6,
//                         pointLabelFontSize: 10,
//                         pointLabelFontColor: 'rgba(255, 22, 93, 1)',
//                         pointBorderWidth: 2,
//                         pointHoverBorderWidth: 2,
//                         pointHitRadius: 10,
//                         images: [
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                             'https://via.placeholder.com/150',
//                         ],
//                     },
//                 ],
//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     },
//                     tooltip: {
//                         enabled: false,
//                         external: function (context) {
//                             let tooltipEl = document.getElementById('chartjs-tooltip');
//                             if (!tooltipEl) {
//                                 tooltipEl = document.createElement('div');
//                                 tooltipEl.id = 'chartjs-tooltip';
//                                 document.body.appendChild(tooltipEl);
//                             }
//                             const tooltipModel = context.tooltip;
//                             if (tooltipModel.opacity === 0) {
//                                 tooltipEl.style.opacity = 0;
//                                 return;
//                             }

//                             const dataIndex = tooltipModel.dataPoints[0].dataIndex;
//                             const currentData = context.chart.data.datasets[0].data[dataIndex];
//                             const currentLabel = context.chart.data.labels[dataIndex];

//                             const htmlString = ReactDOMServer.renderToString(<MyComponent data={currentData} label={currentLabel} />);
//                             tooltipEl.innerHTML = htmlString;

//                             const position = context.chart.canvas.getBoundingClientRect();
//                             tooltipEl.style.opacity = 1;
//                             tooltipEl.style.position = 'absolute';
//                             // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
//                             tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 94 + 'px';
//                             // tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
//                             tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 20 + 'px';
//                             tooltipEl.style.pointerEvents = 'none';
//                         },
//                     },
//                     datalabels: {
//                         display: true,
//                         align: 'top',
//                         color: 'white',
//                     },
//                 },
//                 scales: {
//                     x: {
//                         ticks: {
//                             color: '#D9D9D9',
//                             beginAtZero: true,
//                         },
//                         grid: {
//                             display: false,
//                         },
//                     },
//                     y: {
//                         ticks: {
//                             color: '#D9D9D9',
//                         },
//                         grid: {
//                             display: true,
//                             color: 'rgba(229, 230, 235, 0.15)',
//                         },
//                     },
//                 },
//                 pointLabels: {
//                     display: true,
//                     fontColor: 'rgba(255, 22, 93, 1)',
//                     fontSize: 14,
//                     fontStyle: 'bold',
//                 },
//                 elements: {
//                     point: {
//                         radius: 4,
//                         hoverRadius: 6,
//                     },
//                     line: {
//                         tension: 0,
//                     },
//                 },
//             },
//         });

//         return () => {
//             chartInstance.destroy();
//             Chart.unregister(customPlugin);
//         };
//     }, [smallChartTabs, data, analyticsData, chartTabs, colors, chartLabels]);

//     return (
//         <>
//             <div className="graph-title">
//                 <div className="dot" style={{ background: colors?.borderColor }}></div>
//                 <span>{smallChartTabs}</span>
//             </div>
//             <div className='graph-chart-wrapper'>
//                 <canvas id="largeGraphChart" />
//             </div>
//         </>
//     );
// }

// const MyComponent = ({ data, label }) => {
//     return (
//         <div className='large-chart-custom-tooltip'>
//             <div className="upper-date-title">
//                 <div className="date">
//                     <div className="d-flex align-self-center gap-2">
//                         <FaCalendarDays />
//                         <span className="small">{label}</span>
//                     </div>
//                     <span className="ex-small strong">{data} total data</span>
//                 </div>
//                 <div className="percentage">
//                     <TbCircleArrowUpFilled />
//                     <span className="ex-small green">8.2%</span>
//                 </div>
//             </div>
//             <div className="img-list">
//                 {/* Dynamic image list can be added here */}
//                 <div className="list">
//                     <img src={img} alt="img" className='img' />
//                     <div className="icon">
//                         <img src={tiktokIcon} alt="tiktokIcon" className='tiktokIcon' />
//                         <span>s</span>
//                     </div>
//                 </div>
//             </div>
//             <div className="view-more-btn">
//                 <a href="#" className='btn-style text'>View more </a>
//                 <HiArrowRight />
//             </div>
//         </div>
//     );
// };






import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js/auto'; // Import Chart object
import zoomPlugin from 'chartjs-plugin-zoom';
import ReactDOMServer from 'react-dom/server';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import img from '../assets/images/video-img-1.png';
import tiktokIcon from '../assets/images/icons/tick-tock-icon.png';
import instagramIcon from '../assets/images/icons/instagram-reels-icon.png';
import youtubeIcon from '../assets/images/icons/youtube-icon.png';
import { FaCalendarDays } from "react-icons/fa6";
import { HiArrowRight } from "react-icons/hi2";
import { TbCircleArrowUpFilled } from "react-icons/tb";


import { CgZoomIn } from "react-icons/cg";
import { CgZoomOut } from "react-icons/cg";
import { TbZoomReset } from "react-icons/tb";
Chart.register(...registerables, zoomPlugin);

// Chart.register(...registerables);

const ShortenNumber = (num) => {
    if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B';
    if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
    return num;
};

export default function LargeGraphChart1({ data, smallChartTabs, analyticsData, chartTabs, colors, daysFilter, chartLabels, pointVideos, profileData }) {
    console.log('received data', data);
    const [ishow, setIsShow] = useState();
    const chartRef = useRef(null);

    useEffect(() => {
        const canvas = document.getElementById('largeGraphChart');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 550);
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, colors?.gradient);
        const chart = chartRef.current;
     
        const customPlugin = {
            id: 'custom-datalabels',
            // afterDatasetsDraw: (chart) => {
            //     const ctx = chart.ctx;
            //     chart.data.datasets.forEach((dataset, i) => {
            //         const meta = chart.getDatasetMeta(i);
            //         meta.data.forEach((element, index) => {
            //             const value = dataset.data[index];
            //             ctx.save();
            //             ctx.font = 'bold 12px Arial';
            //             ctx.fillStyle = 'white';
            //             ctx.textAlign = 'center';
            //             ctx.textBaseline = 'middle';
            //             ctx.fillText(ShortenNumber(value), element.x, element.y - 10);
            //             ctx.restore();
            //         });
            //     });
            // },
            afterDraw: (chart) => {
                const xScale = chart.scales['x'];
                const parentElement = canvas.parentElement;

                // Remove old divs
                const oldDiv = parentElement.querySelectorAll('.main-icon-label-div');
                oldDiv.forEach(div => div.remove());
                // oldDiv.remove()
                const customLabelDivs = xScale.ticks.map((tick, index) => {
                    const label = tick.label;
                    const iconHTML = ReactDOMServer.renderToString(
                        <div className="icon-label">
                            <img width={12} src={profileData?.platform == 'instagram' ? instagramIcon : profileData?.platform == 'youtube' ? youtubeIcon : tiktokIcon} alt={`${label} icon`} />
                            {/* <span>{label}</span> */}
                            <sub>l</sub>
                        </div>
                    );
                    const x = xScale.getPixelForTick(index);
                    const div = document.createElement('div');
                    div.className = 'icon-label-div'; // Add class for removal
                    div.innerHTML = iconHTML;
                    // div.style.position = 'relative';
                    div.style.transform = 'translateX(-50%)'; // Center the label
                    // div.style.left = `${x}px`;
                    // div.style.width = `100%`;
                    return div
                });

                parentElement.style.position = 'relative';
                const mainDiv = document.createElement('div');
                mainDiv.className = 'main-icon-label-div'; // Add class for removal
                mainDiv.style.position = 'absolute';
                mainDiv.style.display = 'flex';
                mainDiv.style.width = "100%"
                mainDiv.style.borderBottom = "1px solid #C9CDD4"
                mainDiv.style.justifyContent = 'space-between'
                mainDiv.style.padding = '5px 0 5px 10px'
                mainDiv.style.width = '90%'
                mainDiv.style.bottom = chartLabels?.length > 15 ? '90px' : `65px`;
                mainDiv.style.marginLeft = `50px`;
                customLabelDivs.forEach(div => mainDiv.appendChild(div));
                parentElement.appendChild(mainDiv);
            }
        };
        Chart.register(customPlugin);

        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: chartLabels?.length > 0 ? chartLabels : ['January 2024', 'December 2023', 'November 2023', 'October 2023', 'September 2023', 'August 2023', 'July 2024', 'June 2023'],
                // labels: ['January 2024', 'December 2023', 'November 2023', 'October 2023', 'September 2023', 'August 2023', 'July 2024', 'June 2023'],
                datasets: [
                    {
                        label: 'My second dataset',
                        data: data,
                        backgroundColor: gradient,
                        borderColor: colors?.borderColor,
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: '#191F2F',
                        pointBorderColor: colors?.borderColor,
                        pointHoverBackgroundColor: '#191F2F',
                        pointHoverBorderColor: colors?.borderColor,
                        lineTension: 0,
                        pointRadius: 4,
                        pointHoverRadius: 6,
                        pointLabelFontSize: 10,
                        pointLabelFontColor: 'rgba(255, 22, 93, 1)',
                        pointBorderWidth: 2,
                        pointHoverBorderWidth: 2,
                        pointHitRadius: 10,
                        images: [
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                            'https://via.placeholder.com/150',
                        ],
                    },
                ],
            },
            options: {
                layout: {
                    padding: {
                        top: 20, // Adjust this value to create the gap
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                        external: function (context) {
                            let tooltipEl = document.getElementById('chartjs-tooltip');
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                document.body.appendChild(tooltipEl);
                            }
                            const tooltipModel = context.tooltip;
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            const dataIndex = tooltipModel.dataPoints[0].dataIndex;
                            const currentData = context.chart.data.datasets[0].data[dataIndex];
                            const currentLabel = context.chart.data.labels[dataIndex];

                            const htmlString = ReactDOMServer.renderToString(<MyComponent data={currentData} label={currentLabel} videos={pointVideos?.find((item) => item?.year == currentLabel)?.videos || []} profileData={profileData}/>);
                            tooltipEl.innerHTML = htmlString;

                            const position = context.chart.canvas.getBoundingClientRect();
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 96 + 'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 20 + 'px';
                            tooltipEl.style.pointerEvents = 'none';
                        },
                    },
                    datalabels: {
                        display: true,
                        align: 'top',
                        color: 'white',
                        formatter: (value) => ShortenNumber(value), // Ensure datalabels are also shortened

                    },
                    htmlLabels: {
                        id: 'htmlLabels',
                        afterDraw: (chart) => {
                            const xScale = chart.scales['x'];
                            console.log("xScalexScalexScale", xScale)
                            xScale.ticks.forEach((tick, index) => {
                                const label = tick.label;
                                const icon = index % 2 === 0 ? <FaCalendarDays /> : <HiArrowRight />;
                                const htmlString = ReactDOMServer.renderToString(
                                    <div className="icon-label">
                                        {/*<img src={tiktokIcon} alt={label} />*/}
                                        <span>{label}1212</span>
                                    </div>
                                );

                                const x = xScale.getPixelForTick(index);
                                const y = chart.height - 30;
                                ctx.save();
                                ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
                                ctx.fillText(htmlString, x, y);
                                ctx.restore();
                            });
                        },
                    },
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'xy'
                        },
                        zoom: {
                            wheel: {
                                enabled: false
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'xy'
                        }
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#D9D9D9',
                            beginAtZero: true,
                            padding: 40,
                            // paddingTop: 20,
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color: '#D9D9D9',
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                            maxTicksLimit: 5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(229, 230, 235, 0.15)',
                        },
                    },
                },
                pointLabels: {
                    display: true,
                    fontColor: 'rgba(255, 22, 93, 1)',
                    fontSize: 14,
                    fontStyle: 'bold',
                },
                elements: {
                    point: {
                        radius: 4,
                        hoverRadius: 6,
                    },
                    line: {
                        tension: 0,
                    },
                },
            },
            plugins: [ChartDataLabels],
        });
        chartRef.current = chartInstance;

        

        return () => {
            chartInstance.destroy();
            // Chart.unregister(chartLabels);  
            Chart.unregister(customPlugin);
        };
    }, [smallChartTabs, data, analyticsData, chartTabs, chartLabels]);

    const handleZoomIn = () => {
        const chartInstance = chartRef.current;
        if (chartInstance) {
            chartInstance.zoom(1.1);
        }
    };

    const handleZoomOut = () => {
        const chartInstance = chartRef.current;
        if (chartInstance) {
            chartInstance.zoom(0.9);
        }
    };

    const handleResetZoom = () => {
        const chartInstance = chartRef.current;
        if (chartInstance) {
            chartInstance.resetZoom();
        }
    };  

    return (
        <>
            <div className="graph-title">
                <div className="dot" style={{ background: colors?.borderColor, fontWeight: "bold" }}></div>
                {/* <span>{smallChartTabs}</span> */}
                <span>{smallChartTabs?.charAt(0).toUpperCase() + smallChartTabs?.slice(1)}</span>
            </div>
            <div className='graph-chart-wrapper with-magnifier-icons'>
                <canvas id="largeGraphChart" className='large-graph-chart-1' ref={chartRef} />
                <ul className="magnifiers-list">
                <li><a onClick={() => handleZoomIn()}><CgZoomIn/></a>                </li>
                <li><a onClick={() => handleZoomOut()}><CgZoomOut/></a>                </li>
                <li><a onClick={() => handleResetZoom()}><TbZoomReset/></a>                </li>
            </ul>
            </div>
        </>
    );
}

const MyComponent = ({ data, label, videos, profileData }) => {
    console.log('videos data labels ', videos)
    return (
        <div className='large-chart-custom-tooltip'>
            <div className="upper-date-title">
                <div className="date">
                    <div className="d-flex align-self-center gap-2">
                        <FaCalendarDays />
                        <span className="small">{label}</span>
                    </div>
                    <span className="ex-small strong">{ShortenNumber(data)} total data</span>
                </div>
                <div className="percentage">
                    <TbCircleArrowUpFilled />
                    <span className="ex-small green">8.2%</span>
                </div>
            </div>
            <div className="img-list">
                {videos?.length > 0 ? videos?.slice(0,5)?.map((item, index) => {
                    return (
                        <div className="list" key={index}>
                            <a href={item?.link} target='_blank'><img src={profileData?.platform === "instagram"
                                                ? `${process.env.REACT_APP_IG_SERVER}proxy?media_url=${encodeURIComponent(

                                                    item?.thumbnail
                                                )}` : item?.thumbnail} alt="img" className='img' /></a>
                            <div className="icon">
                                <img src={tiktokIcon} alt="tiktokIcon" className='tiktokIcon' />
                                <span>s</span>
                            </div>
                        </div>
                    )
                }) : <div></div>}
            </div>
            <div className="view-more-btn">
                <a href="#" className='btn-style text'>View more </a>
                <HiArrowRight />
            </div>
        </div>
    );
};
